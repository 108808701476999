/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
GENERAL SETTINGS
----------------------------------------
Read more about settings and
USWDS style tokens in the documentation:
https://designsystem.digital.gov/design-tokens
----------------------------------------
*/
/*
----------------------------------------
Image path
----------------------------------------
Relative image file path
----------------------------------------
*/
/*
----------------------------------------
Show compile warnings
----------------------------------------
Show Sass warnings when functions and
mixins use non-standard tokens.
AND
Show updates and notifications.
----------------------------------------
*/
/*
----------------------------------------
Namespace
----------------------------------------
*/
/*
----------------------------------------
Prefix separator
----------------------------------------
Set the character the separates
responsive and state prefixes from the
main class name.
The default (":") needs to be preceded
by two backslashes to be properly
escaped.
----------------------------------------
*/
/*
----------------------------------------
Layout grid
----------------------------------------
Should the layout grid classes output
with !important
----------------------------------------
*/
/*
----------------------------------------
Border box sizing
----------------------------------------
When set to true, sets the box-sizing
property of all site elements to
`border-box`.
----------------------------------------
*/
/*
----------------------------------------
Focus styles
----------------------------------------
*/
/*
----------------------------------------
Icons
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
TYPOGRAPHY SETTINGS
----------------------------------------
Read more about settings and
USWDS typography tokens in the documentation:
https://designsystem.digital.gov/design-tokens/typesetting/overview/
----------------------------------------
*/
/*
----------------------------------------
Root font size
----------------------------------------
Setting $theme-respect-user-font-size to
true sets the root font size to 100% and
uses ems for media queries
----------------------------------------
$theme-root-font-size only applies when
$theme-respect-user-font-size is set to
false.

This will set the root font size
as a specific px value and use px values
for media queries.

Accepts true or false
----------------------------------------
*/
/*
----------------------------------------
Global styles
----------------------------------------
Adds basic styling for the following
unclassed elements:

- paragraph: paragraph text
- link: links
- content: paragraph text, links,
  headings, lists, and tables
----------------------------------------
*/
/*
----------------------------------------
Broswer compatibility mode
----------------------------------------
When true, outputs woff and ttf font 
formats in addition to woff2
----------------------------------------
*/
/*
----------------------------------------
Font path
----------------------------------------
Relative font file path
----------------------------------------
*/
/*
----------------------------------------
Custom typeface tokens
----------------------------------------
Add a new custom typeface token if
your project uses a typeface not already
defined by USWDS.
----------------------------------------
USWDS defines the following tokens
by default:
----------------------------------------
'georgia'
'helvetica'
'merriweather'
'open-sans'
'public-sans'
'roboto-mono'
'source-sans-pro'
'system'
'tahoma'
'verdana'
----------------------------------------
Add as many new tokens as you have
custom typefaces. Reference your new
token(s) in the type-based font settings
using the quoted name of the token.

For example:

$theme-font-type-cond: 'example-font-token';

display-name:
The display name of your font

cap-height:
The height of a 500px `N` in Sketch
----------------------------------------
You should change `example-[style]-token`
names to something more descriptive.
----------------------------------------
*/
/*
----------------------------------------
Type-based font settings
----------------------------------------
Set the type-based tokens for your
project from the following tokens,
or from any new font tokens you added in
$theme-typeface-tokens.
----------------------------------------
'georgia'
'helvetica'
'merriweather'
'open-sans'
'public-sans'
'roboto-mono'
'source-sans-pro'
'system'
'tahoma'
'verdana'
----------------------------------------
*/
/*
----------------------------------------
Custom font stacks
----------------------------------------
Add custom font stacks to any of the
type-based fonts. Any USWDS typeface
token already has a default stack.

Custom stacks don't need to include the
font's display name. It will
automatically appear at the start of
the stack.
----------------------------------------
Example:
$theme-font-type-sans: 'source-sans-pro';
$theme-font-sans-custom-stack: "Helvetica Neue", Helvetica, Arial, sans;

Output:
font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans;
----------------------------------------
*/
/*
----------------------------------------
Add any custom font source files
----------------------------------------
If you want USWDS to generate additional
@font-face declarations, add your font
data below, following the example that
follows.
----------------------------------------
USWDS automatically generates @font-face
declarations for the following

'merriweather'
'public-sans'
'roboto-mono'
'source-sans-pro'

These typefaces not require custom
source files.
----------------------------------------
EXAMPLE

- dir:
  Directory relative to $theme-font-path
- This directory should include fonts saved as
  .woff2
  ExampleSerif-Normal.woff2

$theme-font-serif-custom-src: (
  dir: 'custom/example-serif',
  roman: (
    100: false,
    200: false,
    300: 'ExampleSerif-Light',
    400: 'ExampleSerif-Normal',
    500: false,
    600: false,
    700: 'ExampleSerif-Bold',
    800: false,
    900: false,
  ),
  italic: (
    100: false,
    200: false,
    300: 'ExampleSerif-LightItalic',
    400: 'ExampleSerif-Italic',
    500: false,
    600: false,
    700: 'ExampleSerif-BoldItalic',
    800: false,
    900: false,
  ),
);
----------------------------------------
*/
/*
----------------------------------------
Role-based font settings
----------------------------------------
Set the role-based tokens for your
project from the following font-type
tokens.
----------------------------------------
'cond'
'icon'
'lang'
'mono'
'sans'
'serif'
----------------------------------------
*/
/*
----------------------------------------
Type scale
----------------------------------------
Define your project's type scale using
values from the USWDS system type scale

1-20
----------------------------------------
*/
/*
----------------------------------------
Font weights
----------------------------------------
Assign weights 100-900
Or use `false` for unneeded weights.
----------------------------------------
*/
/*
----------------------------------------
General typography settings
----------------------------------------
Type scale tokens
----------------------------------------
micro:      10px
1:          12px
2:          13px
3:          14px
4:          15px
5:          16px
6:          17px
7:          18px
8:          20px
9:          22px
10:         24px
11:         28px
12:         32px
13:         36px
14:         40px
15:         48px
16:         56px
17:         64px
18:         80px
19:         120px
20:         140px
----------------------------------------
Line height tokens
----------------------------------------
1:    1
2:    1.15
3:    1.35
4:    1.5
5:    1.62
6:    1.75
----------------------------------------
Font role tokens
----------------------------------------
'ui'
'heading'
'body'
'code'
'alt'
----------------------------------------
Measure (max-width) tokens
----------------------------------------
1:       44ex
2:       60ex
3:       64ex
4:       68ex
5:       74ex
6:       88ex
none:    none
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
COLOR SETTINGS
----------------------------------------
Read more about settings and
USWDS color tokens in the documentation:
https://designsystem.digital.gov/design-tokens/color
----------------------------------------
*/
/*
----------------------------------------
Theme palette colors
----------------------------------------
*/
/*
----------------------------------------
State palette colors
----------------------------------------
*/
/*
----------------------------------------
General colors
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
COMPONENT SETTINGS
----------------------------------------
Read more about settings and
USWDS style tokens in the documentation:
https://designsystem.digital.gov/design-tokens
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
SPACING SETTINGS
----------------------------------------
Read more about settings and
USWDS spacing units tokens in the
documentation:
https://designsystem.digital.gov/design-tokens/spacing-units
----------------------------------------
*/
/*
----------------------------------------
Border radius
----------------------------------------
2px           2px
0.5           4px
1             8px
1.5           12px
2             16px
2.5           20px
3             24px
4             32px
5             40px
6             48px
7             56px
8             64px
9             72px
----------------------------------------
*/
/*
----------------------------------------
Column gap
----------------------------------------
2px         2px
0.5         4px
1           8px
2           16px
3           24px
4           32px
5           40px
6           48px
----------------------------------------
*/
/*
----------------------------------------
Grid container max-width
----------------------------------------
mobile
mobile-lg
tablet
tablet-lg
desktop
desktop-lg
widescreen
----------------------------------------
*/
/*
----------------------------------------
Site
----------------------------------------
*/
/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
UTILITIES SETTINGS
----------------------------------------
Read more about settings and
USWDS utilities in the documentation:
https://designsystem.digital.gov/utilities
----------------------------------------
*/
/*
----------------------------------------
map-collect()
----------------------------------------
Collect multiple maps into a single
large map
source: https://gist.github.com/bigglesrocks/d75091700f8f2be5abfe
----------------------------------------
*/
/*
----------------------------------------
Utility breakpoints
----------------------------------------
Which breakpoints does your project
need? Select as `true` any breakpoint
used by utilities or layout grid
----------------------------------------
*/
/*
----------------------------------------
Global colors
----------------------------------------
The following palettes will be added to
- background-color
- border-color
- color
- text-decoration-color
----------------------------------------
*/
/*
----------------------------------------
Settings
----------------------------------------
*/
/*
----------------------------------------
Values
----------------------------------------
*/
/*
----------------------------------------
color()
----------------------------------------
Derive a color from a color shortcode
----------------------------------------
*/
/*
----------------------------------------
append-important()
----------------------------------------
Append `!important` to a list
----------------------------------------
*/
/*
----------------------------------------
get-last()
----------------------------------------
Return the last item of a list,
Return null if the value is null
----------------------------------------
*/
/*
----------------------------------------
de-list()
----------------------------------------
Transform a one-element list or arglist
into that single element.
----------------------------------------
(1) => 1
((1)) => (1)
----------------------------------------
*/
/*
----------------------------------------
error-not-token()
----------------------------------------
Returns a common not-a-token error.
----------------------------------------
*/
/*
----------------------------------------
uswds-error()
----------------------------------------
Allow the system to pass an error as text
to test error states in unit testing
----------------------------------------
*/
/*
----------------------------------------
get-default()
----------------------------------------
Returns the default value from a map
of project defaults
get-default("bg-color")
> $theme-body-background-color
----------------------------------------
*/
/*
----------------------------------------
has-important()
----------------------------------------
Check to see if `!important` is
being passed in a mixin's props
----------------------------------------
*/
/*
----------------------------------------
map-deep-get()
----------------------------------------
@author Hugo Giraudel
@access public
@param {Map} $map - Map
@param {Arglist} $keys - Key chain
@return {*} - Desired value
----------------------------------------
*/
/*
----------------------------------------
multi-cat()
----------------------------------------
Concatenate two lists
----------------------------------------
*/
/*
----------------------------------------
remove()
----------------------------------------
Remove a value from a list
----------------------------------------
*/
/*
----------------------------------------
smart-quote()
----------------------------------------
Quotes strings
Inspects `px`, `xs`, and `xl` numbers
Leaves bools as is
----------------------------------------
*/
/*
----------------------------------------
str-replace()
----------------------------------------
Replace any substring with another
string
----------------------------------------
*/
/*
----------------------------------------
str-split()
----------------------------------------
Split a string at a given separator
and convert into a list of substrings
----------------------------------------
*/
/*
----------------------------------------
strip-unit()
----------------------------------------
Remove the unit of a length
@author Hugo Giraudel
@param {Number} $number - Number to remove unit from
@return {Number} - Unitless number
----------------------------------------
*/
/*
----------------------------------------
base-to-map()
@TODO: Deprecate and delete
----------------------------------------
Convert a single base to a USWDS
value map.

Candidate for deprecation if we remove
isReadable
----------------------------------------
*/
/*
----------------------------------------
to-number()
----------------------------------------
Casts a string into a number
----------------------------------------
@param {String | Number} $value - Value to be parsed
@return {Number}
----------------------------------------
*/
/*
----------------------------------------
unpack()
----------------------------------------
Create lists of single items from lists
of lists.
----------------------------------------
(1, (2.1, 2.2), 3) -->
(1, 2.1, 2.2, 3)
----------------------------------------
*/
/*
----------------------------------------
color()
----------------------------------------
Derive a color from a color shortcode
----------------------------------------
*/
/*
----------------------------------------
color()
----------------------------------------
Derive a color from a color shortcode
----------------------------------------
*/
/*
----------------------------------------
get-system-color()
----------------------------------------
Derive a system color from its
family, value, and vivid or a passed
variable that is, itself, a list
----------------------------------------
*/
/*
----------------------------------------
color()
----------------------------------------
Derive a color from a color shortcode
----------------------------------------
*/
/*
----------------------------------------
color()
----------------------------------------
Derive a color from a color shortcode
----------------------------------------
*/
/*
----------------------------------------
color()
----------------------------------------
Derive a color from a color shortcode
----------------------------------------
*/
/*
----------------------------------------
set-theme-color()
----------------------------------------
Derive a color from a system color token
or a hex value
----------------------------------------
*/
/*
----------------------------------------
px-to-rem()
----------------------------------------
Converts a value in px to a value in rem
----------------------------------------
*/
/*
----------------------------------------
rem-to-user-em()
----------------------------------------
Converts a value in rem to a value in
[user-settings] em for use in media
queries
----------------------------------------
*/
/*
----------------------------------------
px-to-user-em()
----------------------------------------
Converts a value in px to a value in em
----------------------------------------
*/
/*
----------------------------------------
rem-to-px()
----------------------------------------
Converts a value in rem to a value in px
----------------------------------------
*/
/*
----------------------------------------
spacing-multiple()
----------------------------------------
Converts a spacing unit multiple into
the desired final units (currently rem)
----------------------------------------
*/
/*
----------------------------------------
units()
----------------------------------------
Converts a spacing unit into
the desired final units (currently rem)
----------------------------------------
*/
/*
----------------------------------------
number-to-token()
----------------------------------------
Converts an integer or numeric value
into a system value

Ex: 0.5   --> '05'
    -1px  --> 'neg-1px'
----------------------------------------
*/
/*
----------------------------------------
Project fonts
----------------------------------------
Collects font settings in a map for
looping.
----------------------------------------
*/
/*
----------------------------------------
Luminance ranges
----------------------------------------
*/
/*
----------------------------------------
ns()
----------------------------------------
Add a namesspace of $type if that
namespace is set to output
----------------------------------------
*/
/*
----------------------------------------
Line height
----------------------------------------
*/
/*
----------------------------------------
Measure
----------------------------------------
*/
/*
----------------------------------------
cap-height()
----------------------------------------
Get the cap height of a valid typeface
----------------------------------------
*/
/*
----------------------------------------
validate-typeface-token()
----------------------------------------
Check to see if a typeface-token exists.
Throw an error if a passed token does
not exist in the typeface-token map.
----------------------------------------
*/
/*
----------------------------------------
convert-to-font-type()
----------------------------------------
Converts a font-role token into a
font-type token. Leaves font-type tokens
unchanged.
----------------------------------------
*/
/*
----------------------------------------
font-sources()
----------------------------------------
Outputs a list of font sources used in
a @font-face declaration.

$theme-font-browser-compatibility: true - output woff2, woff, ttf
$theme-font-browser-compatibility: false - output woff2

@param stem: string - [font path]/[custom-src.dir]/[custom-src.[style].[weight]]
@output: string

filetypes must be one of the filetypes set in variables/$project-font-face-filetypes (woff, woff2, ttf)
----------------------------------------
*/
/*
----------------------------------------
get-font-stack()
----------------------------------------
Get a font stack from a style- or
role-based font token.
----------------------------------------
*/
/*
----------------------------------------
get-typeface-token()
----------------------------------------
Get a typeface token from a font-type or
font-role token.
----------------------------------------
*/
/*
----------------------------------------
normalize-type-scale()
----------------------------------------
Normalizes a specific face's optical size
to a set target
----------------------------------------
*/
/*
----------------------------------------
system-type-scale()
----------------------------------------
Get a value from the system type scale
----------------------------------------
*/
/*
----------------------------------------
Easing
----------------------------------------
*/
/*  deprecated.scss
    ---
    Occasionally the design system will deprecate
    old variables or functionality. If we replace
    the old functionality with something new, this is a
    place to connect the old functionality to the
    new functionality, in the service of better
    continuity and backwards compatibility within a
    major release cycle.

    Note the USWDS version where we deprecated the
    old functionality in a comment.

    Be sure to update notifications.scss.

    This file should started fresh at each
    major version.
*/
/*
----------------------------------------
advanced-color()
----------------------------------------
Derive a color from a color triplet:
[family], [grade], [variant]
----------------------------------------
*/
/*
----------------------------------------
calculate-grade()
----------------------------------------
Derive the grade equivalent any color,
even non-token colors
----------------------------------------
*/
/*
----------------------------------------
color-token-assignment()
----------------------------------------
Get the system token equivalent of any
theme color token
----------------------------------------
*/
/*
----------------------------------------
is-system-color-token()
----------------------------------------
Return whether a token is a system
color token
----------------------------------------
*/
/*
----------------------------------------
is-theme-color-token()
----------------------------------------
Return whether a token is a theme
color token
----------------------------------------
*/
/*
----------------------------------------
color-token-family()
----------------------------------------
Returns the family of a color token.
Returns: color-family
color-token-family("accent-warm-vivid")
> "accent-warm"
color-token-family("red-50v")
> "red"
color-token-variant(("red", 50, "vivid"))
> "red"
----------------------------------------
*/
/*
----------------------------------------
decompose()
----------------------------------------
Convert a color token into into a list
of form [family], [grade], [variant]
Vivid variants return "vivid" as the
variant.
If neither grade nor variant exists,
returns 'null'
----------------------------------------
*/
/*
----------------------------------------
color-token-family()
----------------------------------------
Returns the family of a color token.
Returns: color-family
color-token-family("accent-warm-vivid")
> "accent-warm"
color-token-family("red-50v")
> "red"
color-token-variant(("red", 50, "vivid"))
> "red"
----------------------------------------
*/
/*
----------------------------------------
color-token-grade()
----------------------------------------
Returns the grade of a USWDS color token.
Returns: color-grade
color-token-grade("accent-warm")
> "root"
color-token-grade("accent-warm-vivid")
> "root"
color-token-grade("accent-warm-darker")
> "darker"
color-token-grade("red-50v")
> 50
color-token-variant(("red", 50, "vivid"))
> 50
----------------------------------------
*/
/*
----------------------------------------
color-token-family()
----------------------------------------
Returns the family of a color token.
Returns: color-family
color-token-family("accent-warm-vivid")
> "accent-warm"
color-token-family("red-50v")
> "red"
color-token-variant(("red", 50, "vivid"))
> "red"
----------------------------------------
*/
/*
----------------------------------------
is-color-token()
----------------------------------------
Returns whether a given string is a
USWDS color token.
----------------------------------------
*/
/*
----------------------------------------
pow()
----------------------------------------
Raises a unitless number to the power
of another unitless number
Includes helper functions
----------------------------------------
*/
/*
----------------------------------------
Helper functions
----------------------------------------
*/
/* factorial()
----------------------------------------
*/
/* summation()
----------------------------------------
*/
/* exp-maclaurin()
----------------------------------------
*/
/* ln()
----------------------------------------
*/
/*
----------------------------------------
color-token-type()
----------------------------------------
Returns the type of a color token.
Returns: "system" | "theme"
----------------------------------------
*/
/*
----------------------------------------
color-token-variant()
----------------------------------------
Returns the variant of color token.
Returns: "vivid" | false
color-token-variant("accent-warm")
> false
color-token-variant("accent-warm-vivid")
> "vivid"
color-token-variant("red-50v")
> "vivid"
color-token-variant(("red", 50, "vivid"))
> "vivid"
----------------------------------------
*/
/*
----------------------------------------
magic-number()
----------------------------------------
Returns the magic number of two color
grades. Takes numbers or color tokens.
magic-number(50, 10)
return: 40
magic-number("red-50", "red-10")
return: 40
----------------------------------------
*/
/*
----------------------------------------
is-accessible-magic-number()
----------------------------------------
Returns whether two grades achieve
specified target color contrast
Returns: true | false
is-accessible-magic-number(10, 50, "AA")
> false
is-accessible-magic-number(10, 60, "AA")
> true
----------------------------------------
*/
/*
----------------------------------------
wcag-magic-number()
----------------------------------------
Returns the magic number of a specific
wcag grade:
"AA"
"AA-Large"
"AAA"
wcag-magic-number("AA")
> 50
----------------------------------------
*/
/*
----------------------------------------
get-link-tokens-from-bg()
----------------------------------------
Get accessible link colors for a given
background color
returns: link-token, hover-token
get-link-tokens-from-bg(
  "black",
  "red-60",
  "red-10",
  "AA")
> "red-10", "red-5"
get-link-tokens-from-bg(
  "black",
  "red-60v",
  "red-10v",
  "AA-large")
> "red-60v", "red-50v"
get-link-tokens-from-bg(
  "black",
  "red-5v",
  "red-60v",
  "AA")
> "red-5v", "white"
get-link-tokens-from-bg(
  "black",
  "white",
  "red-60v",
  "AA")
> "white", "white"
----------------------------------------
*/
/*
----------------------------------------
next-token()
----------------------------------------
Returns next "darker" or "lighter" color
token of the same token type and variant.
Returns: color-token | false
next-token("accent-warm", "lighter")
> "accent-warm-light"
next-token("gray-10", "lighter")
> "gray-5"
next-token("gray-5", "lighter")
> "white"
next-token("white", "lighter")
> false
next-token("red-50v", "darker")
> "red-60v"
next-token("red-50", "darker")
> "red-60"
next-token("red-80v", "darker")
> "red-90"
next-token("red-90", "darker")
> "black"
next-token("white", "darker")
> "gray-5"
next-token("black", "lighter")
> "gray-90"
----------------------------------------
*/
/*
----------------------------------------
test-colors()
----------------------------------------
Check to see if all system colors
fall between the proper relative
luminance range for their grade.
Has a couple quirks, as the luminance()
function returns slightly different
results than expected.
----------------------------------------
*/
/*
----------------------------------------
columns()
----------------------------------------
outputs a grid-col number based on
the number of desired columns in the
12-column grid

Ex: columns(2) --> 6
    grid-col(columns(2))
----------------------------------------
*/
/*
----------------------------------------
USWDS Properties
----------------------------------------
*/
/*
----------------------------------------
get-uswds-value()
----------------------------------------
Finds and outputs a value from the
USWDS standard values.

Used to build other standard utility
functions and mixins.
----------------------------------------
*/
/*
----------------------------------------
get-standard-values()
----------------------------------------
Gets a map of USWDS standard values
for a property
----------------------------------------
*/
/*
----------------------------------------
border-radius()
----------------------------------------
Get a border-radius from the system
border-radii
----------------------------------------
*/
/*
----------------------------------------
font-weight()
fw()
----------------------------------------
Get a font-weight value from the
system font-weight
----------------------------------------
*/
/*
----------------------------------------
feature()
----------------------------------------
Gets a valid USWDS font feature setting
----------------------------------------
*/
/*
----------------------------------------
flex()
----------------------------------------
Gets a valid USWDS flex value
----------------------------------------
*/
/*
----------------------------------------
font-family()
family()
----------------------------------------
Get a font-family stack from a
role-based or type-based font family
----------------------------------------
*/
/*
----------------------------------------
letter-spacing()
ls()
----------------------------------------
Get a letter-spacing value from the
system letter-spacing
----------------------------------------
*/
/*
----------------------------------------
measure()
----------------------------------------
Gets a valid USWDS reading line length
----------------------------------------
*/
/*
----------------------------------------
opacity()
----------------------------------------
Get an opacity from the system
opacities
----------------------------------------
*/
/*
----------------------------------------
order()
----------------------------------------
Get an order value from the
system orders
----------------------------------------
*/
/*
----------------------------------------
radius()
----------------------------------------
Get a border-radius value from the
system letter-spacing
----------------------------------------
*/
/*
----------------------------------------
font-size()
----------------------------------------
Get type scale value from a [family] and
[scale]
----------------------------------------
*/
/*
----------------------------------------
z-index()
z()
----------------------------------------
Get a z-index value from the
system z-index
----------------------------------------
*/
/*
----------------------------------------
utility-font()
----------------------------------------
Get a normalized font-size in rem from
a family and a type size in either
system scale or project scale
----------------------------------------
Not the public-facing function.
Used for building the utilities and
withholds certain errors.
----------------------------------------
*/
/*
----------------------------------------
family()
----------------------------------------
Get a font-family stack
----------------------------------------
*/
/*
----------------------------------------
size()
----------------------------------------
Get a normalized font-size in rem from
a family and a type size in either
system scale or project scale
----------------------------------------
*/
/*
----------------------------------------
font()
----------------------------------------
Get a font-family stack
AND
Get a normalized font-size in rem from
a family and a type size in either
system scale or project scale
----------------------------------------
*/
/*
----------------------------------------
typeset()
----------------------------------------
Sets:
- family
- size
- line-height
----------------------------------------
*/
/* stylelint-disable max-nesting-depth */
/*
----------------------------------------
@render-pseudoclass
----------------------------------------
Build a pseucoclass utiliy from values
calculated in the @render-utilities-in
loop
----------------------------------------
*/
/*
----------------------------------------
@render-utility
----------------------------------------
Build a utility from values calculated
in the @render-utilities-in loop
----------------------------------------
TODO: Determine the proper use of
unquote() in the following. Changed to
account for a 'interpolation near
operators will be simplified in a
future version of Sass' warning.
----------------------------------------
*/
/*
----------------------------------------
@render-utilities-in
----------------------------------------
The master loop that sets the building
blocks of utilities from the values
in individual rule settings and loops
through all possible variants
----------------------------------------
*/
/* stylelint-enable */
/*  notifications.scss
    ---
    Adds a notification at the top of each USWDS
    compile. Use this file for important notifications
    and updates to the design system.

    This file should started fresh at each
    major version.

*/
/* prettier-ignore */
/* prettier-ignore */
/* stylelint-disable */
.vads-grid-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .vads-grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.vads-grid-container-card {
  margin-left: auto;
  margin-right: auto;
  max-width: 10rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .vads-grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.vads-grid-container-card-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: 15rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .vads-grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.vads-grid-container-mobile {
  margin-left: auto;
  margin-right: auto;
  max-width: 20rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .vads-grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.vads-grid-container-mobile-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: 30rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .vads-grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.vads-grid-container-tablet {
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .vads-grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.vads-grid-container-tablet-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: 55rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .vads-grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.vads-grid-container-desktop {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .vads-grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.vads-grid-container-desktop-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .vads-grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.vads-grid-container-widescreen {
  margin-left: auto;
  margin-right: auto;
  max-width: 87.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .vads-grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.vads-grid-container-medium-screen {
  margin-left: auto;
  margin-right: auto;
  max-width: 768px;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 64em) {
  .vads-grid-container-medium-screen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media all and (min-width: 20em) {
  .mobile\:vads-grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 20em) and (min-width: 64em) {
  .mobile\:vads-grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-container-card {
    margin-left: auto;
    margin-right: auto;
    max-width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 20em) and (min-width: 64em) {
  .mobile\:vads-grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-container-card-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 15rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 20em) and (min-width: 64em) {
  .mobile\:vads-grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-container-mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 20em) and (min-width: 64em) {
  .mobile\:vads-grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-container-mobile-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 20em) and (min-width: 64em) {
  .mobile\:vads-grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-container-tablet {
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 20em) and (min-width: 64em) {
  .mobile\:vads-grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-container-tablet-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 55rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 20em) and (min-width: 64em) {
  .mobile\:vads-grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-container-desktop {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 20em) and (min-width: 64em) {
  .mobile\:vads-grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-container-desktop-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 20em) and (min-width: 64em) {
  .mobile\:vads-grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-container-widescreen {
    margin-left: auto;
    margin-right: auto;
    max-width: 87.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 20em) and (min-width: 64em) {
  .mobile\:vads-grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-container-medium-screen {
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 20em) and (min-width: 64em) {
  .mobile\:vads-grid-container-medium-screen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:vads-grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-container-card {
    margin-left: auto;
    margin-right: auto;
    max-width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:vads-grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-container-card-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 15rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:vads-grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-container-mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:vads-grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-container-mobile-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:vads-grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-container-tablet {
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:vads-grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-container-tablet-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 55rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:vads-grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-container-desktop {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:vads-grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-container-desktop-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:vads-grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-container-widescreen {
    margin-left: auto;
    margin-right: auto;
    max-width: 87.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:vads-grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-container-medium-screen {
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 30em) and (min-width: 64em) {
  .mobile-lg\:vads-grid-container-medium-screen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:vads-grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-container-card {
    margin-left: auto;
    margin-right: auto;
    max-width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:vads-grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-container-card-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 15rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:vads-grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-container-mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:vads-grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-container-mobile-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:vads-grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-container-tablet {
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:vads-grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-container-tablet-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 55rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:vads-grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-container-desktop {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:vads-grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-container-desktop-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:vads-grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-container-widescreen {
    margin-left: auto;
    margin-right: auto;
    max-width: 87.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:vads-grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-container-medium-screen {
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 40em) and (min-width: 64em) {
  .tablet\:vads-grid-container-medium-screen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:vads-grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-container-card {
    margin-left: auto;
    margin-right: auto;
    max-width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:vads-grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-container-card-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 15rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:vads-grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-container-mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:vads-grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-container-mobile-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:vads-grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-container-tablet {
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:vads-grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-container-tablet-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 55rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:vads-grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-container-desktop {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:vads-grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-container-desktop-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:vads-grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-container-widescreen {
    margin-left: auto;
    margin-right: auto;
    max-width: 87.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:vads-grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-container-medium-screen {
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 64em) and (min-width: 64em) {
  .desktop\:vads-grid-container-medium-screen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 75em) and (min-width: 64em) {
  .desktop-lg\:vads-grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-container-card {
    margin-left: auto;
    margin-right: auto;
    max-width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 75em) and (min-width: 64em) {
  .desktop-lg\:vads-grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-container-card-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 15rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 75em) and (min-width: 64em) {
  .desktop-lg\:vads-grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-container-mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 75em) and (min-width: 64em) {
  .desktop-lg\:vads-grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-container-mobile-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 75em) and (min-width: 64em) {
  .desktop-lg\:vads-grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-container-tablet {
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 75em) and (min-width: 64em) {
  .desktop-lg\:vads-grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-container-tablet-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 55rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 75em) and (min-width: 64em) {
  .desktop-lg\:vads-grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-container-desktop {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 75em) and (min-width: 64em) {
  .desktop-lg\:vads-grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-container-desktop-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 75em) and (min-width: 64em) {
  .desktop-lg\:vads-grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-container-widescreen {
    margin-left: auto;
    margin-right: auto;
    max-width: 87.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 75em) and (min-width: 64em) {
  .desktop-lg\:vads-grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-container-medium-screen {
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 75em) and (min-width: 64em) {
  .desktop-lg\:vads-grid-container-medium-screen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 48em) and (min-width: 64em) {
  .medium-screen\:vads-grid-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-container-card {
    margin-left: auto;
    margin-right: auto;
    max-width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 48em) and (min-width: 64em) {
  .medium-screen\:vads-grid-container-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-container-card-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 15rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 48em) and (min-width: 64em) {
  .medium-screen\:vads-grid-container-card-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-container-mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 48em) and (min-width: 64em) {
  .medium-screen\:vads-grid-container-mobile {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-container-mobile-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 48em) and (min-width: 64em) {
  .medium-screen\:vads-grid-container-mobile-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-container-tablet {
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 48em) and (min-width: 64em) {
  .medium-screen\:vads-grid-container-tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-container-tablet-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 55rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 48em) and (min-width: 64em) {
  .medium-screen\:vads-grid-container-tablet-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-container-desktop {
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 48em) and (min-width: 64em) {
  .medium-screen\:vads-grid-container-desktop {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-container-desktop-lg {
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 48em) and (min-width: 64em) {
  .medium-screen\:vads-grid-container-desktop-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-container-widescreen {
    margin-left: auto;
    margin-right: auto;
    max-width: 87.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 48em) and (min-width: 64em) {
  .medium-screen\:vads-grid-container-widescreen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-container-medium-screen {
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media all and (min-width: 48em) and (min-width: 64em) {
  .medium-screen\:vads-grid-container-medium-screen {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.vads-grid-row {
  display: flex;
  flex-wrap: wrap;
}
.vads-grid-row.vads-grid-gap {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.vads-grid-row.vads-grid-gap > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media all and (min-width: 64em) {
  .vads-grid-row.vads-grid-gap {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .vads-grid-row.vads-grid-gap > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.vads-grid-row.vads-grid-gap-0 {
  margin-left: 0;
  margin-right: 0;
}
.vads-grid-row.vads-grid-gap-0 > * {
  padding-left: 0;
  padding-right: 0;
}
.vads-grid-row.vads-grid-gap-2px {
  margin-left: -1px;
  margin-right: -1px;
}
.vads-grid-row.vads-grid-gap-2px > * {
  padding-left: 1px;
  padding-right: 1px;
}
.vads-grid-row.vads-grid-gap-05 {
  margin-left: -2px;
  margin-right: -2px;
}
.vads-grid-row.vads-grid-gap-05 > * {
  padding-left: 2px;
  padding-right: 2px;
}
.vads-grid-row.vads-grid-gap-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.vads-grid-row.vads-grid-gap-1 > * {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.vads-grid-row.vads-grid-gap-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.vads-grid-row.vads-grid-gap-2 > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.vads-grid-row.vads-grid-gap-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.vads-grid-row.vads-grid-gap-3 > * {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.vads-grid-row.vads-grid-gap-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}
.vads-grid-row.vads-grid-gap-4 > * {
  padding-left: 1rem;
  padding-right: 1rem;
}
.vads-grid-row.vads-grid-gap-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
.vads-grid-row.vads-grid-gap-5 > * {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.vads-grid-row.vads-grid-gap-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.vads-grid-row.vads-grid-gap-6 > * {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.vads-grid-row.vads-grid-gap-sm {
  margin-left: -1px;
  margin-right: -1px;
}
.vads-grid-row.vads-grid-gap-sm > * {
  padding-left: 1px;
  padding-right: 1px;
}
.vads-grid-row.vads-grid-gap-md {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.vads-grid-row.vads-grid-gap-md > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.vads-grid-row.vads-grid-gap-lg {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.vads-grid-row.vads-grid-gap-lg > * {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
@media all and (min-width: 20em) {
  .vads-grid-row.mobile\:vads-grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .vads-grid-row.mobile\:vads-grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .vads-grid-row.mobile\:vads-grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .vads-grid-row.mobile\:vads-grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .vads-grid-row.mobile\:vads-grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .vads-grid-row.mobile\:vads-grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }
  .vads-grid-row.mobile\:vads-grid-gap-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .vads-grid-row.mobile\:vads-grid-gap-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .vads-grid-row.mobile\:vads-grid-gap-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .vads-grid-row.mobile\:vads-grid-gap-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .vads-grid-row.mobile\:vads-grid-gap-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .vads-grid-row.mobile\:vads-grid-gap-3 > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .vads-grid-row.mobile\:vads-grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .vads-grid-row.mobile\:vads-grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .vads-grid-row.mobile\:vads-grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .vads-grid-row.mobile\:vads-grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .vads-grid-row.mobile\:vads-grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .vads-grid-row.mobile\:vads-grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .vads-grid-row.mobile\:vads-grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }
  .vads-grid-row.mobile\:vads-grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .vads-grid-row.mobile\:vads-grid-gap-md {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .vads-grid-row.mobile\:vads-grid-gap-md > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .vads-grid-row.mobile\:vads-grid-gap-lg {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .vads-grid-row.mobile\:vads-grid-gap-lg > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
@media all and (min-width: 30em) {
  .vads-grid-row.mobile-lg\:vads-grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-3 > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-md {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-md > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-lg {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .vads-grid-row.mobile-lg\:vads-grid-gap-lg > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
@media all and (min-width: 40em) {
  .vads-grid-row.tablet\:vads-grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .vads-grid-row.tablet\:vads-grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .vads-grid-row.tablet\:vads-grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .vads-grid-row.tablet\:vads-grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .vads-grid-row.tablet\:vads-grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .vads-grid-row.tablet\:vads-grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }
  .vads-grid-row.tablet\:vads-grid-gap-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .vads-grid-row.tablet\:vads-grid-gap-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .vads-grid-row.tablet\:vads-grid-gap-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .vads-grid-row.tablet\:vads-grid-gap-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .vads-grid-row.tablet\:vads-grid-gap-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .vads-grid-row.tablet\:vads-grid-gap-3 > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .vads-grid-row.tablet\:vads-grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .vads-grid-row.tablet\:vads-grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .vads-grid-row.tablet\:vads-grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .vads-grid-row.tablet\:vads-grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .vads-grid-row.tablet\:vads-grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .vads-grid-row.tablet\:vads-grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .vads-grid-row.tablet\:vads-grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }
  .vads-grid-row.tablet\:vads-grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .vads-grid-row.tablet\:vads-grid-gap-md {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .vads-grid-row.tablet\:vads-grid-gap-md > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .vads-grid-row.tablet\:vads-grid-gap-lg {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .vads-grid-row.tablet\:vads-grid-gap-lg > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
@media all and (min-width: 64em) {
  .vads-grid-row.desktop\:vads-grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .vads-grid-row.desktop\:vads-grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .vads-grid-row.desktop\:vads-grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .vads-grid-row.desktop\:vads-grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .vads-grid-row.desktop\:vads-grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .vads-grid-row.desktop\:vads-grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }
  .vads-grid-row.desktop\:vads-grid-gap-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .vads-grid-row.desktop\:vads-grid-gap-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .vads-grid-row.desktop\:vads-grid-gap-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .vads-grid-row.desktop\:vads-grid-gap-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .vads-grid-row.desktop\:vads-grid-gap-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .vads-grid-row.desktop\:vads-grid-gap-3 > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .vads-grid-row.desktop\:vads-grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .vads-grid-row.desktop\:vads-grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .vads-grid-row.desktop\:vads-grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .vads-grid-row.desktop\:vads-grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .vads-grid-row.desktop\:vads-grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .vads-grid-row.desktop\:vads-grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .vads-grid-row.desktop\:vads-grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }
  .vads-grid-row.desktop\:vads-grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .vads-grid-row.desktop\:vads-grid-gap-md {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .vads-grid-row.desktop\:vads-grid-gap-md > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .vads-grid-row.desktop\:vads-grid-gap-lg {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .vads-grid-row.desktop\:vads-grid-gap-lg > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
@media all and (min-width: 75em) {
  .vads-grid-row.desktop-lg\:vads-grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-3 > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-md {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-md > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-lg {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .vads-grid-row.desktop-lg\:vads-grid-gap-lg > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
@media all and (min-width: 48em) {
  .vads-grid-row.medium-screen\:vads-grid-gap-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-2px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-2px > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-05 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-05 > * {
    padding-left: 2px;
    padding-right: 2px;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-3 > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-4 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-5 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-6 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-sm {
    margin-left: -1px;
    margin-right: -1px;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-sm > * {
    padding-left: 1px;
    padding-right: 1px;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-md {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-md > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-lg {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .vads-grid-row.medium-screen\:vads-grid-gap-lg > * {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

[class*=vads-grid-col] {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.vads-grid-col {
  flex: 1 1 0%;
  width: auto;
  max-width: 100%;
  min-width: 1px;
}

.vads-grid-col-auto {
  flex: 0 1 auto;
  width: auto;
  max-width: 100%;
}

.vads-grid-col-fill {
  flex: 1 1 0%;
  width: auto;
  max-width: 100%;
  min-width: 1px;
}

.vads-grid-col-1 {
  flex: 0 1 auto;
  width: 8.3333333333%;
}

.vads-grid-col-2 {
  flex: 0 1 auto;
  width: 16.6666666667%;
}

.vads-grid-col-3 {
  flex: 0 1 auto;
  width: 25%;
}

.vads-grid-col-4 {
  flex: 0 1 auto;
  width: 33.3333333333%;
}

.vads-grid-col-5 {
  flex: 0 1 auto;
  width: 41.6666666667%;
}

.vads-grid-col-6 {
  flex: 0 1 auto;
  width: 50%;
}

.vads-grid-col-7 {
  flex: 0 1 auto;
  width: 58.3333333333%;
}

.vads-grid-col-8 {
  flex: 0 1 auto;
  width: 66.6666666667%;
}

.vads-grid-col-9 {
  flex: 0 1 auto;
  width: 75%;
}

.vads-grid-col-10 {
  flex: 0 1 auto;
  width: 83.3333333333%;
}

.vads-grid-col-11 {
  flex: 0 1 auto;
  width: 91.6666666667%;
}

.vads-grid-col-12 {
  flex: 0 1 auto;
  width: 100%;
}

@media all and (min-width: 20em) {
  .mobile\:vads-grid-col {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .mobile\:vads-grid-col-fill {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .mobile\:vads-grid-col-auto {
    flex: 0 1 auto;
    width: auto;
    max-width: 100%;
  }
  .mobile\:vads-grid-col-1 {
    flex: 0 1 auto;
    width: 8.3333333333%;
  }
  .mobile\:vads-grid-col-2 {
    flex: 0 1 auto;
    width: 16.6666666667%;
  }
  .mobile\:vads-grid-col-3 {
    flex: 0 1 auto;
    width: 25%;
  }
  .mobile\:vads-grid-col-4 {
    flex: 0 1 auto;
    width: 33.3333333333%;
  }
  .mobile\:vads-grid-col-5 {
    flex: 0 1 auto;
    width: 41.6666666667%;
  }
  .mobile\:vads-grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
  .mobile\:vads-grid-col-7 {
    flex: 0 1 auto;
    width: 58.3333333333%;
  }
  .mobile\:vads-grid-col-8 {
    flex: 0 1 auto;
    width: 66.6666666667%;
  }
  .mobile\:vads-grid-col-9 {
    flex: 0 1 auto;
    width: 75%;
  }
  .mobile\:vads-grid-col-10 {
    flex: 0 1 auto;
    width: 83.3333333333%;
  }
  .mobile\:vads-grid-col-11 {
    flex: 0 1 auto;
    width: 91.6666666667%;
  }
  .mobile\:vads-grid-col-12 {
    flex: 0 1 auto;
    width: 100%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-col {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .mobile-lg\:vads-grid-col-fill {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .mobile-lg\:vads-grid-col-auto {
    flex: 0 1 auto;
    width: auto;
    max-width: 100%;
  }
  .mobile-lg\:vads-grid-col-1 {
    flex: 0 1 auto;
    width: 8.3333333333%;
  }
  .mobile-lg\:vads-grid-col-2 {
    flex: 0 1 auto;
    width: 16.6666666667%;
  }
  .mobile-lg\:vads-grid-col-3 {
    flex: 0 1 auto;
    width: 25%;
  }
  .mobile-lg\:vads-grid-col-4 {
    flex: 0 1 auto;
    width: 33.3333333333%;
  }
  .mobile-lg\:vads-grid-col-5 {
    flex: 0 1 auto;
    width: 41.6666666667%;
  }
  .mobile-lg\:vads-grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
  .mobile-lg\:vads-grid-col-7 {
    flex: 0 1 auto;
    width: 58.3333333333%;
  }
  .mobile-lg\:vads-grid-col-8 {
    flex: 0 1 auto;
    width: 66.6666666667%;
  }
  .mobile-lg\:vads-grid-col-9 {
    flex: 0 1 auto;
    width: 75%;
  }
  .mobile-lg\:vads-grid-col-10 {
    flex: 0 1 auto;
    width: 83.3333333333%;
  }
  .mobile-lg\:vads-grid-col-11 {
    flex: 0 1 auto;
    width: 91.6666666667%;
  }
  .mobile-lg\:vads-grid-col-12 {
    flex: 0 1 auto;
    width: 100%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-col {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .tablet\:vads-grid-col-fill {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .tablet\:vads-grid-col-auto {
    flex: 0 1 auto;
    width: auto;
    max-width: 100%;
  }
  .tablet\:vads-grid-col-1 {
    flex: 0 1 auto;
    width: 8.3333333333%;
  }
  .tablet\:vads-grid-col-2 {
    flex: 0 1 auto;
    width: 16.6666666667%;
  }
  .tablet\:vads-grid-col-3 {
    flex: 0 1 auto;
    width: 25%;
  }
  .tablet\:vads-grid-col-4 {
    flex: 0 1 auto;
    width: 33.3333333333%;
  }
  .tablet\:vads-grid-col-5 {
    flex: 0 1 auto;
    width: 41.6666666667%;
  }
  .tablet\:vads-grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
  .tablet\:vads-grid-col-7 {
    flex: 0 1 auto;
    width: 58.3333333333%;
  }
  .tablet\:vads-grid-col-8 {
    flex: 0 1 auto;
    width: 66.6666666667%;
  }
  .tablet\:vads-grid-col-9 {
    flex: 0 1 auto;
    width: 75%;
  }
  .tablet\:vads-grid-col-10 {
    flex: 0 1 auto;
    width: 83.3333333333%;
  }
  .tablet\:vads-grid-col-11 {
    flex: 0 1 auto;
    width: 91.6666666667%;
  }
  .tablet\:vads-grid-col-12 {
    flex: 0 1 auto;
    width: 100%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-col {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .desktop\:vads-grid-col-fill {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .desktop\:vads-grid-col-auto {
    flex: 0 1 auto;
    width: auto;
    max-width: 100%;
  }
  .desktop\:vads-grid-col-1 {
    flex: 0 1 auto;
    width: 8.3333333333%;
  }
  .desktop\:vads-grid-col-2 {
    flex: 0 1 auto;
    width: 16.6666666667%;
  }
  .desktop\:vads-grid-col-3 {
    flex: 0 1 auto;
    width: 25%;
  }
  .desktop\:vads-grid-col-4 {
    flex: 0 1 auto;
    width: 33.3333333333%;
  }
  .desktop\:vads-grid-col-5 {
    flex: 0 1 auto;
    width: 41.6666666667%;
  }
  .desktop\:vads-grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
  .desktop\:vads-grid-col-7 {
    flex: 0 1 auto;
    width: 58.3333333333%;
  }
  .desktop\:vads-grid-col-8 {
    flex: 0 1 auto;
    width: 66.6666666667%;
  }
  .desktop\:vads-grid-col-9 {
    flex: 0 1 auto;
    width: 75%;
  }
  .desktop\:vads-grid-col-10 {
    flex: 0 1 auto;
    width: 83.3333333333%;
  }
  .desktop\:vads-grid-col-11 {
    flex: 0 1 auto;
    width: 91.6666666667%;
  }
  .desktop\:vads-grid-col-12 {
    flex: 0 1 auto;
    width: 100%;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-col {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .desktop-lg\:vads-grid-col-fill {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .desktop-lg\:vads-grid-col-auto {
    flex: 0 1 auto;
    width: auto;
    max-width: 100%;
  }
  .desktop-lg\:vads-grid-col-1 {
    flex: 0 1 auto;
    width: 8.3333333333%;
  }
  .desktop-lg\:vads-grid-col-2 {
    flex: 0 1 auto;
    width: 16.6666666667%;
  }
  .desktop-lg\:vads-grid-col-3 {
    flex: 0 1 auto;
    width: 25%;
  }
  .desktop-lg\:vads-grid-col-4 {
    flex: 0 1 auto;
    width: 33.3333333333%;
  }
  .desktop-lg\:vads-grid-col-5 {
    flex: 0 1 auto;
    width: 41.6666666667%;
  }
  .desktop-lg\:vads-grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
  .desktop-lg\:vads-grid-col-7 {
    flex: 0 1 auto;
    width: 58.3333333333%;
  }
  .desktop-lg\:vads-grid-col-8 {
    flex: 0 1 auto;
    width: 66.6666666667%;
  }
  .desktop-lg\:vads-grid-col-9 {
    flex: 0 1 auto;
    width: 75%;
  }
  .desktop-lg\:vads-grid-col-10 {
    flex: 0 1 auto;
    width: 83.3333333333%;
  }
  .desktop-lg\:vads-grid-col-11 {
    flex: 0 1 auto;
    width: 91.6666666667%;
  }
  .desktop-lg\:vads-grid-col-12 {
    flex: 0 1 auto;
    width: 100%;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-col {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .medium-screen\:vads-grid-col-fill {
    flex: 1 1 0%;
    width: auto;
    max-width: 100%;
    min-width: 1px;
  }
  .medium-screen\:vads-grid-col-auto {
    flex: 0 1 auto;
    width: auto;
    max-width: 100%;
  }
  .medium-screen\:vads-grid-col-1 {
    flex: 0 1 auto;
    width: 8.3333333333%;
  }
  .medium-screen\:vads-grid-col-2 {
    flex: 0 1 auto;
    width: 16.6666666667%;
  }
  .medium-screen\:vads-grid-col-3 {
    flex: 0 1 auto;
    width: 25%;
  }
  .medium-screen\:vads-grid-col-4 {
    flex: 0 1 auto;
    width: 33.3333333333%;
  }
  .medium-screen\:vads-grid-col-5 {
    flex: 0 1 auto;
    width: 41.6666666667%;
  }
  .medium-screen\:vads-grid-col-6 {
    flex: 0 1 auto;
    width: 50%;
  }
  .medium-screen\:vads-grid-col-7 {
    flex: 0 1 auto;
    width: 58.3333333333%;
  }
  .medium-screen\:vads-grid-col-8 {
    flex: 0 1 auto;
    width: 66.6666666667%;
  }
  .medium-screen\:vads-grid-col-9 {
    flex: 0 1 auto;
    width: 75%;
  }
  .medium-screen\:vads-grid-col-10 {
    flex: 0 1 auto;
    width: 83.3333333333%;
  }
  .medium-screen\:vads-grid-col-11 {
    flex: 0 1 auto;
    width: 91.6666666667%;
  }
  .medium-screen\:vads-grid-col-12 {
    flex: 0 1 auto;
    width: 100%;
  }
}
.vads-grid-offset-1 {
  margin-left: 8.3333333333%;
}

.vads-grid-offset-2 {
  margin-left: 16.6666666667%;
}

.vads-grid-offset-3 {
  margin-left: 25%;
}

.vads-grid-offset-4 {
  margin-left: 33.3333333333%;
}

.vads-grid-offset-5 {
  margin-left: 41.6666666667%;
}

.vads-grid-offset-6 {
  margin-left: 50%;
}

.vads-grid-offset-7 {
  margin-left: 58.3333333333%;
}

.vads-grid-offset-8 {
  margin-left: 66.6666666667%;
}

.vads-grid-offset-9 {
  margin-left: 75%;
}

.vads-grid-offset-10 {
  margin-left: 83.3333333333%;
}

.vads-grid-offset-11 {
  margin-left: 91.6666666667%;
}

.vads-grid-offset-12 {
  margin-left: 100%;
}

.vads-grid-offset-none {
  margin-left: 0;
}

@media all and (min-width: 20em) {
  .mobile\:vads-grid-offset-1 {
    margin-left: 8.3333333333%;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-offset-2 {
    margin-left: 16.6666666667%;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-offset-3 {
    margin-left: 25%;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-offset-4 {
    margin-left: 33.3333333333%;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-offset-5 {
    margin-left: 41.6666666667%;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-offset-6 {
    margin-left: 50%;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-offset-7 {
    margin-left: 58.3333333333%;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-offset-8 {
    margin-left: 66.6666666667%;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-offset-9 {
    margin-left: 75%;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-offset-10 {
    margin-left: 83.3333333333%;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-offset-12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 20em) {
  .mobile\:vads-grid-offset-none {
    margin-left: 0;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-offset-1 {
    margin-left: 8.3333333333%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-offset-2 {
    margin-left: 16.6666666667%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-offset-3 {
    margin-left: 25%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-offset-4 {
    margin-left: 33.3333333333%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-offset-5 {
    margin-left: 41.6666666667%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-offset-6 {
    margin-left: 50%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-offset-7 {
    margin-left: 58.3333333333%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-offset-8 {
    margin-left: 66.6666666667%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-offset-9 {
    margin-left: 75%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-offset-10 {
    margin-left: 83.3333333333%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-offset-12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-grid-offset-none {
    margin-left: 0;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-offset-1 {
    margin-left: 8.3333333333%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-offset-2 {
    margin-left: 16.6666666667%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-offset-3 {
    margin-left: 25%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-offset-4 {
    margin-left: 33.3333333333%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-offset-5 {
    margin-left: 41.6666666667%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-offset-6 {
    margin-left: 50%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-offset-7 {
    margin-left: 58.3333333333%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-offset-8 {
    margin-left: 66.6666666667%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-offset-9 {
    margin-left: 75%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-offset-10 {
    margin-left: 83.3333333333%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-offset-12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-grid-offset-none {
    margin-left: 0;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-offset-1 {
    margin-left: 8.3333333333%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-offset-2 {
    margin-left: 16.6666666667%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-offset-3 {
    margin-left: 25%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-offset-4 {
    margin-left: 33.3333333333%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-offset-5 {
    margin-left: 41.6666666667%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-offset-6 {
    margin-left: 50%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-offset-7 {
    margin-left: 58.3333333333%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-offset-8 {
    margin-left: 66.6666666667%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-offset-9 {
    margin-left: 75%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-offset-10 {
    margin-left: 83.3333333333%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-offset-12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-grid-offset-none {
    margin-left: 0;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-offset-1 {
    margin-left: 8.3333333333%;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-offset-2 {
    margin-left: 16.6666666667%;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-offset-3 {
    margin-left: 25%;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-offset-4 {
    margin-left: 33.3333333333%;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-offset-5 {
    margin-left: 41.6666666667%;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-offset-6 {
    margin-left: 50%;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-offset-7 {
    margin-left: 58.3333333333%;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-offset-8 {
    margin-left: 66.6666666667%;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-offset-9 {
    margin-left: 75%;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-offset-10 {
    margin-left: 83.3333333333%;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-offset-12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-grid-offset-none {
    margin-left: 0;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-offset-1 {
    margin-left: 8.3333333333%;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-offset-2 {
    margin-left: 16.6666666667%;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-offset-3 {
    margin-left: 25%;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-offset-4 {
    margin-left: 33.3333333333%;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-offset-5 {
    margin-left: 41.6666666667%;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-offset-6 {
    margin-left: 50%;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-offset-7 {
    margin-left: 58.3333333333%;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-offset-8 {
    margin-left: 66.6666666667%;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-offset-9 {
    margin-left: 75%;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-offset-10 {
    margin-left: 83.3333333333%;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-offset-12 {
    margin-left: 100%;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-grid-offset-none {
    margin-left: 0;
  }
}
/* stylelint-enable */
.vads-u-visibility--screen-reader {
  position: absolute;
  left: -999em;
  right: auto;
}

.vads-u-background-color--base {
  background-color: #1b1b1b !important;
}

.vads-u-background-color--white {
  background-color: #ffffff !important;
}

.vads-u-background-color--black {
  background-color: #000000 !important;
}

.vads-u-background-color--link-default {
  background-color: #005ea2 !important;
}

.vads-u-background-color--warning-message {
  background-color: #8a7237 !important;
}

.vads-u-background-color--primary {
  background-color: #005ea2 !important;
}

.vads-u-background-color--primary-dark {
  background-color: #1a4480 !important;
}

.vads-u-background-color--primary-darker {
  background-color: #162e51 !important;
}

.vads-u-background-color--primary-alt {
  background-color: #00bde3 !important;
}

.vads-u-background-color--primary-alt-dark {
  background-color: #28a0cb !important;
}

.vads-u-background-color--primary-alt-darkest {
  background-color: #07648d !important;
}

.vads-u-background-color--primary-alt-light {
  background-color: #97d4ea !important;
}

.vads-u-background-color--primary-alt-lightest {
  background-color: #e1f3f8 !important;
}

.vads-u-background-color--secondary {
  background-color: #d83933 !important;
}

.vads-u-background-color--secondary-dark {
  background-color: #b50909 !important;
}

.vads-u-background-color--secondary-darkest {
  background-color: #8b0a03 !important;
}

.vads-u-background-color--secondary-light {
  background-color: #f2938c !important;
}

.vads-u-background-color--secondary-lightest {
  background-color: #f8dfe2 !important;
}

.vads-u-background-color--gray {
  background-color: #565c65 !important;
}

.vads-u-background-color--gray-dark {
  background-color: #3d4551 !important;
}

.vads-u-background-color--gray-medium {
  background-color: #757575 !important;
}

.vads-u-background-color--gray-light {
  background-color: #a9aeb1 !important;
}

.vads-u-background-color--gray-light-alt {
  background-color: #edeff0 !important;
}

.vads-u-background-color--gray-lighter {
  background-color: #dfe1e2 !important;
}

.vads-u-background-color--gray-lightest {
  background-color: #f0f0f0 !important;
}

.vads-u-background-color--gray-warm-dark {
  background-color: #454540 !important;
}

.vads-u-background-color--gray-warm-light {
  background-color: #e6e6e2 !important;
}

.vads-u-background-color--gray-cool-light {
  background-color: #e1e7f1 !important;
}

.vads-u-background-color--gold {
  background-color: #ffbe2e !important;
}

.vads-u-background-color--gold-darker {
  background-color: #936f38 !important;
}

.vads-u-background-color--gold-light {
  background-color: #face00 !important;
}

.vads-u-background-color--gold-lighter {
  background-color: #ffe396 !important;
}

.vads-u-background-color--gold-lightest {
  background-color: #fef0c8 !important;
}

.vads-u-background-color--green {
  background-color: #008817 !important;
}

.vads-u-background-color--green-darker {
  background-color: #154c21 !important;
}

.vads-u-background-color--green-light {
  background-color: #5e9f69 !important;
}

.vads-u-background-color--green-lighter {
  background-color: #b4d0b9 !important;
}

.vads-u-background-color--green-lightest {
  background-color: #ecf3ec !important;
}

.vads-u-background-color--cool-blue {
  background-color: #345d96 !important;
}

.vads-u-background-color--cool-blue-light {
  background-color: #4a77b4 !important;
}

.vads-u-background-color--cool-blue-lightest {
  background-color: #e6e6e2 !important;
}

.vads-u-background-color--hub-health-care {
  background-color: #3f57a6 !important;
}

.vads-u-background-color--hub-education {
  background-color: #162e51 !important;
}

.vads-u-background-color--hub-disability {
  background-color: #a23737 !important;
}

.vads-u-background-color--hub-careers {
  background-color: #bd5727 !important;
}

.vads-u-background-color--hub-pension {
  background-color: #4d8055 !important;
}

.vads-u-background-color--hub-housing {
  background-color: #8e704f !important;
}

.vads-u-background-color--hub-life-insurance {
  background-color: #6f7a41 !important;
}

.vads-u-background-color--hub-burials {
  background-color: #2378c3 !important;
}

.vads-u-background-color--hub-family-member {
  background-color: #162e51 !important;
}

.vads-u-background-color--hub-service-member {
  background-color: #162e51 !important;
}

.vads-u-background-color--hub-records {
  background-color: #6f7a41 !important;
}

.vads-u-background-color--action-border-base-active-on-dark {
  background-color: #adadad !important;
}

.vads-u-background-color--action-border-base-active-on-light {
  background-color: #2e2e2e !important;
}

.vads-u-background-color--action-focus-on-light {
  background-color: #face00 !important;
}

.vads-u-background-color--action-surface-default-on-dark {
  background-color: #58b4ff !important;
}

.vads-u-background-color--action-surface-destructive-on-dark {
  background-color: #fb5a47 !important;
}

.vads-u-background-color--background-default-on-dark {
  background-color: #171716 !important;
}

.vads-u-background-color--base-dark {
  background-color: #565c65 !important;
}

.vads-u-background-color--base-darker {
  background-color: #3d4551 !important;
}

.vads-u-background-color--base-darkest {
  background-color: #1b1b1b !important;
}

.vads-u-background-color--base-light {
  background-color: #a9aeb1 !important;
}

.vads-u-background-color--base-lighter {
  background-color: #dfe1e2 !important;
}

.vads-u-background-color--base-lightest {
  background-color: #f0f0f0 !important;
}

.vads-u-background-color--emergency {
  background-color: #9c3d10 !important;
}

.vads-u-background-color--emergency-dark {
  background-color: #332d29 !important;
}

.vads-u-background-color--error {
  background-color: #d54309 !important;
}

.vads-u-background-color--error-dark {
  background-color: #b21d38 !important;
}

.vads-u-background-color--error-darker {
  background-color: #6f3331 !important;
}

.vads-u-background-color--error-light {
  background-color: #f39268 !important;
}

.vads-u-background-color--error-lighter {
  background-color: #f4e3db !important;
}

.vads-u-background-color--feedback-border-warning-on-dark {
  background-color: #face00 !important;
}

.vads-u-background-color--feedback-surface-error-on-dark {
  background-color: #5c1111 !important;
}

.vads-u-background-color--feedback-surface-info-on-dark {
  background-color: #112f4e !important;
}

.vads-u-background-color--feedback-surface-success-on-dark {
  background-color: #19311e !important;
}

.vads-u-background-color--feedback-surface-warning-on-dark {
  background-color: #422d19 !important;
}

.vads-u-background-color--feedback-forms-active-on-dark {
  background-color: #252f3e !important;
}

.vads-u-background-color--feedback-forms-active-on-light {
  background-color: #ecf1f7 !important;
}

.vads-u-background-color--gibill-accent {
  background-color: #fef0c8 !important;
}

.vads-u-background-color--info {
  background-color: #00bde3 !important;
}

.vads-u-background-color--info-dark {
  background-color: #009ec1 !important;
}

.vads-u-background-color--info-darker {
  background-color: #2e6276 !important;
}

.vads-u-background-color--info-light {
  background-color: #99deea !important;
}

.vads-u-background-color--info-lighter {
  background-color: #e7f6f8 !important;
}

.vads-u-background-color--ink {
  background-color: #1b1b1b !important;
}

.vads-u-background-color--inset-bg {
  background-color: #e7f6f8 !important;
}

.vads-u-background-color--link {
  background-color: #005ea2 !important;
}

.vads-u-background-color--link-active {
  background-color: #0b4778 !important;
}

.vads-u-background-color--link-visited {
  background-color: #54278f !important;
}

.vads-u-background-color--success {
  background-color: #00a91c !important;
}

.vads-u-background-color--success-dark {
  background-color: #008817 !important;
}

.vads-u-background-color--success-darker {
  background-color: #216e1f !important;
}

.vads-u-background-color--success-light {
  background-color: #70e17b !important;
}

.vads-u-background-color--success-lighter {
  background-color: #ecf3ec !important;
}

.vads-u-background-color--va-accent {
  background-color: #8a7237 !important;
}

.vads-u-background-color--warning {
  background-color: #ffbe2e !important;
}

.vads-u-background-color--warning-dark {
  background-color: #e5a000 !important;
}

.vads-u-background-color--warning-darker {
  background-color: #936f38 !important;
}

.vads-u-background-color--warning-light {
  background-color: #fee685 !important;
}

.vads-u-background-color--warning-lighter {
  background-color: #faf3d1 !important;
}

.vads-u-border--0 {
  border: 0 !important;
}

.vads-u-border-top--0 {
  border-top: 0 !important;
}

.vads-u-border-right--0 {
  border-right: 0 !important;
}

.vads-u-border-bottom--0 {
  border-bottom: 0 !important;
}

.vads-u-border-left--0 {
  border-left: 0 !important;
}

.vads-u-border--1px {
  border: 1px solid !important;
}

.vads-u-border-top--1px {
  border-top: 1px solid !important;
}

.vads-u-border-right--1px {
  border-right: 1px solid !important;
}

.vads-u-border-bottom--1px {
  border-bottom: 1px solid !important;
}

.vads-u-border-left--1px {
  border-left: 1px solid !important;
}

.vads-u-border--2px {
  border: 2px solid !important;
}

.vads-u-border-top--2px {
  border-top: 2px solid !important;
}

.vads-u-border-right--2px {
  border-right: 2px solid !important;
}

.vads-u-border-bottom--2px {
  border-bottom: 2px solid !important;
}

.vads-u-border-left--2px {
  border-left: 2px solid !important;
}

.vads-u-border--3px {
  border: 3px solid !important;
}

.vads-u-border-top--3px {
  border-top: 3px solid !important;
}

.vads-u-border-right--3px {
  border-right: 3px solid !important;
}

.vads-u-border-bottom--3px {
  border-bottom: 3px solid !important;
}

.vads-u-border-left--3px {
  border-left: 3px solid !important;
}

.vads-u-border--4px {
  border: 4px solid !important;
}

.vads-u-border-top--4px {
  border-top: 4px solid !important;
}

.vads-u-border-right--4px {
  border-right: 4px solid !important;
}

.vads-u-border-bottom--4px {
  border-bottom: 4px solid !important;
}

.vads-u-border-left--4px {
  border-left: 4px solid !important;
}

.vads-u-border--5px {
  border: 5px solid !important;
}

.vads-u-border-top--5px {
  border-top: 5px solid !important;
}

.vads-u-border-right--5px {
  border-right: 5px solid !important;
}

.vads-u-border-bottom--5px {
  border-bottom: 5px solid !important;
}

.vads-u-border-left--5px {
  border-left: 5px solid !important;
}

.vads-u-border--7px {
  border: 7px solid !important;
}

.vads-u-border-top--7px {
  border-top: 7px solid !important;
}

.vads-u-border-right--7px {
  border-right: 7px solid !important;
}

.vads-u-border-bottom--7px {
  border-bottom: 7px solid !important;
}

.vads-u-border-left--7px {
  border-left: 7px solid !important;
}

.vads-u-border--10px {
  border: 10px solid !important;
}

.vads-u-border-top--10px {
  border-top: 10px solid !important;
}

.vads-u-border-right--10px {
  border-right: 10px solid !important;
}

.vads-u-border-bottom--10px {
  border-bottom: 10px solid !important;
}

.vads-u-border-left--10px {
  border-left: 10px solid !important;
}

.vads-u-border-style--solid {
  border-style: solid !important;
}

.vads-u-border-style--dashed {
  border-style: dashed !important;
}

.vads-u-border-style--dotted {
  border-style: dotted !important;
}

.vads-u-border-color--base {
  border-color: #1b1b1b !important;
}

.vads-u-border-color--white {
  border-color: #ffffff !important;
}

.vads-u-border-color--black {
  border-color: #000000 !important;
}

.vads-u-border-color--link-default {
  border-color: #005ea2 !important;
}

.vads-u-border-color--warning-message {
  border-color: #8a7237 !important;
}

.vads-u-border-color--primary {
  border-color: #005ea2 !important;
}

.vads-u-border-color--primary-dark {
  border-color: #1a4480 !important;
}

.vads-u-border-color--primary-darker {
  border-color: #162e51 !important;
}

.vads-u-border-color--primary-alt {
  border-color: #00bde3 !important;
}

.vads-u-border-color--primary-alt-dark {
  border-color: #28a0cb !important;
}

.vads-u-border-color--primary-alt-darkest {
  border-color: #07648d !important;
}

.vads-u-border-color--primary-alt-light {
  border-color: #97d4ea !important;
}

.vads-u-border-color--primary-alt-lightest {
  border-color: #e1f3f8 !important;
}

.vads-u-border-color--secondary {
  border-color: #d83933 !important;
}

.vads-u-border-color--secondary-dark {
  border-color: #b50909 !important;
}

.vads-u-border-color--secondary-darkest {
  border-color: #8b0a03 !important;
}

.vads-u-border-color--secondary-light {
  border-color: #f2938c !important;
}

.vads-u-border-color--secondary-lightest {
  border-color: #f8dfe2 !important;
}

.vads-u-border-color--gray {
  border-color: #565c65 !important;
}

.vads-u-border-color--gray-dark {
  border-color: #3d4551 !important;
}

.vads-u-border-color--gray-medium {
  border-color: #757575 !important;
}

.vads-u-border-color--gray-light {
  border-color: #a9aeb1 !important;
}

.vads-u-border-color--gray-light-alt {
  border-color: #edeff0 !important;
}

.vads-u-border-color--gray-lighter {
  border-color: #dfe1e2 !important;
}

.vads-u-border-color--gray-lightest {
  border-color: #f0f0f0 !important;
}

.vads-u-border-color--gray-warm-dark {
  border-color: #454540 !important;
}

.vads-u-border-color--gray-warm-light {
  border-color: #e6e6e2 !important;
}

.vads-u-border-color--gray-cool-light {
  border-color: #e1e7f1 !important;
}

.vads-u-border-color--gold {
  border-color: #ffbe2e !important;
}

.vads-u-border-color--gold-darker {
  border-color: #936f38 !important;
}

.vads-u-border-color--gold-light {
  border-color: #face00 !important;
}

.vads-u-border-color--gold-lighter {
  border-color: #ffe396 !important;
}

.vads-u-border-color--gold-lightest {
  border-color: #fef0c8 !important;
}

.vads-u-border-color--green {
  border-color: #008817 !important;
}

.vads-u-border-color--green-darker {
  border-color: #154c21 !important;
}

.vads-u-border-color--green-light {
  border-color: #5e9f69 !important;
}

.vads-u-border-color--green-lighter {
  border-color: #b4d0b9 !important;
}

.vads-u-border-color--green-lightest {
  border-color: #ecf3ec !important;
}

.vads-u-border-color--cool-blue {
  border-color: #345d96 !important;
}

.vads-u-border-color--cool-blue-light {
  border-color: #4a77b4 !important;
}

.vads-u-border-color--cool-blue-lightest {
  border-color: #e6e6e2 !important;
}

.vads-u-border-color--hub-health-care {
  border-color: #3f57a6 !important;
}

.vads-u-border-color--hub-education {
  border-color: #162e51 !important;
}

.vads-u-border-color--hub-disability {
  border-color: #a23737 !important;
}

.vads-u-border-color--hub-careers {
  border-color: #bd5727 !important;
}

.vads-u-border-color--hub-pension {
  border-color: #4d8055 !important;
}

.vads-u-border-color--hub-housing {
  border-color: #8e704f !important;
}

.vads-u-border-color--hub-life-insurance {
  border-color: #6f7a41 !important;
}

.vads-u-border-color--hub-burials {
  border-color: #2378c3 !important;
}

.vads-u-border-color--hub-family-member {
  border-color: #162e51 !important;
}

.vads-u-border-color--hub-service-member {
  border-color: #162e51 !important;
}

.vads-u-border-color--hub-records {
  border-color: #6f7a41 !important;
}

.vads-u-border-color--action-border-base-active-on-dark {
  border-color: #adadad !important;
}

.vads-u-border-color--action-border-base-active-on-light {
  border-color: #2e2e2e !important;
}

.vads-u-border-color--action-focus-on-light {
  border-color: #face00 !important;
}

.vads-u-border-color--action-surface-default-on-dark {
  border-color: #58b4ff !important;
}

.vads-u-border-color--action-surface-destructive-on-dark {
  border-color: #fb5a47 !important;
}

.vads-u-border-color--background-default-on-dark {
  border-color: #171716 !important;
}

.vads-u-border-color--base-dark {
  border-color: #565c65 !important;
}

.vads-u-border-color--base-darker {
  border-color: #3d4551 !important;
}

.vads-u-border-color--base-darkest {
  border-color: #1b1b1b !important;
}

.vads-u-border-color--base-light {
  border-color: #a9aeb1 !important;
}

.vads-u-border-color--base-lighter {
  border-color: #dfe1e2 !important;
}

.vads-u-border-color--base-lightest {
  border-color: #f0f0f0 !important;
}

.vads-u-border-color--emergency {
  border-color: #9c3d10 !important;
}

.vads-u-border-color--emergency-dark {
  border-color: #332d29 !important;
}

.vads-u-border-color--error {
  border-color: #d54309 !important;
}

.vads-u-border-color--error-dark {
  border-color: #b21d38 !important;
}

.vads-u-border-color--error-darker {
  border-color: #6f3331 !important;
}

.vads-u-border-color--error-light {
  border-color: #f39268 !important;
}

.vads-u-border-color--error-lighter {
  border-color: #f4e3db !important;
}

.vads-u-border-color--feedback-border-warning-on-dark {
  border-color: #face00 !important;
}

.vads-u-border-color--feedback-surface-error-on-dark {
  border-color: #5c1111 !important;
}

.vads-u-border-color--feedback-surface-info-on-dark {
  border-color: #112f4e !important;
}

.vads-u-border-color--feedback-surface-success-on-dark {
  border-color: #19311e !important;
}

.vads-u-border-color--feedback-surface-warning-on-dark {
  border-color: #422d19 !important;
}

.vads-u-border-color--feedback-forms-active-on-dark {
  border-color: #252f3e !important;
}

.vads-u-border-color--feedback-forms-active-on-light {
  border-color: #ecf1f7 !important;
}

.vads-u-border-color--gibill-accent {
  border-color: #fef0c8 !important;
}

.vads-u-border-color--info {
  border-color: #00bde3 !important;
}

.vads-u-border-color--info-dark {
  border-color: #009ec1 !important;
}

.vads-u-border-color--info-darker {
  border-color: #2e6276 !important;
}

.vads-u-border-color--info-light {
  border-color: #99deea !important;
}

.vads-u-border-color--info-lighter {
  border-color: #e7f6f8 !important;
}

.vads-u-border-color--ink {
  border-color: #1b1b1b !important;
}

.vads-u-border-color--inset-bg {
  border-color: #e7f6f8 !important;
}

.vads-u-border-color--link {
  border-color: #005ea2 !important;
}

.vads-u-border-color--link-active {
  border-color: #0b4778 !important;
}

.vads-u-border-color--link-visited {
  border-color: #54278f !important;
}

.vads-u-border-color--success {
  border-color: #00a91c !important;
}

.vads-u-border-color--success-dark {
  border-color: #008817 !important;
}

.vads-u-border-color--success-darker {
  border-color: #216e1f !important;
}

.vads-u-border-color--success-light {
  border-color: #70e17b !important;
}

.vads-u-border-color--success-lighter {
  border-color: #ecf3ec !important;
}

.vads-u-border-color--va-accent {
  border-color: #8a7237 !important;
}

.vads-u-border-color--warning {
  border-color: #ffbe2e !important;
}

.vads-u-border-color--warning-dark {
  border-color: #e5a000 !important;
}

.vads-u-border-color--warning-darker {
  border-color: #936f38 !important;
}

.vads-u-border-color--warning-light {
  border-color: #fee685 !important;
}

.vads-u-border-color--warning-lighter {
  border-color: #faf3d1 !important;
}

.vads-u-color--base {
  color: #1b1b1b !important;
}

.vads-u-color--white {
  color: #ffffff !important;
}

.vads-u-color--black {
  color: #000000 !important;
}

.vads-u-color--link-default {
  color: #005ea2 !important;
}

.vads-u-color--warning-message {
  color: #8a7237 !important;
}

.vads-u-color--primary {
  color: #005ea2 !important;
}

.vads-u-color--primary-dark {
  color: #1a4480 !important;
}

.vads-u-color--primary-darker {
  color: #162e51 !important;
}

.vads-u-color--primary-alt {
  color: #00bde3 !important;
}

.vads-u-color--primary-alt-dark {
  color: #28a0cb !important;
}

.vads-u-color--primary-alt-darkest {
  color: #07648d !important;
}

.vads-u-color--primary-alt-light {
  color: #97d4ea !important;
}

.vads-u-color--primary-alt-lightest {
  color: #e1f3f8 !important;
}

.vads-u-color--secondary {
  color: #d83933 !important;
}

.vads-u-color--secondary-dark {
  color: #b50909 !important;
}

.vads-u-color--secondary-darkest {
  color: #8b0a03 !important;
}

.vads-u-color--secondary-light {
  color: #f2938c !important;
}

.vads-u-color--secondary-lightest {
  color: #f8dfe2 !important;
}

.vads-u-color--gray {
  color: #565c65 !important;
}

.vads-u-color--gray-dark {
  color: #3d4551 !important;
}

.vads-u-color--gray-medium {
  color: #757575 !important;
}

.vads-u-color--gray-light {
  color: #a9aeb1 !important;
}

.vads-u-color--gray-light-alt {
  color: #edeff0 !important;
}

.vads-u-color--gray-lighter {
  color: #dfe1e2 !important;
}

.vads-u-color--gray-lightest {
  color: #f0f0f0 !important;
}

.vads-u-color--gray-warm-dark {
  color: #454540 !important;
}

.vads-u-color--gray-warm-light {
  color: #e6e6e2 !important;
}

.vads-u-color--gray-cool-light {
  color: #e1e7f1 !important;
}

.vads-u-color--gold {
  color: #ffbe2e !important;
}

.vads-u-color--gold-darker {
  color: #936f38 !important;
}

.vads-u-color--gold-light {
  color: #face00 !important;
}

.vads-u-color--gold-lighter {
  color: #ffe396 !important;
}

.vads-u-color--gold-lightest {
  color: #fef0c8 !important;
}

.vads-u-color--green {
  color: #008817 !important;
}

.vads-u-color--green-darker {
  color: #154c21 !important;
}

.vads-u-color--green-light {
  color: #5e9f69 !important;
}

.vads-u-color--green-lighter {
  color: #b4d0b9 !important;
}

.vads-u-color--green-lightest {
  color: #ecf3ec !important;
}

.vads-u-color--cool-blue {
  color: #345d96 !important;
}

.vads-u-color--cool-blue-light {
  color: #4a77b4 !important;
}

.vads-u-color--cool-blue-lightest {
  color: #e6e6e2 !important;
}

.vads-u-color--hub-health-care {
  color: #3f57a6 !important;
}

.vads-u-color--hub-education {
  color: #162e51 !important;
}

.vads-u-color--hub-disability {
  color: #a23737 !important;
}

.vads-u-color--hub-careers {
  color: #bd5727 !important;
}

.vads-u-color--hub-pension {
  color: #4d8055 !important;
}

.vads-u-color--hub-housing {
  color: #8e704f !important;
}

.vads-u-color--hub-life-insurance {
  color: #6f7a41 !important;
}

.vads-u-color--hub-burials {
  color: #2378c3 !important;
}

.vads-u-color--hub-family-member {
  color: #162e51 !important;
}

.vads-u-color--hub-service-member {
  color: #162e51 !important;
}

.vads-u-color--hub-records {
  color: #6f7a41 !important;
}

.vads-u-color--action-border-base-active-on-dark {
  color: #adadad !important;
}

.vads-u-color--action-border-base-active-on-light {
  color: #2e2e2e !important;
}

.vads-u-color--action-focus-on-light {
  color: #face00 !important;
}

.vads-u-color--action-surface-default-on-dark {
  color: #58b4ff !important;
}

.vads-u-color--action-surface-destructive-on-dark {
  color: #fb5a47 !important;
}

.vads-u-color--background-default-on-dark {
  color: #171716 !important;
}

.vads-u-color--base-dark {
  color: #565c65 !important;
}

.vads-u-color--base-darker {
  color: #3d4551 !important;
}

.vads-u-color--base-darkest {
  color: #1b1b1b !important;
}

.vads-u-color--base-light {
  color: #a9aeb1 !important;
}

.vads-u-color--base-lighter {
  color: #dfe1e2 !important;
}

.vads-u-color--base-lightest {
  color: #f0f0f0 !important;
}

.vads-u-color--emergency {
  color: #9c3d10 !important;
}

.vads-u-color--emergency-dark {
  color: #332d29 !important;
}

.vads-u-color--error {
  color: #d54309 !important;
}

.vads-u-color--error-dark {
  color: #b21d38 !important;
}

.vads-u-color--error-darker {
  color: #6f3331 !important;
}

.vads-u-color--error-light {
  color: #f39268 !important;
}

.vads-u-color--error-lighter {
  color: #f4e3db !important;
}

.vads-u-color--feedback-border-warning-on-dark {
  color: #face00 !important;
}

.vads-u-color--feedback-surface-error-on-dark {
  color: #5c1111 !important;
}

.vads-u-color--feedback-surface-info-on-dark {
  color: #112f4e !important;
}

.vads-u-color--feedback-surface-success-on-dark {
  color: #19311e !important;
}

.vads-u-color--feedback-surface-warning-on-dark {
  color: #422d19 !important;
}

.vads-u-color--feedback-forms-active-on-dark {
  color: #252f3e !important;
}

.vads-u-color--feedback-forms-active-on-light {
  color: #ecf1f7 !important;
}

.vads-u-color--gibill-accent {
  color: #fef0c8 !important;
}

.vads-u-color--info {
  color: #00bde3 !important;
}

.vads-u-color--info-dark {
  color: #009ec1 !important;
}

.vads-u-color--info-darker {
  color: #2e6276 !important;
}

.vads-u-color--info-light {
  color: #99deea !important;
}

.vads-u-color--info-lighter {
  color: #e7f6f8 !important;
}

.vads-u-color--ink {
  color: #1b1b1b !important;
}

.vads-u-color--inset-bg {
  color: #e7f6f8 !important;
}

.vads-u-color--link {
  color: #005ea2 !important;
}

.vads-u-color--link-active {
  color: #0b4778 !important;
}

.vads-u-color--link-visited {
  color: #54278f !important;
}

.vads-u-color--success {
  color: #00a91c !important;
}

.vads-u-color--success-dark {
  color: #008817 !important;
}

.vads-u-color--success-darker {
  color: #216e1f !important;
}

.vads-u-color--success-light {
  color: #70e17b !important;
}

.vads-u-color--success-lighter {
  color: #ecf3ec !important;
}

.vads-u-color--va-accent {
  color: #8a7237 !important;
}

.vads-u-color--warning {
  color: #ffbe2e !important;
}

.vads-u-color--warning-dark {
  color: #e5a000 !important;
}

.vads-u-color--warning-darker {
  color: #936f38 !important;
}

.vads-u-color--warning-light {
  color: #fee685 !important;
}

.vads-u-color--warning-lighter {
  color: #faf3d1 !important;
}

.vads-u-display--block {
  display: block !important;
}

.vads-u-display--inline {
  display: inline !important;
}

.vads-u-display--inline-block {
  display: inline-block !important;
}

.vads-u-display--flex {
  display: flex !important;
}

.vads-u-display--none {
  display: none !important;
}

.vads-u-flex--1 {
  flex: 1 0 0% !important;
}

.vads-u-flex--2 {
  flex: 2 0 0% !important;
}

.vads-u-flex--3 {
  flex: 3 0 0% !important;
}

.vads-u-flex--4 {
  flex: 4 0 0% !important;
}

.vads-u-flex--auto {
  flex: 0 0 auto !important;
}

.vads-u-flex--fill {
  flex: 1 0 0% !important;
}

.vads-u-flex-direction--column {
  flex-direction: column !important;
}

.vads-u-flex-direction--row {
  flex-direction: row !important;
}

.vads-u-flex-direction--column-reverse {
  flex-direction: column-reverse !important;
}

.vads-u-flex-direction--row-reverse {
  flex-direction: row-reverse !important;
}

.vads-u-flex-wrap--nowrap {
  flex-wrap: nowrap !important;
}

.vads-u-flex-wrap--wrap {
  flex-wrap: wrap !important;
}

.vads-u-flex-wrap--wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.vads-u-align-items--stretch {
  align-items: stretch !important;
}

.vads-u-align-items--flex-start {
  align-items: flex-start !important;
}

.vads-u-align-items--flex-end {
  align-items: flex-end !important;
}

.vads-u-align-items--center {
  align-items: center !important;
}

.vads-u-align-items--baseline {
  align-items: baseline !important;
}

.vads-u-justify-content--flex-start {
  justify-content: flex-start !important;
}

.vads-u-justify-content--flex-end {
  justify-content: flex-end !important;
}

.vads-u-justify-content--center {
  justify-content: center !important;
}

.vads-u-justify-content--space-between {
  justify-content: space-between !important;
}

.vads-u-justify-content--space-around {
  justify-content: space-around !important;
}

.vads-u-justify-content--space-evenly {
  justify-content: space-evenly !important;
}

.vads-u-align-content--flex-start {
  align-content: flex-start !important;
}

.vads-u-align-content--flex-end {
  align-content: flex-end !important;
}

.vads-u-align-content--center {
  align-content: center !important;
}

.vads-u-align-content--space-between {
  align-content: space-between !important;
}

.vads-u-align-content--space-around {
  align-content: space-around !important;
}

.vads-u-align-content--stretch {
  align-content: stretch !important;
}

.vads-u-align-self--auto {
  align-self: auto !important;
}

.vads-u-align-self--flex-start {
  align-self: flex-start !important;
}

.vads-u-align-self--flex-end {
  align-self: flex-end !important;
}

.vads-u-align-self--center {
  align-self: center !important;
}

.vads-u-align-self--baseline {
  align-self: baseline !important;
}

.vads-u-align-self--stretch {
  align-self: stretch !important;
}

.vads-u-order--1 {
  order: 1 !important;
}

.vads-u-order--2 {
  order: 2 !important;
}

.vads-u-order--3 {
  order: 3 !important;
}

.vads-u-order--4 {
  order: 4 !important;
}

.vads-u-order--first {
  order: -1 !important;
}

.vads-u-order--last {
  order: 999 !important;
}

.vads-u-order--initial {
  order: 0 !important;
}

.vads-u-font-family--sans {
  font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif !important;
}

.vads-u-font-family--serif {
  font-family: Bitter, Georgia, Cambria, Times New Roman, Times, serif !important;
}

.vads-u-font-size--source-sans-normalized {
  font-size: 1.063rem !important;
}

.vads-u-font-size--sm {
  font-size: 0.938rem !important;
}

.vads-u-font-size--base {
  font-size: 1rem !important;
}

.vads-u-font-size--md {
  font-size: 1.063rem !important;
}

.vads-u-font-size--lg {
  font-size: 1.25rem !important;
}

.vads-u-font-size--xl {
  font-size: 1.875rem !important;
}

.vads-u-font-size--2xl {
  font-size: 2.5rem !important;
}

.vads-u-font-size--h1 {
  font-size: 2.5rem !important;
}

.vads-u-font-size--h2 {
  font-size: 1.875rem !important;
}

.vads-u-font-size--h3 {
  font-size: 1.25rem !important;
}

.vads-u-font-size--h4 {
  font-size: 1.063rem !important;
}

.vads-u-font-size--h5 {
  font-size: 0.938rem !important;
}

.vads-u-font-size--h6 {
  font-size: 0.938rem !important;
}

.vads-u-font-style--italic {
  font-style: italic !important;
}

.vads-u-font-style--normal {
  font-style: normal !important;
}

.vads-u-font-weight--bold {
  font-weight: 700 !important;
}

.vads-u-font-weight--normal {
  font-weight: 400 !important;
}

.vads-u-height--0 {
  height: 0px !important;
}

.vads-u-height--full {
  height: 100% !important;
}

.vads-u-height--auto {
  height: auto !important;
}

.vads-u-height--viewport {
  height: 100vh !important;
}

.vads-u-min-height--none {
  min-height: none !important;
}

.vads-u-min-height--viewport {
  min-height: 100vh !important;
}

.vads-u-max-height--none {
  max-height: none !important;
}

.vads-u-max-height--viewport {
  max-height: 100vh !important;
}

.vads-u-line-height--1 {
  line-height: 1 !important;
}

.vads-u-line-height--2 {
  line-height: 1.15 !important;
}

.vads-u-line-height--3 {
  line-height: 1.35 !important;
}

.vads-u-line-height--4 {
  line-height: 1.5 !important;
}

.vads-u-line-height--5 {
  line-height: 1.62 !important;
}

.vads-u-line-height--6 {
  line-height: 1.75 !important;
}

.vads-u-margin--0 {
  margin: 0 !important;
}

.vads-u-margin--1px {
  margin: 1px !important;
}

.vads-u-margin--0p25 {
  margin: 2px !important;
}

.vads-u-margin--0p5 {
  margin: 0.25rem !important;
}

.vads-u-margin--1 {
  margin: 0.5rem !important;
}

.vads-u-margin--1p5 {
  margin: 0.75rem !important;
}

.vads-u-margin--2 {
  margin: 1rem !important;
}

.vads-u-margin--2p5 {
  margin: 1.25rem !important;
}

.vads-u-margin--3 {
  margin: 1.5rem !important;
}

.vads-u-margin--4 {
  margin: 2rem !important;
}

.vads-u-margin--5 {
  margin: 2.5rem !important;
}

.vads-u-margin--6 {
  margin: 3rem !important;
}

.vads-u-margin--7 {
  margin: 3.5rem !important;
}

.vads-u-margin--8 {
  margin: 4rem !important;
}

.vads-u-margin--9 {
  margin: 4.5rem !important;
}

.vads-u-margin-y--0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.vads-u-margin-top--0 {
  margin-top: 0 !important;
}

.vads-u-margin-bottom--0 {
  margin-bottom: 0 !important;
}

.vads-u-margin-y--1px {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.vads-u-margin-top--1px {
  margin-top: 1px !important;
}

.vads-u-margin-bottom--1px {
  margin-bottom: 1px !important;
}

.vads-u-margin-y--0p25 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.vads-u-margin-top--0p25 {
  margin-top: 2px !important;
}

.vads-u-margin-bottom--0p25 {
  margin-bottom: 2px !important;
}

.vads-u-margin-y--0p5 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.vads-u-margin-top--0p5 {
  margin-top: 0.25rem !important;
}

.vads-u-margin-bottom--0p5 {
  margin-bottom: 0.25rem !important;
}

.vads-u-margin-y--1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.vads-u-margin-top--1 {
  margin-top: 0.5rem !important;
}

.vads-u-margin-bottom--1 {
  margin-bottom: 0.5rem !important;
}

.vads-u-margin-y--1p5 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.vads-u-margin-top--1p5 {
  margin-top: 0.75rem !important;
}

.vads-u-margin-bottom--1p5 {
  margin-bottom: 0.75rem !important;
}

.vads-u-margin-y--2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.vads-u-margin-top--2 {
  margin-top: 1rem !important;
}

.vads-u-margin-bottom--2 {
  margin-bottom: 1rem !important;
}

.vads-u-margin-y--2p5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.vads-u-margin-top--2p5 {
  margin-top: 1.25rem !important;
}

.vads-u-margin-bottom--2p5 {
  margin-bottom: 1.25rem !important;
}

.vads-u-margin-y--3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.vads-u-margin-top--3 {
  margin-top: 1.5rem !important;
}

.vads-u-margin-bottom--3 {
  margin-bottom: 1.5rem !important;
}

.vads-u-margin-y--4 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.vads-u-margin-top--4 {
  margin-top: 2rem !important;
}

.vads-u-margin-bottom--4 {
  margin-bottom: 2rem !important;
}

.vads-u-margin-y--5 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.vads-u-margin-top--5 {
  margin-top: 2.5rem !important;
}

.vads-u-margin-bottom--5 {
  margin-bottom: 2.5rem !important;
}

.vads-u-margin-y--6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.vads-u-margin-top--6 {
  margin-top: 3rem !important;
}

.vads-u-margin-bottom--6 {
  margin-bottom: 3rem !important;
}

.vads-u-margin-y--7 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.vads-u-margin-top--7 {
  margin-top: 3.5rem !important;
}

.vads-u-margin-bottom--7 {
  margin-bottom: 3.5rem !important;
}

.vads-u-margin-y--8 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.vads-u-margin-top--8 {
  margin-top: 4rem !important;
}

.vads-u-margin-bottom--8 {
  margin-bottom: 4rem !important;
}

.vads-u-margin-y--9 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.vads-u-margin-top--9 {
  margin-top: 4.5rem !important;
}

.vads-u-margin-bottom--9 {
  margin-bottom: 4.5rem !important;
}

.vads-u-margin-y--neg9 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.vads-u-margin-top--neg9 {
  margin-top: -4.5rem !important;
}

.vads-u-margin-bottom--neg9 {
  margin-bottom: -4.5rem !important;
}

.vads-u-margin-y--neg8 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.vads-u-margin-top--neg8 {
  margin-top: -4rem !important;
}

.vads-u-margin-bottom--neg8 {
  margin-bottom: -4rem !important;
}

.vads-u-margin-y--neg7 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.vads-u-margin-top--neg7 {
  margin-top: -3.5rem !important;
}

.vads-u-margin-bottom--neg7 {
  margin-bottom: -3.5rem !important;
}

.vads-u-margin-y--neg6 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.vads-u-margin-top--neg6 {
  margin-top: -3rem !important;
}

.vads-u-margin-bottom--neg6 {
  margin-bottom: -3rem !important;
}

.vads-u-margin-y--neg5 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.vads-u-margin-top--neg5 {
  margin-top: -2.5rem !important;
}

.vads-u-margin-bottom--neg5 {
  margin-bottom: -2.5rem !important;
}

.vads-u-margin-y--neg4 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.vads-u-margin-top--neg4 {
  margin-top: -2rem !important;
}

.vads-u-margin-bottom--neg4 {
  margin-bottom: -2rem !important;
}

.vads-u-margin-y--neg3 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.vads-u-margin-top--neg3 {
  margin-top: -1.5rem !important;
}

.vads-u-margin-bottom--neg3 {
  margin-bottom: -1.5rem !important;
}

.vads-u-margin-y--neg2p5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.vads-u-margin-top--neg2p5 {
  margin-top: -1.25rem !important;
}

.vads-u-margin-bottom--neg2p5 {
  margin-bottom: -1.25rem !important;
}

.vads-u-margin-y--neg2 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.vads-u-margin-top--neg2 {
  margin-top: -1rem !important;
}

.vads-u-margin-bottom--neg2 {
  margin-bottom: -1rem !important;
}

.vads-u-margin-y--neg1p5 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.vads-u-margin-top--neg1p5 {
  margin-top: -0.75rem !important;
}

.vads-u-margin-bottom--neg1p5 {
  margin-bottom: -0.75rem !important;
}

.vads-u-margin-y--neg1 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.vads-u-margin-top--neg1 {
  margin-top: -0.5rem !important;
}

.vads-u-margin-bottom--neg1 {
  margin-bottom: -0.5rem !important;
}

.vads-u-margin-y--neg0p5 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.vads-u-margin-top--neg0p5 {
  margin-top: -0.25rem !important;
}

.vads-u-margin-bottom--neg0p5 {
  margin-bottom: -0.25rem !important;
}

.vads-u-margin-y--neg0p25 {
  margin-top: -2px !important;
  margin-bottom: -2px !important;
}

.vads-u-margin-top--neg0p25 {
  margin-top: -2px !important;
}

.vads-u-margin-bottom--neg0p25 {
  margin-bottom: -2px !important;
}

.vads-u-margin-y--neg1px {
  margin-top: -1px !important;
  margin-bottom: -1px !important;
}

.vads-u-margin-top--neg1px {
  margin-top: -1px !important;
}

.vads-u-margin-bottom--neg1px {
  margin-bottom: -1px !important;
}

.vads-u-margin-x--0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.vads-u-margin-right--0 {
  margin-right: 0 !important;
}

.vads-u-margin-left--0 {
  margin-left: 0 !important;
}

.vads-u-margin-x--1px {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.vads-u-margin-right--1px {
  margin-right: 1px !important;
}

.vads-u-margin-left--1px {
  margin-left: 1px !important;
}

.vads-u-margin-x--0p25 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.vads-u-margin-right--0p25 {
  margin-right: 2px !important;
}

.vads-u-margin-left--0p25 {
  margin-left: 2px !important;
}

.vads-u-margin-x--0p5 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.vads-u-margin-right--0p5 {
  margin-right: 0.25rem !important;
}

.vads-u-margin-left--0p5 {
  margin-left: 0.25rem !important;
}

.vads-u-margin-x--1 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.vads-u-margin-right--1 {
  margin-right: 0.5rem !important;
}

.vads-u-margin-left--1 {
  margin-left: 0.5rem !important;
}

.vads-u-margin-x--1p5 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}

.vads-u-margin-right--1p5 {
  margin-right: 0.75rem !important;
}

.vads-u-margin-left--1p5 {
  margin-left: 0.75rem !important;
}

.vads-u-margin-x--2 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.vads-u-margin-right--2 {
  margin-right: 1rem !important;
}

.vads-u-margin-left--2 {
  margin-left: 1rem !important;
}

.vads-u-margin-x--2p5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.vads-u-margin-right--2p5 {
  margin-right: 1.25rem !important;
}

.vads-u-margin-left--2p5 {
  margin-left: 1.25rem !important;
}

.vads-u-margin-x--3 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.vads-u-margin-right--3 {
  margin-right: 1.5rem !important;
}

.vads-u-margin-left--3 {
  margin-left: 1.5rem !important;
}

.vads-u-margin-x--4 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.vads-u-margin-right--4 {
  margin-right: 2rem !important;
}

.vads-u-margin-left--4 {
  margin-left: 2rem !important;
}

.vads-u-margin-x--5 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.vads-u-margin-right--5 {
  margin-right: 2.5rem !important;
}

.vads-u-margin-left--5 {
  margin-left: 2.5rem !important;
}

.vads-u-margin-x--6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.vads-u-margin-right--6 {
  margin-right: 3rem !important;
}

.vads-u-margin-left--6 {
  margin-left: 3rem !important;
}

.vads-u-margin-x--7 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

.vads-u-margin-right--7 {
  margin-right: 3.5rem !important;
}

.vads-u-margin-left--7 {
  margin-left: 3.5rem !important;
}

.vads-u-margin-x--8 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.vads-u-margin-right--8 {
  margin-right: 4rem !important;
}

.vads-u-margin-left--8 {
  margin-left: 4rem !important;
}

.vads-u-margin-x--9 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}

.vads-u-margin-right--9 {
  margin-right: 4.5rem !important;
}

.vads-u-margin-left--9 {
  margin-left: 4.5rem !important;
}

.vads-u-margin-x--neg9 {
  margin-left: -4.5rem !important;
  margin-right: -4.5rem !important;
}

.vads-u-margin-right--neg9 {
  margin-right: -4.5rem !important;
}

.vads-u-margin-left--neg9 {
  margin-left: -4.5rem !important;
}

.vads-u-margin-x--neg8 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.vads-u-margin-right--neg8 {
  margin-right: -4rem !important;
}

.vads-u-margin-left--neg8 {
  margin-left: -4rem !important;
}

.vads-u-margin-x--neg7 {
  margin-left: -3.5rem !important;
  margin-right: -3.5rem !important;
}

.vads-u-margin-right--neg7 {
  margin-right: -3.5rem !important;
}

.vads-u-margin-left--neg7 {
  margin-left: -3.5rem !important;
}

.vads-u-margin-x--neg6 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.vads-u-margin-right--neg6 {
  margin-right: -3rem !important;
}

.vads-u-margin-left--neg6 {
  margin-left: -3rem !important;
}

.vads-u-margin-x--neg5 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.vads-u-margin-right--neg5 {
  margin-right: -2.5rem !important;
}

.vads-u-margin-left--neg5 {
  margin-left: -2.5rem !important;
}

.vads-u-margin-x--neg4 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.vads-u-margin-right--neg4 {
  margin-right: -2rem !important;
}

.vads-u-margin-left--neg4 {
  margin-left: -2rem !important;
}

.vads-u-margin-x--neg3 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.vads-u-margin-right--neg3 {
  margin-right: -1.5rem !important;
}

.vads-u-margin-left--neg3 {
  margin-left: -1.5rem !important;
}

.vads-u-margin-x--neg2p5 {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}

.vads-u-margin-right--neg2p5 {
  margin-right: -1.25rem !important;
}

.vads-u-margin-left--neg2p5 {
  margin-left: -1.25rem !important;
}

.vads-u-margin-x--neg2 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.vads-u-margin-right--neg2 {
  margin-right: -1rem !important;
}

.vads-u-margin-left--neg2 {
  margin-left: -1rem !important;
}

.vads-u-margin-x--neg1p5 {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}

.vads-u-margin-right--neg1p5 {
  margin-right: -0.75rem !important;
}

.vads-u-margin-left--neg1p5 {
  margin-left: -0.75rem !important;
}

.vads-u-margin-x--neg1 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.vads-u-margin-right--neg1 {
  margin-right: -0.5rem !important;
}

.vads-u-margin-left--neg1 {
  margin-left: -0.5rem !important;
}

.vads-u-margin-x--neg0p5 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.vads-u-margin-right--neg0p5 {
  margin-right: -0.25rem !important;
}

.vads-u-margin-left--neg0p5 {
  margin-left: -0.25rem !important;
}

.vads-u-margin-x--neg0p25 {
  margin-left: -2px !important;
  margin-right: -2px !important;
}

.vads-u-margin-right--neg0p25 {
  margin-right: -2px !important;
}

.vads-u-margin-left--neg0p25 {
  margin-left: -2px !important;
}

.vads-u-margin-x--neg1px {
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.vads-u-margin-right--neg1px {
  margin-right: -1px !important;
}

.vads-u-margin-left--neg1px {
  margin-left: -1px !important;
}

.vads-u-margin-x--auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.vads-u-margin-right--auto {
  margin-right: auto !important;
}

.vads-u-margin-left--auto {
  margin-left: auto !important;
}

.vads-u-measure--1 {
  max-width: 40ch !important;
}

.vads-u-measure--2 {
  max-width: 60ch !important;
}

.vads-u-measure--3 {
  max-width: 66ch !important;
}

.vads-u-measure--4 {
  max-width: 72ch !important;
}

.vads-u-measure--5 {
  max-width: 77ch !important;
}

.vads-u-measure--none {
  max-width: none !important;
}

.vads-u-padding--0 {
  padding: 0 !important;
}

.vads-u-padding-top--0 {
  padding-top: 0 !important;
}

.vads-u-padding-bottom--0 {
  padding-bottom: 0 !important;
}

.vads-u-padding-right--0 {
  padding-right: 0 !important;
}

.vads-u-padding-left--0 {
  padding-left: 0 !important;
}

.vads-u-padding--1px {
  padding: 1px !important;
}

.vads-u-padding-top--1px {
  padding-top: 1px !important;
}

.vads-u-padding-bottom--1px {
  padding-bottom: 1px !important;
}

.vads-u-padding-right--1px {
  padding-right: 1px !important;
}

.vads-u-padding-left--1px {
  padding-left: 1px !important;
}

.vads-u-padding--0p25 {
  padding: 2px !important;
}

.vads-u-padding-top--0p25 {
  padding-top: 2px !important;
}

.vads-u-padding-bottom--0p25 {
  padding-bottom: 2px !important;
}

.vads-u-padding-right--0p25 {
  padding-right: 2px !important;
}

.vads-u-padding-left--0p25 {
  padding-left: 2px !important;
}

.vads-u-padding--0p5 {
  padding: 0.25rem !important;
}

.vads-u-padding-top--0p5 {
  padding-top: 0.25rem !important;
}

.vads-u-padding-bottom--0p5 {
  padding-bottom: 0.25rem !important;
}

.vads-u-padding-right--0p5 {
  padding-right: 0.25rem !important;
}

.vads-u-padding-left--0p5 {
  padding-left: 0.25rem !important;
}

.vads-u-padding--1 {
  padding: 0.5rem !important;
}

.vads-u-padding-top--1 {
  padding-top: 0.5rem !important;
}

.vads-u-padding-bottom--1 {
  padding-bottom: 0.5rem !important;
}

.vads-u-padding-right--1 {
  padding-right: 0.5rem !important;
}

.vads-u-padding-left--1 {
  padding-left: 0.5rem !important;
}

.vads-u-padding--1p5 {
  padding: 0.75rem !important;
}

.vads-u-padding-top--1p5 {
  padding-top: 0.75rem !important;
}

.vads-u-padding-bottom--1p5 {
  padding-bottom: 0.75rem !important;
}

.vads-u-padding-right--1p5 {
  padding-right: 0.75rem !important;
}

.vads-u-padding-left--1p5 {
  padding-left: 0.75rem !important;
}

.vads-u-padding--2 {
  padding: 1rem !important;
}

.vads-u-padding-top--2 {
  padding-top: 1rem !important;
}

.vads-u-padding-bottom--2 {
  padding-bottom: 1rem !important;
}

.vads-u-padding-right--2 {
  padding-right: 1rem !important;
}

.vads-u-padding-left--2 {
  padding-left: 1rem !important;
}

.vads-u-padding--2p5 {
  padding: 1.25rem !important;
}

.vads-u-padding-top--2p5 {
  padding-top: 1.25rem !important;
}

.vads-u-padding-bottom--2p5 {
  padding-bottom: 1.25rem !important;
}

.vads-u-padding-right--2p5 {
  padding-right: 1.25rem !important;
}

.vads-u-padding-left--2p5 {
  padding-left: 1.25rem !important;
}

.vads-u-padding--3 {
  padding: 1.5rem !important;
}

.vads-u-padding-top--3 {
  padding-top: 1.5rem !important;
}

.vads-u-padding-bottom--3 {
  padding-bottom: 1.5rem !important;
}

.vads-u-padding-right--3 {
  padding-right: 1.5rem !important;
}

.vads-u-padding-left--3 {
  padding-left: 1.5rem !important;
}

.vads-u-padding--4 {
  padding: 2rem !important;
}

.vads-u-padding-top--4 {
  padding-top: 2rem !important;
}

.vads-u-padding-bottom--4 {
  padding-bottom: 2rem !important;
}

.vads-u-padding-right--4 {
  padding-right: 2rem !important;
}

.vads-u-padding-left--4 {
  padding-left: 2rem !important;
}

.vads-u-padding--5 {
  padding: 2.5rem !important;
}

.vads-u-padding-top--5 {
  padding-top: 2.5rem !important;
}

.vads-u-padding-bottom--5 {
  padding-bottom: 2.5rem !important;
}

.vads-u-padding-right--5 {
  padding-right: 2.5rem !important;
}

.vads-u-padding-left--5 {
  padding-left: 2.5rem !important;
}

.vads-u-padding--6 {
  padding: 3rem !important;
}

.vads-u-padding-top--6 {
  padding-top: 3rem !important;
}

.vads-u-padding-bottom--6 {
  padding-bottom: 3rem !important;
}

.vads-u-padding-right--6 {
  padding-right: 3rem !important;
}

.vads-u-padding-left--6 {
  padding-left: 3rem !important;
}

.vads-u-padding--7 {
  padding: 3.5rem !important;
}

.vads-u-padding-top--7 {
  padding-top: 3.5rem !important;
}

.vads-u-padding-bottom--7 {
  padding-bottom: 3.5rem !important;
}

.vads-u-padding-right--7 {
  padding-right: 3.5rem !important;
}

.vads-u-padding-left--7 {
  padding-left: 3.5rem !important;
}

.vads-u-padding--8 {
  padding: 4rem !important;
}

.vads-u-padding-top--8 {
  padding-top: 4rem !important;
}

.vads-u-padding-bottom--8 {
  padding-bottom: 4rem !important;
}

.vads-u-padding-right--8 {
  padding-right: 4rem !important;
}

.vads-u-padding-left--8 {
  padding-left: 4rem !important;
}

.vads-u-padding--9 {
  padding: 4.5rem !important;
}

.vads-u-padding-top--9 {
  padding-top: 4.5rem !important;
}

.vads-u-padding-bottom--9 {
  padding-bottom: 4.5rem !important;
}

.vads-u-padding-right--9 {
  padding-right: 4.5rem !important;
}

.vads-u-padding-left--9 {
  padding-left: 4.5rem !important;
}

.vads-u-padding-y--0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.vads-u-padding-y--1px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.vads-u-padding-y--0p25 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.vads-u-padding-y--0p5 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.vads-u-padding-y--1 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.vads-u-padding-y--1p5 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.vads-u-padding-y--2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.vads-u-padding-y--2p5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.vads-u-padding-y--3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.vads-u-padding-y--4 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.vads-u-padding-y--5 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.vads-u-padding-y--6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.vads-u-padding-y--7 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.vads-u-padding-y--8 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.vads-u-padding-y--9 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.vads-u-padding-x--0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.vads-u-padding-x--1px {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.vads-u-padding-x--0p25 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.vads-u-padding-x--0p5 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.vads-u-padding-x--1 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.vads-u-padding-x--1p5 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.vads-u-padding-x--2 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.vads-u-padding-x--2p5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.vads-u-padding-x--3 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.vads-u-padding-x--4 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.vads-u-padding-x--5 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.vads-u-padding-x--6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.vads-u-padding-x--7 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.vads-u-padding-x--8 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.vads-u-padding-x--9 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}

.vads-u-position--absolute {
  position: absolute !important;
}

.vads-u-position--fixed {
  position: fixed !important;
}

.vads-u-position--relative {
  position: relative !important;
}

.vads-u-text-align--left {
  text-align: left !important;
}

.vads-u-text-align--center {
  text-align: center !important;
}

.vads-u-text-align--right {
  text-align: right !important;
}

.vads-u-text-align--justify {
  text-align: justify !important;
}

.vads-u-text-decoration--none {
  text-decoration: none !important;
}

.vads-u-text-decoration--underline {
  text-decoration: underline !important;
}

.vads-u-text-decoration--line-through {
  text-decoration: line-through !important;
}

.vads-u-visibility--hidden {
  visibility: hidden !important;
}

.vads-u-visibility--visible {
  visibility: visible !important;
}

.vads-u-width--0 {
  width: 0px !important;
}

.vads-u-width--full {
  width: 100% !important;
}

.vads-u-width--auto {
  width: auto !important;
}

.vads-u-min-width--none {
  min-width: none !important;
}

.vads-u-max-width--none {
  max-width: none !important;
}

.vads-u-max-width--100 {
  max-width: 100% !important;
}

@media all and (min-width: 20em) {
  .mobile\:vads-u-border--0 {
    border: 0 !important;
  }
  .mobile\:vads-u-border-top--0 {
    border-top: 0 !important;
  }
  .mobile\:vads-u-border-right--0 {
    border-right: 0 !important;
  }
  .mobile\:vads-u-border-bottom--0 {
    border-bottom: 0 !important;
  }
  .mobile\:vads-u-border-left--0 {
    border-left: 0 !important;
  }
  .mobile\:vads-u-border--1px {
    border: 1px solid !important;
  }
  .mobile\:vads-u-border-top--1px {
    border-top: 1px solid !important;
  }
  .mobile\:vads-u-border-right--1px {
    border-right: 1px solid !important;
  }
  .mobile\:vads-u-border-bottom--1px {
    border-bottom: 1px solid !important;
  }
  .mobile\:vads-u-border-left--1px {
    border-left: 1px solid !important;
  }
  .mobile\:vads-u-border--2px {
    border: 2px solid !important;
  }
  .mobile\:vads-u-border-top--2px {
    border-top: 2px solid !important;
  }
  .mobile\:vads-u-border-right--2px {
    border-right: 2px solid !important;
  }
  .mobile\:vads-u-border-bottom--2px {
    border-bottom: 2px solid !important;
  }
  .mobile\:vads-u-border-left--2px {
    border-left: 2px solid !important;
  }
  .mobile\:vads-u-border--3px {
    border: 3px solid !important;
  }
  .mobile\:vads-u-border-top--3px {
    border-top: 3px solid !important;
  }
  .mobile\:vads-u-border-right--3px {
    border-right: 3px solid !important;
  }
  .mobile\:vads-u-border-bottom--3px {
    border-bottom: 3px solid !important;
  }
  .mobile\:vads-u-border-left--3px {
    border-left: 3px solid !important;
  }
  .mobile\:vads-u-border--4px {
    border: 4px solid !important;
  }
  .mobile\:vads-u-border-top--4px {
    border-top: 4px solid !important;
  }
  .mobile\:vads-u-border-right--4px {
    border-right: 4px solid !important;
  }
  .mobile\:vads-u-border-bottom--4px {
    border-bottom: 4px solid !important;
  }
  .mobile\:vads-u-border-left--4px {
    border-left: 4px solid !important;
  }
  .mobile\:vads-u-border--5px {
    border: 5px solid !important;
  }
  .mobile\:vads-u-border-top--5px {
    border-top: 5px solid !important;
  }
  .mobile\:vads-u-border-right--5px {
    border-right: 5px solid !important;
  }
  .mobile\:vads-u-border-bottom--5px {
    border-bottom: 5px solid !important;
  }
  .mobile\:vads-u-border-left--5px {
    border-left: 5px solid !important;
  }
  .mobile\:vads-u-border--7px {
    border: 7px solid !important;
  }
  .mobile\:vads-u-border-top--7px {
    border-top: 7px solid !important;
  }
  .mobile\:vads-u-border-right--7px {
    border-right: 7px solid !important;
  }
  .mobile\:vads-u-border-bottom--7px {
    border-bottom: 7px solid !important;
  }
  .mobile\:vads-u-border-left--7px {
    border-left: 7px solid !important;
  }
  .mobile\:vads-u-border--10px {
    border: 10px solid !important;
  }
  .mobile\:vads-u-border-top--10px {
    border-top: 10px solid !important;
  }
  .mobile\:vads-u-border-right--10px {
    border-right: 10px solid !important;
  }
  .mobile\:vads-u-border-bottom--10px {
    border-bottom: 10px solid !important;
  }
  .mobile\:vads-u-border-left--10px {
    border-left: 10px solid !important;
  }
  .mobile\:vads-u-display--block {
    display: block !important;
  }
  .mobile\:vads-u-display--inline {
    display: inline !important;
  }
  .mobile\:vads-u-display--inline-block {
    display: inline-block !important;
  }
  .mobile\:vads-u-display--flex {
    display: flex !important;
  }
  .mobile\:vads-u-display--none {
    display: none !important;
  }
  .mobile\:vads-u-flex--1 {
    flex: 1 0 0% !important;
  }
  .mobile\:vads-u-flex--2 {
    flex: 2 0 0% !important;
  }
  .mobile\:vads-u-flex--3 {
    flex: 3 0 0% !important;
  }
  .mobile\:vads-u-flex--4 {
    flex: 4 0 0% !important;
  }
  .mobile\:vads-u-flex--auto {
    flex: 0 0 auto !important;
  }
  .mobile\:vads-u-flex--fill {
    flex: 1 0 0% !important;
  }
  .mobile\:vads-u-flex-direction--column {
    flex-direction: column !important;
  }
  .mobile\:vads-u-flex-direction--row {
    flex-direction: row !important;
  }
  .mobile\:vads-u-flex-direction--column-reverse {
    flex-direction: column-reverse !important;
  }
  .mobile\:vads-u-flex-direction--row-reverse {
    flex-direction: row-reverse !important;
  }
  .mobile\:vads-u-flex-wrap--nowrap {
    flex-wrap: nowrap !important;
  }
  .mobile\:vads-u-flex-wrap--wrap {
    flex-wrap: wrap !important;
  }
  .mobile\:vads-u-flex-wrap--wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .mobile\:vads-u-align-items--stretch {
    align-items: stretch !important;
  }
  .mobile\:vads-u-align-items--flex-start {
    align-items: flex-start !important;
  }
  .mobile\:vads-u-align-items--flex-end {
    align-items: flex-end !important;
  }
  .mobile\:vads-u-align-items--center {
    align-items: center !important;
  }
  .mobile\:vads-u-align-items--baseline {
    align-items: baseline !important;
  }
  .mobile\:vads-u-justify-content--flex-start {
    justify-content: flex-start !important;
  }
  .mobile\:vads-u-justify-content--flex-end {
    justify-content: flex-end !important;
  }
  .mobile\:vads-u-justify-content--center {
    justify-content: center !important;
  }
  .mobile\:vads-u-justify-content--space-between {
    justify-content: space-between !important;
  }
  .mobile\:vads-u-justify-content--space-around {
    justify-content: space-around !important;
  }
  .mobile\:vads-u-justify-content--space-evenly {
    justify-content: space-evenly !important;
  }
  .mobile\:vads-u-align-content--flex-start {
    align-content: flex-start !important;
  }
  .mobile\:vads-u-align-content--flex-end {
    align-content: flex-end !important;
  }
  .mobile\:vads-u-align-content--center {
    align-content: center !important;
  }
  .mobile\:vads-u-align-content--space-between {
    align-content: space-between !important;
  }
  .mobile\:vads-u-align-content--space-around {
    align-content: space-around !important;
  }
  .mobile\:vads-u-align-content--stretch {
    align-content: stretch !important;
  }
  .mobile\:vads-u-align-self--auto {
    align-self: auto !important;
  }
  .mobile\:vads-u-align-self--flex-start {
    align-self: flex-start !important;
  }
  .mobile\:vads-u-align-self--flex-end {
    align-self: flex-end !important;
  }
  .mobile\:vads-u-align-self--center {
    align-self: center !important;
  }
  .mobile\:vads-u-align-self--baseline {
    align-self: baseline !important;
  }
  .mobile\:vads-u-align-self--stretch {
    align-self: stretch !important;
  }
  .mobile\:vads-u-order--1 {
    order: 1 !important;
  }
  .mobile\:vads-u-order--2 {
    order: 2 !important;
  }
  .mobile\:vads-u-order--3 {
    order: 3 !important;
  }
  .mobile\:vads-u-order--4 {
    order: 4 !important;
  }
  .mobile\:vads-u-order--first {
    order: -1 !important;
  }
  .mobile\:vads-u-order--last {
    order: 999 !important;
  }
  .mobile\:vads-u-order--initial {
    order: 0 !important;
  }
  .mobile\:vads-u-font-size--source-sans-normalized {
    font-size: 1.063rem !important;
  }
  .mobile\:vads-u-font-size--sm {
    font-size: 0.938rem !important;
  }
  .mobile\:vads-u-font-size--base {
    font-size: 1rem !important;
  }
  .mobile\:vads-u-font-size--md {
    font-size: 1.063rem !important;
  }
  .mobile\:vads-u-font-size--lg {
    font-size: 1.25rem !important;
  }
  .mobile\:vads-u-font-size--xl {
    font-size: 1.875rem !important;
  }
  .mobile\:vads-u-font-size--2xl {
    font-size: 2.5rem !important;
  }
  .mobile\:vads-u-font-size--h1 {
    font-size: 2.5rem !important;
  }
  .mobile\:vads-u-font-size--h2 {
    font-size: 1.875rem !important;
  }
  .mobile\:vads-u-font-size--h3 {
    font-size: 1.25rem !important;
  }
  .mobile\:vads-u-font-size--h4 {
    font-size: 1.063rem !important;
  }
  .mobile\:vads-u-font-size--h5 {
    font-size: 0.938rem !important;
  }
  .mobile\:vads-u-font-size--h6 {
    font-size: 0.938rem !important;
  }
  .mobile\:vads-u-height--0 {
    height: 0px !important;
  }
  .mobile\:vads-u-height--full {
    height: 100% !important;
  }
  .mobile\:vads-u-height--auto {
    height: auto !important;
  }
  .mobile\:vads-u-height--viewport {
    height: 100vh !important;
  }
  .mobile\:vads-u-min-height--none {
    min-height: none !important;
  }
  .mobile\:vads-u-min-height--viewport {
    min-height: 100vh !important;
  }
  .mobile\:vads-u-max-height--none {
    max-height: none !important;
  }
  .mobile\:vads-u-max-height--viewport {
    max-height: 100vh !important;
  }
  .mobile\:vads-u-line-height--1 {
    line-height: 1 !important;
  }
  .mobile\:vads-u-line-height--2 {
    line-height: 1.15 !important;
  }
  .mobile\:vads-u-line-height--3 {
    line-height: 1.35 !important;
  }
  .mobile\:vads-u-line-height--4 {
    line-height: 1.5 !important;
  }
  .mobile\:vads-u-line-height--5 {
    line-height: 1.62 !important;
  }
  .mobile\:vads-u-line-height--6 {
    line-height: 1.75 !important;
  }
  .mobile\:vads-u-margin--0 {
    margin: 0 !important;
  }
  .mobile\:vads-u-margin--1px {
    margin: 1px !important;
  }
  .mobile\:vads-u-margin--0p25 {
    margin: 2px !important;
  }
  .mobile\:vads-u-margin--0p5 {
    margin: 0.25rem !important;
  }
  .mobile\:vads-u-margin--1 {
    margin: 0.5rem !important;
  }
  .mobile\:vads-u-margin--1p5 {
    margin: 0.75rem !important;
  }
  .mobile\:vads-u-margin--2 {
    margin: 1rem !important;
  }
  .mobile\:vads-u-margin--2p5 {
    margin: 1.25rem !important;
  }
  .mobile\:vads-u-margin--3 {
    margin: 1.5rem !important;
  }
  .mobile\:vads-u-margin--4 {
    margin: 2rem !important;
  }
  .mobile\:vads-u-margin--5 {
    margin: 2.5rem !important;
  }
  .mobile\:vads-u-margin--6 {
    margin: 3rem !important;
  }
  .mobile\:vads-u-margin--7 {
    margin: 3.5rem !important;
  }
  .mobile\:vads-u-margin--8 {
    margin: 4rem !important;
  }
  .mobile\:vads-u-margin--9 {
    margin: 4.5rem !important;
  }
  .mobile\:vads-u-margin-y--0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .mobile\:vads-u-margin-top--0 {
    margin-top: 0 !important;
  }
  .mobile\:vads-u-margin-bottom--0 {
    margin-bottom: 0 !important;
  }
  .mobile\:vads-u-margin-y--1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .mobile\:vads-u-margin-top--1px {
    margin-top: 1px !important;
  }
  .mobile\:vads-u-margin-bottom--1px {
    margin-bottom: 1px !important;
  }
  .mobile\:vads-u-margin-y--0p25 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .mobile\:vads-u-margin-top--0p25 {
    margin-top: 2px !important;
  }
  .mobile\:vads-u-margin-bottom--0p25 {
    margin-bottom: 2px !important;
  }
  .mobile\:vads-u-margin-y--0p5 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .mobile\:vads-u-margin-top--0p5 {
    margin-top: 0.25rem !important;
  }
  .mobile\:vads-u-margin-bottom--0p5 {
    margin-bottom: 0.25rem !important;
  }
  .mobile\:vads-u-margin-y--1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .mobile\:vads-u-margin-top--1 {
    margin-top: 0.5rem !important;
  }
  .mobile\:vads-u-margin-bottom--1 {
    margin-bottom: 0.5rem !important;
  }
  .mobile\:vads-u-margin-y--1p5 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .mobile\:vads-u-margin-top--1p5 {
    margin-top: 0.75rem !important;
  }
  .mobile\:vads-u-margin-bottom--1p5 {
    margin-bottom: 0.75rem !important;
  }
  .mobile\:vads-u-margin-y--2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .mobile\:vads-u-margin-top--2 {
    margin-top: 1rem !important;
  }
  .mobile\:vads-u-margin-bottom--2 {
    margin-bottom: 1rem !important;
  }
  .mobile\:vads-u-margin-y--2p5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .mobile\:vads-u-margin-top--2p5 {
    margin-top: 1.25rem !important;
  }
  .mobile\:vads-u-margin-bottom--2p5 {
    margin-bottom: 1.25rem !important;
  }
  .mobile\:vads-u-margin-y--3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .mobile\:vads-u-margin-top--3 {
    margin-top: 1.5rem !important;
  }
  .mobile\:vads-u-margin-bottom--3 {
    margin-bottom: 1.5rem !important;
  }
  .mobile\:vads-u-margin-y--4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .mobile\:vads-u-margin-top--4 {
    margin-top: 2rem !important;
  }
  .mobile\:vads-u-margin-bottom--4 {
    margin-bottom: 2rem !important;
  }
  .mobile\:vads-u-margin-y--5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .mobile\:vads-u-margin-top--5 {
    margin-top: 2.5rem !important;
  }
  .mobile\:vads-u-margin-bottom--5 {
    margin-bottom: 2.5rem !important;
  }
  .mobile\:vads-u-margin-y--6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .mobile\:vads-u-margin-top--6 {
    margin-top: 3rem !important;
  }
  .mobile\:vads-u-margin-bottom--6 {
    margin-bottom: 3rem !important;
  }
  .mobile\:vads-u-margin-y--7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .mobile\:vads-u-margin-top--7 {
    margin-top: 3.5rem !important;
  }
  .mobile\:vads-u-margin-bottom--7 {
    margin-bottom: 3.5rem !important;
  }
  .mobile\:vads-u-margin-y--8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .mobile\:vads-u-margin-top--8 {
    margin-top: 4rem !important;
  }
  .mobile\:vads-u-margin-bottom--8 {
    margin-bottom: 4rem !important;
  }
  .mobile\:vads-u-margin-y--9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .mobile\:vads-u-margin-top--9 {
    margin-top: 4.5rem !important;
  }
  .mobile\:vads-u-margin-bottom--9 {
    margin-bottom: 4.5rem !important;
  }
  .mobile\:vads-u-margin-y--neg9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mobile\:vads-u-margin-top--neg9 {
    margin-top: -4.5rem !important;
  }
  .mobile\:vads-u-margin-bottom--neg9 {
    margin-bottom: -4.5rem !important;
  }
  .mobile\:vads-u-margin-y--neg8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .mobile\:vads-u-margin-top--neg8 {
    margin-top: -4rem !important;
  }
  .mobile\:vads-u-margin-bottom--neg8 {
    margin-bottom: -4rem !important;
  }
  .mobile\:vads-u-margin-y--neg7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .mobile\:vads-u-margin-top--neg7 {
    margin-top: -3.5rem !important;
  }
  .mobile\:vads-u-margin-bottom--neg7 {
    margin-bottom: -3.5rem !important;
  }
  .mobile\:vads-u-margin-y--neg6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mobile\:vads-u-margin-top--neg6 {
    margin-top: -3rem !important;
  }
  .mobile\:vads-u-margin-bottom--neg6 {
    margin-bottom: -3rem !important;
  }
  .mobile\:vads-u-margin-y--neg5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .mobile\:vads-u-margin-top--neg5 {
    margin-top: -2.5rem !important;
  }
  .mobile\:vads-u-margin-bottom--neg5 {
    margin-bottom: -2.5rem !important;
  }
  .mobile\:vads-u-margin-y--neg4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .mobile\:vads-u-margin-top--neg4 {
    margin-top: -2rem !important;
  }
  .mobile\:vads-u-margin-bottom--neg4 {
    margin-bottom: -2rem !important;
  }
  .mobile\:vads-u-margin-y--neg3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .mobile\:vads-u-margin-top--neg3 {
    margin-top: -1.5rem !important;
  }
  .mobile\:vads-u-margin-bottom--neg3 {
    margin-bottom: -1.5rem !important;
  }
  .mobile\:vads-u-margin-y--neg2p5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .mobile\:vads-u-margin-top--neg2p5 {
    margin-top: -1.25rem !important;
  }
  .mobile\:vads-u-margin-bottom--neg2p5 {
    margin-bottom: -1.25rem !important;
  }
  .mobile\:vads-u-margin-y--neg2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .mobile\:vads-u-margin-top--neg2 {
    margin-top: -1rem !important;
  }
  .mobile\:vads-u-margin-bottom--neg2 {
    margin-bottom: -1rem !important;
  }
  .mobile\:vads-u-margin-y--neg1p5 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .mobile\:vads-u-margin-top--neg1p5 {
    margin-top: -0.75rem !important;
  }
  .mobile\:vads-u-margin-bottom--neg1p5 {
    margin-bottom: -0.75rem !important;
  }
  .mobile\:vads-u-margin-y--neg1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .mobile\:vads-u-margin-top--neg1 {
    margin-top: -0.5rem !important;
  }
  .mobile\:vads-u-margin-bottom--neg1 {
    margin-bottom: -0.5rem !important;
  }
  .mobile\:vads-u-margin-y--neg0p5 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .mobile\:vads-u-margin-top--neg0p5 {
    margin-top: -0.25rem !important;
  }
  .mobile\:vads-u-margin-bottom--neg0p5 {
    margin-bottom: -0.25rem !important;
  }
  .mobile\:vads-u-margin-y--neg0p25 {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .mobile\:vads-u-margin-top--neg0p25 {
    margin-top: -2px !important;
  }
  .mobile\:vads-u-margin-bottom--neg0p25 {
    margin-bottom: -2px !important;
  }
  .mobile\:vads-u-margin-y--neg1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }
  .mobile\:vads-u-margin-top--neg1px {
    margin-top: -1px !important;
  }
  .mobile\:vads-u-margin-bottom--neg1px {
    margin-bottom: -1px !important;
  }
  .mobile\:vads-u-margin-x--0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mobile\:vads-u-margin-right--0 {
    margin-right: 0 !important;
  }
  .mobile\:vads-u-margin-left--0 {
    margin-left: 0 !important;
  }
  .mobile\:vads-u-margin-x--1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .mobile\:vads-u-margin-right--1px {
    margin-right: 1px !important;
  }
  .mobile\:vads-u-margin-left--1px {
    margin-left: 1px !important;
  }
  .mobile\:vads-u-margin-x--0p25 {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .mobile\:vads-u-margin-right--0p25 {
    margin-right: 2px !important;
  }
  .mobile\:vads-u-margin-left--0p25 {
    margin-left: 2px !important;
  }
  .mobile\:vads-u-margin-x--0p5 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mobile\:vads-u-margin-right--0p5 {
    margin-right: 0.25rem !important;
  }
  .mobile\:vads-u-margin-left--0p5 {
    margin-left: 0.25rem !important;
  }
  .mobile\:vads-u-margin-x--1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mobile\:vads-u-margin-right--1 {
    margin-right: 0.5rem !important;
  }
  .mobile\:vads-u-margin-left--1 {
    margin-left: 0.5rem !important;
  }
  .mobile\:vads-u-margin-x--1p5 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mobile\:vads-u-margin-right--1p5 {
    margin-right: 0.75rem !important;
  }
  .mobile\:vads-u-margin-left--1p5 {
    margin-left: 0.75rem !important;
  }
  .mobile\:vads-u-margin-x--2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mobile\:vads-u-margin-right--2 {
    margin-right: 1rem !important;
  }
  .mobile\:vads-u-margin-left--2 {
    margin-left: 1rem !important;
  }
  .mobile\:vads-u-margin-x--2p5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .mobile\:vads-u-margin-right--2p5 {
    margin-right: 1.25rem !important;
  }
  .mobile\:vads-u-margin-left--2p5 {
    margin-left: 1.25rem !important;
  }
  .mobile\:vads-u-margin-x--3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mobile\:vads-u-margin-right--3 {
    margin-right: 1.5rem !important;
  }
  .mobile\:vads-u-margin-left--3 {
    margin-left: 1.5rem !important;
  }
  .mobile\:vads-u-margin-x--4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mobile\:vads-u-margin-right--4 {
    margin-right: 2rem !important;
  }
  .mobile\:vads-u-margin-left--4 {
    margin-left: 2rem !important;
  }
  .mobile\:vads-u-margin-x--5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mobile\:vads-u-margin-right--5 {
    margin-right: 2.5rem !important;
  }
  .mobile\:vads-u-margin-left--5 {
    margin-left: 2.5rem !important;
  }
  .mobile\:vads-u-margin-x--6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mobile\:vads-u-margin-right--6 {
    margin-right: 3rem !important;
  }
  .mobile\:vads-u-margin-left--6 {
    margin-left: 3rem !important;
  }
  .mobile\:vads-u-margin-x--7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mobile\:vads-u-margin-right--7 {
    margin-right: 3.5rem !important;
  }
  .mobile\:vads-u-margin-left--7 {
    margin-left: 3.5rem !important;
  }
  .mobile\:vads-u-margin-x--8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mobile\:vads-u-margin-right--8 {
    margin-right: 4rem !important;
  }
  .mobile\:vads-u-margin-left--8 {
    margin-left: 4rem !important;
  }
  .mobile\:vads-u-margin-x--9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mobile\:vads-u-margin-right--9 {
    margin-right: 4.5rem !important;
  }
  .mobile\:vads-u-margin-left--9 {
    margin-left: 4.5rem !important;
  }
  .mobile\:vads-u-margin-x--neg9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mobile\:vads-u-margin-right--neg9 {
    margin-right: -4.5rem !important;
  }
  .mobile\:vads-u-margin-left--neg9 {
    margin-left: -4.5rem !important;
  }
  .mobile\:vads-u-margin-x--neg8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .mobile\:vads-u-margin-right--neg8 {
    margin-right: -4rem !important;
  }
  .mobile\:vads-u-margin-left--neg8 {
    margin-left: -4rem !important;
  }
  .mobile\:vads-u-margin-x--neg7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .mobile\:vads-u-margin-right--neg7 {
    margin-right: -3.5rem !important;
  }
  .mobile\:vads-u-margin-left--neg7 {
    margin-left: -3.5rem !important;
  }
  .mobile\:vads-u-margin-x--neg6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mobile\:vads-u-margin-right--neg6 {
    margin-right: -3rem !important;
  }
  .mobile\:vads-u-margin-left--neg6 {
    margin-left: -3rem !important;
  }
  .mobile\:vads-u-margin-x--neg5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .mobile\:vads-u-margin-right--neg5 {
    margin-right: -2.5rem !important;
  }
  .mobile\:vads-u-margin-left--neg5 {
    margin-left: -2.5rem !important;
  }
  .mobile\:vads-u-margin-x--neg4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .mobile\:vads-u-margin-right--neg4 {
    margin-right: -2rem !important;
  }
  .mobile\:vads-u-margin-left--neg4 {
    margin-left: -2rem !important;
  }
  .mobile\:vads-u-margin-x--neg3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mobile\:vads-u-margin-right--neg3 {
    margin-right: -1.5rem !important;
  }
  .mobile\:vads-u-margin-left--neg3 {
    margin-left: -1.5rem !important;
  }
  .mobile\:vads-u-margin-x--neg2p5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .mobile\:vads-u-margin-right--neg2p5 {
    margin-right: -1.25rem !important;
  }
  .mobile\:vads-u-margin-left--neg2p5 {
    margin-left: -1.25rem !important;
  }
  .mobile\:vads-u-margin-x--neg2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mobile\:vads-u-margin-right--neg2 {
    margin-right: -1rem !important;
  }
  .mobile\:vads-u-margin-left--neg2 {
    margin-left: -1rem !important;
  }
  .mobile\:vads-u-margin-x--neg1p5 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mobile\:vads-u-margin-right--neg1p5 {
    margin-right: -0.75rem !important;
  }
  .mobile\:vads-u-margin-left--neg1p5 {
    margin-left: -0.75rem !important;
  }
  .mobile\:vads-u-margin-x--neg1 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mobile\:vads-u-margin-right--neg1 {
    margin-right: -0.5rem !important;
  }
  .mobile\:vads-u-margin-left--neg1 {
    margin-left: -0.5rem !important;
  }
  .mobile\:vads-u-margin-x--neg0p5 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mobile\:vads-u-margin-right--neg0p5 {
    margin-right: -0.25rem !important;
  }
  .mobile\:vads-u-margin-left--neg0p5 {
    margin-left: -0.25rem !important;
  }
  .mobile\:vads-u-margin-x--neg0p25 {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }
  .mobile\:vads-u-margin-right--neg0p25 {
    margin-right: -2px !important;
  }
  .mobile\:vads-u-margin-left--neg0p25 {
    margin-left: -2px !important;
  }
  .mobile\:vads-u-margin-x--neg1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }
  .mobile\:vads-u-margin-right--neg1px {
    margin-right: -1px !important;
  }
  .mobile\:vads-u-margin-left--neg1px {
    margin-left: -1px !important;
  }
  .mobile\:vads-u-margin-x--auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .mobile\:vads-u-margin-right--auto {
    margin-right: auto !important;
  }
  .mobile\:vads-u-margin-left--auto {
    margin-left: auto !important;
  }
  .mobile\:vads-u-measure--1 {
    max-width: 40ch !important;
  }
  .mobile\:vads-u-measure--2 {
    max-width: 60ch !important;
  }
  .mobile\:vads-u-measure--3 {
    max-width: 66ch !important;
  }
  .mobile\:vads-u-measure--4 {
    max-width: 72ch !important;
  }
  .mobile\:vads-u-measure--5 {
    max-width: 77ch !important;
  }
  .mobile\:vads-u-measure--none {
    max-width: none !important;
  }
  .mobile\:vads-u-padding--0 {
    padding: 0 !important;
  }
  .mobile\:vads-u-padding-top--0 {
    padding-top: 0 !important;
  }
  .mobile\:vads-u-padding-bottom--0 {
    padding-bottom: 0 !important;
  }
  .mobile\:vads-u-padding-right--0 {
    padding-right: 0 !important;
  }
  .mobile\:vads-u-padding-left--0 {
    padding-left: 0 !important;
  }
  .mobile\:vads-u-padding--1px {
    padding: 1px !important;
  }
  .mobile\:vads-u-padding-top--1px {
    padding-top: 1px !important;
  }
  .mobile\:vads-u-padding-bottom--1px {
    padding-bottom: 1px !important;
  }
  .mobile\:vads-u-padding-right--1px {
    padding-right: 1px !important;
  }
  .mobile\:vads-u-padding-left--1px {
    padding-left: 1px !important;
  }
  .mobile\:vads-u-padding--0p25 {
    padding: 2px !important;
  }
  .mobile\:vads-u-padding-top--0p25 {
    padding-top: 2px !important;
  }
  .mobile\:vads-u-padding-bottom--0p25 {
    padding-bottom: 2px !important;
  }
  .mobile\:vads-u-padding-right--0p25 {
    padding-right: 2px !important;
  }
  .mobile\:vads-u-padding-left--0p25 {
    padding-left: 2px !important;
  }
  .mobile\:vads-u-padding--0p5 {
    padding: 0.25rem !important;
  }
  .mobile\:vads-u-padding-top--0p5 {
    padding-top: 0.25rem !important;
  }
  .mobile\:vads-u-padding-bottom--0p5 {
    padding-bottom: 0.25rem !important;
  }
  .mobile\:vads-u-padding-right--0p5 {
    padding-right: 0.25rem !important;
  }
  .mobile\:vads-u-padding-left--0p5 {
    padding-left: 0.25rem !important;
  }
  .mobile\:vads-u-padding--1 {
    padding: 0.5rem !important;
  }
  .mobile\:vads-u-padding-top--1 {
    padding-top: 0.5rem !important;
  }
  .mobile\:vads-u-padding-bottom--1 {
    padding-bottom: 0.5rem !important;
  }
  .mobile\:vads-u-padding-right--1 {
    padding-right: 0.5rem !important;
  }
  .mobile\:vads-u-padding-left--1 {
    padding-left: 0.5rem !important;
  }
  .mobile\:vads-u-padding--1p5 {
    padding: 0.75rem !important;
  }
  .mobile\:vads-u-padding-top--1p5 {
    padding-top: 0.75rem !important;
  }
  .mobile\:vads-u-padding-bottom--1p5 {
    padding-bottom: 0.75rem !important;
  }
  .mobile\:vads-u-padding-right--1p5 {
    padding-right: 0.75rem !important;
  }
  .mobile\:vads-u-padding-left--1p5 {
    padding-left: 0.75rem !important;
  }
  .mobile\:vads-u-padding--2 {
    padding: 1rem !important;
  }
  .mobile\:vads-u-padding-top--2 {
    padding-top: 1rem !important;
  }
  .mobile\:vads-u-padding-bottom--2 {
    padding-bottom: 1rem !important;
  }
  .mobile\:vads-u-padding-right--2 {
    padding-right: 1rem !important;
  }
  .mobile\:vads-u-padding-left--2 {
    padding-left: 1rem !important;
  }
  .mobile\:vads-u-padding--2p5 {
    padding: 1.25rem !important;
  }
  .mobile\:vads-u-padding-top--2p5 {
    padding-top: 1.25rem !important;
  }
  .mobile\:vads-u-padding-bottom--2p5 {
    padding-bottom: 1.25rem !important;
  }
  .mobile\:vads-u-padding-right--2p5 {
    padding-right: 1.25rem !important;
  }
  .mobile\:vads-u-padding-left--2p5 {
    padding-left: 1.25rem !important;
  }
  .mobile\:vads-u-padding--3 {
    padding: 1.5rem !important;
  }
  .mobile\:vads-u-padding-top--3 {
    padding-top: 1.5rem !important;
  }
  .mobile\:vads-u-padding-bottom--3 {
    padding-bottom: 1.5rem !important;
  }
  .mobile\:vads-u-padding-right--3 {
    padding-right: 1.5rem !important;
  }
  .mobile\:vads-u-padding-left--3 {
    padding-left: 1.5rem !important;
  }
  .mobile\:vads-u-padding--4 {
    padding: 2rem !important;
  }
  .mobile\:vads-u-padding-top--4 {
    padding-top: 2rem !important;
  }
  .mobile\:vads-u-padding-bottom--4 {
    padding-bottom: 2rem !important;
  }
  .mobile\:vads-u-padding-right--4 {
    padding-right: 2rem !important;
  }
  .mobile\:vads-u-padding-left--4 {
    padding-left: 2rem !important;
  }
  .mobile\:vads-u-padding--5 {
    padding: 2.5rem !important;
  }
  .mobile\:vads-u-padding-top--5 {
    padding-top: 2.5rem !important;
  }
  .mobile\:vads-u-padding-bottom--5 {
    padding-bottom: 2.5rem !important;
  }
  .mobile\:vads-u-padding-right--5 {
    padding-right: 2.5rem !important;
  }
  .mobile\:vads-u-padding-left--5 {
    padding-left: 2.5rem !important;
  }
  .mobile\:vads-u-padding--6 {
    padding: 3rem !important;
  }
  .mobile\:vads-u-padding-top--6 {
    padding-top: 3rem !important;
  }
  .mobile\:vads-u-padding-bottom--6 {
    padding-bottom: 3rem !important;
  }
  .mobile\:vads-u-padding-right--6 {
    padding-right: 3rem !important;
  }
  .mobile\:vads-u-padding-left--6 {
    padding-left: 3rem !important;
  }
  .mobile\:vads-u-padding--7 {
    padding: 3.5rem !important;
  }
  .mobile\:vads-u-padding-top--7 {
    padding-top: 3.5rem !important;
  }
  .mobile\:vads-u-padding-bottom--7 {
    padding-bottom: 3.5rem !important;
  }
  .mobile\:vads-u-padding-right--7 {
    padding-right: 3.5rem !important;
  }
  .mobile\:vads-u-padding-left--7 {
    padding-left: 3.5rem !important;
  }
  .mobile\:vads-u-padding--8 {
    padding: 4rem !important;
  }
  .mobile\:vads-u-padding-top--8 {
    padding-top: 4rem !important;
  }
  .mobile\:vads-u-padding-bottom--8 {
    padding-bottom: 4rem !important;
  }
  .mobile\:vads-u-padding-right--8 {
    padding-right: 4rem !important;
  }
  .mobile\:vads-u-padding-left--8 {
    padding-left: 4rem !important;
  }
  .mobile\:vads-u-padding--9 {
    padding: 4.5rem !important;
  }
  .mobile\:vads-u-padding-top--9 {
    padding-top: 4.5rem !important;
  }
  .mobile\:vads-u-padding-bottom--9 {
    padding-bottom: 4.5rem !important;
  }
  .mobile\:vads-u-padding-right--9 {
    padding-right: 4.5rem !important;
  }
  .mobile\:vads-u-padding-left--9 {
    padding-left: 4.5rem !important;
  }
  .mobile\:vads-u-padding-y--0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .mobile\:vads-u-padding-y--1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .mobile\:vads-u-padding-y--0p25 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .mobile\:vads-u-padding-y--0p5 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .mobile\:vads-u-padding-y--1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .mobile\:vads-u-padding-y--1p5 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .mobile\:vads-u-padding-y--2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .mobile\:vads-u-padding-y--2p5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .mobile\:vads-u-padding-y--3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .mobile\:vads-u-padding-y--4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .mobile\:vads-u-padding-y--5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .mobile\:vads-u-padding-y--6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .mobile\:vads-u-padding-y--7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .mobile\:vads-u-padding-y--8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .mobile\:vads-u-padding-y--9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .mobile\:vads-u-padding-x--0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .mobile\:vads-u-padding-x--1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .mobile\:vads-u-padding-x--0p25 {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .mobile\:vads-u-padding-x--0p5 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .mobile\:vads-u-padding-x--1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .mobile\:vads-u-padding-x--1p5 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .mobile\:vads-u-padding-x--2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .mobile\:vads-u-padding-x--2p5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .mobile\:vads-u-padding-x--3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .mobile\:vads-u-padding-x--4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .mobile\:vads-u-padding-x--5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .mobile\:vads-u-padding-x--6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .mobile\:vads-u-padding-x--7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .mobile\:vads-u-padding-x--8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .mobile\:vads-u-padding-x--9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .mobile\:vads-u-position--absolute {
    position: absolute !important;
  }
  .mobile\:vads-u-position--fixed {
    position: fixed !important;
  }
  .mobile\:vads-u-position--relative {
    position: relative !important;
  }
  .mobile\:vads-u-text-align--left {
    text-align: left !important;
  }
  .mobile\:vads-u-text-align--center {
    text-align: center !important;
  }
  .mobile\:vads-u-text-align--right {
    text-align: right !important;
  }
  .mobile\:vads-u-text-align--justify {
    text-align: justify !important;
  }
  .mobile\:vads-u-visibility--hidden {
    visibility: hidden !important;
  }
  .mobile\:vads-u-visibility--visible {
    visibility: visible !important;
  }
  .mobile\:vads-u-width--0 {
    width: 0px !important;
  }
  .mobile\:vads-u-width--full {
    width: 100% !important;
  }
  .mobile\:vads-u-width--auto {
    width: auto !important;
  }
  .mobile\:vads-u-min-width--none {
    min-width: none !important;
  }
  .mobile\:vads-u-max-width--none {
    max-width: none !important;
  }
  .mobile\:vads-u-max-width--100 {
    max-width: 100% !important;
  }
}
@media all and (min-width: 30em) {
  .mobile-lg\:vads-u-border--0 {
    border: 0 !important;
  }
  .mobile-lg\:vads-u-border-top--0 {
    border-top: 0 !important;
  }
  .mobile-lg\:vads-u-border-right--0 {
    border-right: 0 !important;
  }
  .mobile-lg\:vads-u-border-bottom--0 {
    border-bottom: 0 !important;
  }
  .mobile-lg\:vads-u-border-left--0 {
    border-left: 0 !important;
  }
  .mobile-lg\:vads-u-border--1px {
    border: 1px solid !important;
  }
  .mobile-lg\:vads-u-border-top--1px {
    border-top: 1px solid !important;
  }
  .mobile-lg\:vads-u-border-right--1px {
    border-right: 1px solid !important;
  }
  .mobile-lg\:vads-u-border-bottom--1px {
    border-bottom: 1px solid !important;
  }
  .mobile-lg\:vads-u-border-left--1px {
    border-left: 1px solid !important;
  }
  .mobile-lg\:vads-u-border--2px {
    border: 2px solid !important;
  }
  .mobile-lg\:vads-u-border-top--2px {
    border-top: 2px solid !important;
  }
  .mobile-lg\:vads-u-border-right--2px {
    border-right: 2px solid !important;
  }
  .mobile-lg\:vads-u-border-bottom--2px {
    border-bottom: 2px solid !important;
  }
  .mobile-lg\:vads-u-border-left--2px {
    border-left: 2px solid !important;
  }
  .mobile-lg\:vads-u-border--3px {
    border: 3px solid !important;
  }
  .mobile-lg\:vads-u-border-top--3px {
    border-top: 3px solid !important;
  }
  .mobile-lg\:vads-u-border-right--3px {
    border-right: 3px solid !important;
  }
  .mobile-lg\:vads-u-border-bottom--3px {
    border-bottom: 3px solid !important;
  }
  .mobile-lg\:vads-u-border-left--3px {
    border-left: 3px solid !important;
  }
  .mobile-lg\:vads-u-border--4px {
    border: 4px solid !important;
  }
  .mobile-lg\:vads-u-border-top--4px {
    border-top: 4px solid !important;
  }
  .mobile-lg\:vads-u-border-right--4px {
    border-right: 4px solid !important;
  }
  .mobile-lg\:vads-u-border-bottom--4px {
    border-bottom: 4px solid !important;
  }
  .mobile-lg\:vads-u-border-left--4px {
    border-left: 4px solid !important;
  }
  .mobile-lg\:vads-u-border--5px {
    border: 5px solid !important;
  }
  .mobile-lg\:vads-u-border-top--5px {
    border-top: 5px solid !important;
  }
  .mobile-lg\:vads-u-border-right--5px {
    border-right: 5px solid !important;
  }
  .mobile-lg\:vads-u-border-bottom--5px {
    border-bottom: 5px solid !important;
  }
  .mobile-lg\:vads-u-border-left--5px {
    border-left: 5px solid !important;
  }
  .mobile-lg\:vads-u-border--7px {
    border: 7px solid !important;
  }
  .mobile-lg\:vads-u-border-top--7px {
    border-top: 7px solid !important;
  }
  .mobile-lg\:vads-u-border-right--7px {
    border-right: 7px solid !important;
  }
  .mobile-lg\:vads-u-border-bottom--7px {
    border-bottom: 7px solid !important;
  }
  .mobile-lg\:vads-u-border-left--7px {
    border-left: 7px solid !important;
  }
  .mobile-lg\:vads-u-border--10px {
    border: 10px solid !important;
  }
  .mobile-lg\:vads-u-border-top--10px {
    border-top: 10px solid !important;
  }
  .mobile-lg\:vads-u-border-right--10px {
    border-right: 10px solid !important;
  }
  .mobile-lg\:vads-u-border-bottom--10px {
    border-bottom: 10px solid !important;
  }
  .mobile-lg\:vads-u-border-left--10px {
    border-left: 10px solid !important;
  }
  .mobile-lg\:vads-u-display--block {
    display: block !important;
  }
  .mobile-lg\:vads-u-display--inline {
    display: inline !important;
  }
  .mobile-lg\:vads-u-display--inline-block {
    display: inline-block !important;
  }
  .mobile-lg\:vads-u-display--flex {
    display: flex !important;
  }
  .mobile-lg\:vads-u-display--none {
    display: none !important;
  }
  .mobile-lg\:vads-u-flex--1 {
    flex: 1 0 0% !important;
  }
  .mobile-lg\:vads-u-flex--2 {
    flex: 2 0 0% !important;
  }
  .mobile-lg\:vads-u-flex--3 {
    flex: 3 0 0% !important;
  }
  .mobile-lg\:vads-u-flex--4 {
    flex: 4 0 0% !important;
  }
  .mobile-lg\:vads-u-flex--auto {
    flex: 0 0 auto !important;
  }
  .mobile-lg\:vads-u-flex--fill {
    flex: 1 0 0% !important;
  }
  .mobile-lg\:vads-u-flex-direction--column {
    flex-direction: column !important;
  }
  .mobile-lg\:vads-u-flex-direction--row {
    flex-direction: row !important;
  }
  .mobile-lg\:vads-u-flex-direction--column-reverse {
    flex-direction: column-reverse !important;
  }
  .mobile-lg\:vads-u-flex-direction--row-reverse {
    flex-direction: row-reverse !important;
  }
  .mobile-lg\:vads-u-flex-wrap--nowrap {
    flex-wrap: nowrap !important;
  }
  .mobile-lg\:vads-u-flex-wrap--wrap {
    flex-wrap: wrap !important;
  }
  .mobile-lg\:vads-u-flex-wrap--wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .mobile-lg\:vads-u-align-items--stretch {
    align-items: stretch !important;
  }
  .mobile-lg\:vads-u-align-items--flex-start {
    align-items: flex-start !important;
  }
  .mobile-lg\:vads-u-align-items--flex-end {
    align-items: flex-end !important;
  }
  .mobile-lg\:vads-u-align-items--center {
    align-items: center !important;
  }
  .mobile-lg\:vads-u-align-items--baseline {
    align-items: baseline !important;
  }
  .mobile-lg\:vads-u-justify-content--flex-start {
    justify-content: flex-start !important;
  }
  .mobile-lg\:vads-u-justify-content--flex-end {
    justify-content: flex-end !important;
  }
  .mobile-lg\:vads-u-justify-content--center {
    justify-content: center !important;
  }
  .mobile-lg\:vads-u-justify-content--space-between {
    justify-content: space-between !important;
  }
  .mobile-lg\:vads-u-justify-content--space-around {
    justify-content: space-around !important;
  }
  .mobile-lg\:vads-u-justify-content--space-evenly {
    justify-content: space-evenly !important;
  }
  .mobile-lg\:vads-u-align-content--flex-start {
    align-content: flex-start !important;
  }
  .mobile-lg\:vads-u-align-content--flex-end {
    align-content: flex-end !important;
  }
  .mobile-lg\:vads-u-align-content--center {
    align-content: center !important;
  }
  .mobile-lg\:vads-u-align-content--space-between {
    align-content: space-between !important;
  }
  .mobile-lg\:vads-u-align-content--space-around {
    align-content: space-around !important;
  }
  .mobile-lg\:vads-u-align-content--stretch {
    align-content: stretch !important;
  }
  .mobile-lg\:vads-u-align-self--auto {
    align-self: auto !important;
  }
  .mobile-lg\:vads-u-align-self--flex-start {
    align-self: flex-start !important;
  }
  .mobile-lg\:vads-u-align-self--flex-end {
    align-self: flex-end !important;
  }
  .mobile-lg\:vads-u-align-self--center {
    align-self: center !important;
  }
  .mobile-lg\:vads-u-align-self--baseline {
    align-self: baseline !important;
  }
  .mobile-lg\:vads-u-align-self--stretch {
    align-self: stretch !important;
  }
  .mobile-lg\:vads-u-order--1 {
    order: 1 !important;
  }
  .mobile-lg\:vads-u-order--2 {
    order: 2 !important;
  }
  .mobile-lg\:vads-u-order--3 {
    order: 3 !important;
  }
  .mobile-lg\:vads-u-order--4 {
    order: 4 !important;
  }
  .mobile-lg\:vads-u-order--first {
    order: -1 !important;
  }
  .mobile-lg\:vads-u-order--last {
    order: 999 !important;
  }
  .mobile-lg\:vads-u-order--initial {
    order: 0 !important;
  }
  .mobile-lg\:vads-u-font-size--source-sans-normalized {
    font-size: 1.063rem !important;
  }
  .mobile-lg\:vads-u-font-size--sm {
    font-size: 0.938rem !important;
  }
  .mobile-lg\:vads-u-font-size--base {
    font-size: 1rem !important;
  }
  .mobile-lg\:vads-u-font-size--md {
    font-size: 1.063rem !important;
  }
  .mobile-lg\:vads-u-font-size--lg {
    font-size: 1.25rem !important;
  }
  .mobile-lg\:vads-u-font-size--xl {
    font-size: 1.875rem !important;
  }
  .mobile-lg\:vads-u-font-size--2xl {
    font-size: 2.5rem !important;
  }
  .mobile-lg\:vads-u-font-size--h1 {
    font-size: 2.5rem !important;
  }
  .mobile-lg\:vads-u-font-size--h2 {
    font-size: 1.875rem !important;
  }
  .mobile-lg\:vads-u-font-size--h3 {
    font-size: 1.25rem !important;
  }
  .mobile-lg\:vads-u-font-size--h4 {
    font-size: 1.063rem !important;
  }
  .mobile-lg\:vads-u-font-size--h5 {
    font-size: 0.938rem !important;
  }
  .mobile-lg\:vads-u-font-size--h6 {
    font-size: 0.938rem !important;
  }
  .mobile-lg\:vads-u-height--0 {
    height: 0px !important;
  }
  .mobile-lg\:vads-u-height--full {
    height: 100% !important;
  }
  .mobile-lg\:vads-u-height--auto {
    height: auto !important;
  }
  .mobile-lg\:vads-u-height--viewport {
    height: 100vh !important;
  }
  .mobile-lg\:vads-u-min-height--none {
    min-height: none !important;
  }
  .mobile-lg\:vads-u-min-height--viewport {
    min-height: 100vh !important;
  }
  .mobile-lg\:vads-u-max-height--none {
    max-height: none !important;
  }
  .mobile-lg\:vads-u-max-height--viewport {
    max-height: 100vh !important;
  }
  .mobile-lg\:vads-u-line-height--1 {
    line-height: 1 !important;
  }
  .mobile-lg\:vads-u-line-height--2 {
    line-height: 1.15 !important;
  }
  .mobile-lg\:vads-u-line-height--3 {
    line-height: 1.35 !important;
  }
  .mobile-lg\:vads-u-line-height--4 {
    line-height: 1.5 !important;
  }
  .mobile-lg\:vads-u-line-height--5 {
    line-height: 1.62 !important;
  }
  .mobile-lg\:vads-u-line-height--6 {
    line-height: 1.75 !important;
  }
  .mobile-lg\:vads-u-margin--0 {
    margin: 0 !important;
  }
  .mobile-lg\:vads-u-margin--1px {
    margin: 1px !important;
  }
  .mobile-lg\:vads-u-margin--0p25 {
    margin: 2px !important;
  }
  .mobile-lg\:vads-u-margin--0p5 {
    margin: 0.25rem !important;
  }
  .mobile-lg\:vads-u-margin--1 {
    margin: 0.5rem !important;
  }
  .mobile-lg\:vads-u-margin--1p5 {
    margin: 0.75rem !important;
  }
  .mobile-lg\:vads-u-margin--2 {
    margin: 1rem !important;
  }
  .mobile-lg\:vads-u-margin--2p5 {
    margin: 1.25rem !important;
  }
  .mobile-lg\:vads-u-margin--3 {
    margin: 1.5rem !important;
  }
  .mobile-lg\:vads-u-margin--4 {
    margin: 2rem !important;
  }
  .mobile-lg\:vads-u-margin--5 {
    margin: 2.5rem !important;
  }
  .mobile-lg\:vads-u-margin--6 {
    margin: 3rem !important;
  }
  .mobile-lg\:vads-u-margin--7 {
    margin: 3.5rem !important;
  }
  .mobile-lg\:vads-u-margin--8 {
    margin: 4rem !important;
  }
  .mobile-lg\:vads-u-margin--9 {
    margin: 4.5rem !important;
  }
  .mobile-lg\:vads-u-margin-y--0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .mobile-lg\:vads-u-margin-top--0 {
    margin-top: 0 !important;
  }
  .mobile-lg\:vads-u-margin-bottom--0 {
    margin-bottom: 0 !important;
  }
  .mobile-lg\:vads-u-margin-y--1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .mobile-lg\:vads-u-margin-top--1px {
    margin-top: 1px !important;
  }
  .mobile-lg\:vads-u-margin-bottom--1px {
    margin-bottom: 1px !important;
  }
  .mobile-lg\:vads-u-margin-y--0p25 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .mobile-lg\:vads-u-margin-top--0p25 {
    margin-top: 2px !important;
  }
  .mobile-lg\:vads-u-margin-bottom--0p25 {
    margin-bottom: 2px !important;
  }
  .mobile-lg\:vads-u-margin-y--0p5 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .mobile-lg\:vads-u-margin-top--0p5 {
    margin-top: 0.25rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--0p5 {
    margin-bottom: 0.25rem !important;
  }
  .mobile-lg\:vads-u-margin-y--1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .mobile-lg\:vads-u-margin-top--1 {
    margin-top: 0.5rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--1 {
    margin-bottom: 0.5rem !important;
  }
  .mobile-lg\:vads-u-margin-y--1p5 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .mobile-lg\:vads-u-margin-top--1p5 {
    margin-top: 0.75rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--1p5 {
    margin-bottom: 0.75rem !important;
  }
  .mobile-lg\:vads-u-margin-y--2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .mobile-lg\:vads-u-margin-top--2 {
    margin-top: 1rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--2 {
    margin-bottom: 1rem !important;
  }
  .mobile-lg\:vads-u-margin-y--2p5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .mobile-lg\:vads-u-margin-top--2p5 {
    margin-top: 1.25rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--2p5 {
    margin-bottom: 1.25rem !important;
  }
  .mobile-lg\:vads-u-margin-y--3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .mobile-lg\:vads-u-margin-top--3 {
    margin-top: 1.5rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--3 {
    margin-bottom: 1.5rem !important;
  }
  .mobile-lg\:vads-u-margin-y--4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .mobile-lg\:vads-u-margin-top--4 {
    margin-top: 2rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--4 {
    margin-bottom: 2rem !important;
  }
  .mobile-lg\:vads-u-margin-y--5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .mobile-lg\:vads-u-margin-top--5 {
    margin-top: 2.5rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--5 {
    margin-bottom: 2.5rem !important;
  }
  .mobile-lg\:vads-u-margin-y--6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .mobile-lg\:vads-u-margin-top--6 {
    margin-top: 3rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--6 {
    margin-bottom: 3rem !important;
  }
  .mobile-lg\:vads-u-margin-y--7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .mobile-lg\:vads-u-margin-top--7 {
    margin-top: 3.5rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--7 {
    margin-bottom: 3.5rem !important;
  }
  .mobile-lg\:vads-u-margin-y--8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .mobile-lg\:vads-u-margin-top--8 {
    margin-top: 4rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--8 {
    margin-bottom: 4rem !important;
  }
  .mobile-lg\:vads-u-margin-y--9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .mobile-lg\:vads-u-margin-top--9 {
    margin-top: 4.5rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--9 {
    margin-bottom: 4.5rem !important;
  }
  .mobile-lg\:vads-u-margin-y--neg9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mobile-lg\:vads-u-margin-top--neg9 {
    margin-top: -4.5rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--neg9 {
    margin-bottom: -4.5rem !important;
  }
  .mobile-lg\:vads-u-margin-y--neg8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .mobile-lg\:vads-u-margin-top--neg8 {
    margin-top: -4rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--neg8 {
    margin-bottom: -4rem !important;
  }
  .mobile-lg\:vads-u-margin-y--neg7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .mobile-lg\:vads-u-margin-top--neg7 {
    margin-top: -3.5rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--neg7 {
    margin-bottom: -3.5rem !important;
  }
  .mobile-lg\:vads-u-margin-y--neg6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mobile-lg\:vads-u-margin-top--neg6 {
    margin-top: -3rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--neg6 {
    margin-bottom: -3rem !important;
  }
  .mobile-lg\:vads-u-margin-y--neg5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .mobile-lg\:vads-u-margin-top--neg5 {
    margin-top: -2.5rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--neg5 {
    margin-bottom: -2.5rem !important;
  }
  .mobile-lg\:vads-u-margin-y--neg4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .mobile-lg\:vads-u-margin-top--neg4 {
    margin-top: -2rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--neg4 {
    margin-bottom: -2rem !important;
  }
  .mobile-lg\:vads-u-margin-y--neg3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .mobile-lg\:vads-u-margin-top--neg3 {
    margin-top: -1.5rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--neg3 {
    margin-bottom: -1.5rem !important;
  }
  .mobile-lg\:vads-u-margin-y--neg2p5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .mobile-lg\:vads-u-margin-top--neg2p5 {
    margin-top: -1.25rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--neg2p5 {
    margin-bottom: -1.25rem !important;
  }
  .mobile-lg\:vads-u-margin-y--neg2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .mobile-lg\:vads-u-margin-top--neg2 {
    margin-top: -1rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--neg2 {
    margin-bottom: -1rem !important;
  }
  .mobile-lg\:vads-u-margin-y--neg1p5 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .mobile-lg\:vads-u-margin-top--neg1p5 {
    margin-top: -0.75rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--neg1p5 {
    margin-bottom: -0.75rem !important;
  }
  .mobile-lg\:vads-u-margin-y--neg1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .mobile-lg\:vads-u-margin-top--neg1 {
    margin-top: -0.5rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--neg1 {
    margin-bottom: -0.5rem !important;
  }
  .mobile-lg\:vads-u-margin-y--neg0p5 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .mobile-lg\:vads-u-margin-top--neg0p5 {
    margin-top: -0.25rem !important;
  }
  .mobile-lg\:vads-u-margin-bottom--neg0p5 {
    margin-bottom: -0.25rem !important;
  }
  .mobile-lg\:vads-u-margin-y--neg0p25 {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .mobile-lg\:vads-u-margin-top--neg0p25 {
    margin-top: -2px !important;
  }
  .mobile-lg\:vads-u-margin-bottom--neg0p25 {
    margin-bottom: -2px !important;
  }
  .mobile-lg\:vads-u-margin-y--neg1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }
  .mobile-lg\:vads-u-margin-top--neg1px {
    margin-top: -1px !important;
  }
  .mobile-lg\:vads-u-margin-bottom--neg1px {
    margin-bottom: -1px !important;
  }
  .mobile-lg\:vads-u-margin-x--0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mobile-lg\:vads-u-margin-right--0 {
    margin-right: 0 !important;
  }
  .mobile-lg\:vads-u-margin-left--0 {
    margin-left: 0 !important;
  }
  .mobile-lg\:vads-u-margin-x--1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .mobile-lg\:vads-u-margin-right--1px {
    margin-right: 1px !important;
  }
  .mobile-lg\:vads-u-margin-left--1px {
    margin-left: 1px !important;
  }
  .mobile-lg\:vads-u-margin-x--0p25 {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .mobile-lg\:vads-u-margin-right--0p25 {
    margin-right: 2px !important;
  }
  .mobile-lg\:vads-u-margin-left--0p25 {
    margin-left: 2px !important;
  }
  .mobile-lg\:vads-u-margin-x--0p5 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mobile-lg\:vads-u-margin-right--0p5 {
    margin-right: 0.25rem !important;
  }
  .mobile-lg\:vads-u-margin-left--0p5 {
    margin-left: 0.25rem !important;
  }
  .mobile-lg\:vads-u-margin-x--1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mobile-lg\:vads-u-margin-right--1 {
    margin-right: 0.5rem !important;
  }
  .mobile-lg\:vads-u-margin-left--1 {
    margin-left: 0.5rem !important;
  }
  .mobile-lg\:vads-u-margin-x--1p5 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mobile-lg\:vads-u-margin-right--1p5 {
    margin-right: 0.75rem !important;
  }
  .mobile-lg\:vads-u-margin-left--1p5 {
    margin-left: 0.75rem !important;
  }
  .mobile-lg\:vads-u-margin-x--2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mobile-lg\:vads-u-margin-right--2 {
    margin-right: 1rem !important;
  }
  .mobile-lg\:vads-u-margin-left--2 {
    margin-left: 1rem !important;
  }
  .mobile-lg\:vads-u-margin-x--2p5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .mobile-lg\:vads-u-margin-right--2p5 {
    margin-right: 1.25rem !important;
  }
  .mobile-lg\:vads-u-margin-left--2p5 {
    margin-left: 1.25rem !important;
  }
  .mobile-lg\:vads-u-margin-x--3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mobile-lg\:vads-u-margin-right--3 {
    margin-right: 1.5rem !important;
  }
  .mobile-lg\:vads-u-margin-left--3 {
    margin-left: 1.5rem !important;
  }
  .mobile-lg\:vads-u-margin-x--4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mobile-lg\:vads-u-margin-right--4 {
    margin-right: 2rem !important;
  }
  .mobile-lg\:vads-u-margin-left--4 {
    margin-left: 2rem !important;
  }
  .mobile-lg\:vads-u-margin-x--5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mobile-lg\:vads-u-margin-right--5 {
    margin-right: 2.5rem !important;
  }
  .mobile-lg\:vads-u-margin-left--5 {
    margin-left: 2.5rem !important;
  }
  .mobile-lg\:vads-u-margin-x--6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mobile-lg\:vads-u-margin-right--6 {
    margin-right: 3rem !important;
  }
  .mobile-lg\:vads-u-margin-left--6 {
    margin-left: 3rem !important;
  }
  .mobile-lg\:vads-u-margin-x--7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mobile-lg\:vads-u-margin-right--7 {
    margin-right: 3.5rem !important;
  }
  .mobile-lg\:vads-u-margin-left--7 {
    margin-left: 3.5rem !important;
  }
  .mobile-lg\:vads-u-margin-x--8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mobile-lg\:vads-u-margin-right--8 {
    margin-right: 4rem !important;
  }
  .mobile-lg\:vads-u-margin-left--8 {
    margin-left: 4rem !important;
  }
  .mobile-lg\:vads-u-margin-x--9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mobile-lg\:vads-u-margin-right--9 {
    margin-right: 4.5rem !important;
  }
  .mobile-lg\:vads-u-margin-left--9 {
    margin-left: 4.5rem !important;
  }
  .mobile-lg\:vads-u-margin-x--neg9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mobile-lg\:vads-u-margin-right--neg9 {
    margin-right: -4.5rem !important;
  }
  .mobile-lg\:vads-u-margin-left--neg9 {
    margin-left: -4.5rem !important;
  }
  .mobile-lg\:vads-u-margin-x--neg8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .mobile-lg\:vads-u-margin-right--neg8 {
    margin-right: -4rem !important;
  }
  .mobile-lg\:vads-u-margin-left--neg8 {
    margin-left: -4rem !important;
  }
  .mobile-lg\:vads-u-margin-x--neg7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .mobile-lg\:vads-u-margin-right--neg7 {
    margin-right: -3.5rem !important;
  }
  .mobile-lg\:vads-u-margin-left--neg7 {
    margin-left: -3.5rem !important;
  }
  .mobile-lg\:vads-u-margin-x--neg6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mobile-lg\:vads-u-margin-right--neg6 {
    margin-right: -3rem !important;
  }
  .mobile-lg\:vads-u-margin-left--neg6 {
    margin-left: -3rem !important;
  }
  .mobile-lg\:vads-u-margin-x--neg5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .mobile-lg\:vads-u-margin-right--neg5 {
    margin-right: -2.5rem !important;
  }
  .mobile-lg\:vads-u-margin-left--neg5 {
    margin-left: -2.5rem !important;
  }
  .mobile-lg\:vads-u-margin-x--neg4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .mobile-lg\:vads-u-margin-right--neg4 {
    margin-right: -2rem !important;
  }
  .mobile-lg\:vads-u-margin-left--neg4 {
    margin-left: -2rem !important;
  }
  .mobile-lg\:vads-u-margin-x--neg3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mobile-lg\:vads-u-margin-right--neg3 {
    margin-right: -1.5rem !important;
  }
  .mobile-lg\:vads-u-margin-left--neg3 {
    margin-left: -1.5rem !important;
  }
  .mobile-lg\:vads-u-margin-x--neg2p5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .mobile-lg\:vads-u-margin-right--neg2p5 {
    margin-right: -1.25rem !important;
  }
  .mobile-lg\:vads-u-margin-left--neg2p5 {
    margin-left: -1.25rem !important;
  }
  .mobile-lg\:vads-u-margin-x--neg2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mobile-lg\:vads-u-margin-right--neg2 {
    margin-right: -1rem !important;
  }
  .mobile-lg\:vads-u-margin-left--neg2 {
    margin-left: -1rem !important;
  }
  .mobile-lg\:vads-u-margin-x--neg1p5 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mobile-lg\:vads-u-margin-right--neg1p5 {
    margin-right: -0.75rem !important;
  }
  .mobile-lg\:vads-u-margin-left--neg1p5 {
    margin-left: -0.75rem !important;
  }
  .mobile-lg\:vads-u-margin-x--neg1 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mobile-lg\:vads-u-margin-right--neg1 {
    margin-right: -0.5rem !important;
  }
  .mobile-lg\:vads-u-margin-left--neg1 {
    margin-left: -0.5rem !important;
  }
  .mobile-lg\:vads-u-margin-x--neg0p5 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mobile-lg\:vads-u-margin-right--neg0p5 {
    margin-right: -0.25rem !important;
  }
  .mobile-lg\:vads-u-margin-left--neg0p5 {
    margin-left: -0.25rem !important;
  }
  .mobile-lg\:vads-u-margin-x--neg0p25 {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }
  .mobile-lg\:vads-u-margin-right--neg0p25 {
    margin-right: -2px !important;
  }
  .mobile-lg\:vads-u-margin-left--neg0p25 {
    margin-left: -2px !important;
  }
  .mobile-lg\:vads-u-margin-x--neg1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }
  .mobile-lg\:vads-u-margin-right--neg1px {
    margin-right: -1px !important;
  }
  .mobile-lg\:vads-u-margin-left--neg1px {
    margin-left: -1px !important;
  }
  .mobile-lg\:vads-u-margin-x--auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .mobile-lg\:vads-u-margin-right--auto {
    margin-right: auto !important;
  }
  .mobile-lg\:vads-u-margin-left--auto {
    margin-left: auto !important;
  }
  .mobile-lg\:vads-u-measure--1 {
    max-width: 40ch !important;
  }
  .mobile-lg\:vads-u-measure--2 {
    max-width: 60ch !important;
  }
  .mobile-lg\:vads-u-measure--3 {
    max-width: 66ch !important;
  }
  .mobile-lg\:vads-u-measure--4 {
    max-width: 72ch !important;
  }
  .mobile-lg\:vads-u-measure--5 {
    max-width: 77ch !important;
  }
  .mobile-lg\:vads-u-measure--none {
    max-width: none !important;
  }
  .mobile-lg\:vads-u-padding--0 {
    padding: 0 !important;
  }
  .mobile-lg\:vads-u-padding-top--0 {
    padding-top: 0 !important;
  }
  .mobile-lg\:vads-u-padding-bottom--0 {
    padding-bottom: 0 !important;
  }
  .mobile-lg\:vads-u-padding-right--0 {
    padding-right: 0 !important;
  }
  .mobile-lg\:vads-u-padding-left--0 {
    padding-left: 0 !important;
  }
  .mobile-lg\:vads-u-padding--1px {
    padding: 1px !important;
  }
  .mobile-lg\:vads-u-padding-top--1px {
    padding-top: 1px !important;
  }
  .mobile-lg\:vads-u-padding-bottom--1px {
    padding-bottom: 1px !important;
  }
  .mobile-lg\:vads-u-padding-right--1px {
    padding-right: 1px !important;
  }
  .mobile-lg\:vads-u-padding-left--1px {
    padding-left: 1px !important;
  }
  .mobile-lg\:vads-u-padding--0p25 {
    padding: 2px !important;
  }
  .mobile-lg\:vads-u-padding-top--0p25 {
    padding-top: 2px !important;
  }
  .mobile-lg\:vads-u-padding-bottom--0p25 {
    padding-bottom: 2px !important;
  }
  .mobile-lg\:vads-u-padding-right--0p25 {
    padding-right: 2px !important;
  }
  .mobile-lg\:vads-u-padding-left--0p25 {
    padding-left: 2px !important;
  }
  .mobile-lg\:vads-u-padding--0p5 {
    padding: 0.25rem !important;
  }
  .mobile-lg\:vads-u-padding-top--0p5 {
    padding-top: 0.25rem !important;
  }
  .mobile-lg\:vads-u-padding-bottom--0p5 {
    padding-bottom: 0.25rem !important;
  }
  .mobile-lg\:vads-u-padding-right--0p5 {
    padding-right: 0.25rem !important;
  }
  .mobile-lg\:vads-u-padding-left--0p5 {
    padding-left: 0.25rem !important;
  }
  .mobile-lg\:vads-u-padding--1 {
    padding: 0.5rem !important;
  }
  .mobile-lg\:vads-u-padding-top--1 {
    padding-top: 0.5rem !important;
  }
  .mobile-lg\:vads-u-padding-bottom--1 {
    padding-bottom: 0.5rem !important;
  }
  .mobile-lg\:vads-u-padding-right--1 {
    padding-right: 0.5rem !important;
  }
  .mobile-lg\:vads-u-padding-left--1 {
    padding-left: 0.5rem !important;
  }
  .mobile-lg\:vads-u-padding--1p5 {
    padding: 0.75rem !important;
  }
  .mobile-lg\:vads-u-padding-top--1p5 {
    padding-top: 0.75rem !important;
  }
  .mobile-lg\:vads-u-padding-bottom--1p5 {
    padding-bottom: 0.75rem !important;
  }
  .mobile-lg\:vads-u-padding-right--1p5 {
    padding-right: 0.75rem !important;
  }
  .mobile-lg\:vads-u-padding-left--1p5 {
    padding-left: 0.75rem !important;
  }
  .mobile-lg\:vads-u-padding--2 {
    padding: 1rem !important;
  }
  .mobile-lg\:vads-u-padding-top--2 {
    padding-top: 1rem !important;
  }
  .mobile-lg\:vads-u-padding-bottom--2 {
    padding-bottom: 1rem !important;
  }
  .mobile-lg\:vads-u-padding-right--2 {
    padding-right: 1rem !important;
  }
  .mobile-lg\:vads-u-padding-left--2 {
    padding-left: 1rem !important;
  }
  .mobile-lg\:vads-u-padding--2p5 {
    padding: 1.25rem !important;
  }
  .mobile-lg\:vads-u-padding-top--2p5 {
    padding-top: 1.25rem !important;
  }
  .mobile-lg\:vads-u-padding-bottom--2p5 {
    padding-bottom: 1.25rem !important;
  }
  .mobile-lg\:vads-u-padding-right--2p5 {
    padding-right: 1.25rem !important;
  }
  .mobile-lg\:vads-u-padding-left--2p5 {
    padding-left: 1.25rem !important;
  }
  .mobile-lg\:vads-u-padding--3 {
    padding: 1.5rem !important;
  }
  .mobile-lg\:vads-u-padding-top--3 {
    padding-top: 1.5rem !important;
  }
  .mobile-lg\:vads-u-padding-bottom--3 {
    padding-bottom: 1.5rem !important;
  }
  .mobile-lg\:vads-u-padding-right--3 {
    padding-right: 1.5rem !important;
  }
  .mobile-lg\:vads-u-padding-left--3 {
    padding-left: 1.5rem !important;
  }
  .mobile-lg\:vads-u-padding--4 {
    padding: 2rem !important;
  }
  .mobile-lg\:vads-u-padding-top--4 {
    padding-top: 2rem !important;
  }
  .mobile-lg\:vads-u-padding-bottom--4 {
    padding-bottom: 2rem !important;
  }
  .mobile-lg\:vads-u-padding-right--4 {
    padding-right: 2rem !important;
  }
  .mobile-lg\:vads-u-padding-left--4 {
    padding-left: 2rem !important;
  }
  .mobile-lg\:vads-u-padding--5 {
    padding: 2.5rem !important;
  }
  .mobile-lg\:vads-u-padding-top--5 {
    padding-top: 2.5rem !important;
  }
  .mobile-lg\:vads-u-padding-bottom--5 {
    padding-bottom: 2.5rem !important;
  }
  .mobile-lg\:vads-u-padding-right--5 {
    padding-right: 2.5rem !important;
  }
  .mobile-lg\:vads-u-padding-left--5 {
    padding-left: 2.5rem !important;
  }
  .mobile-lg\:vads-u-padding--6 {
    padding: 3rem !important;
  }
  .mobile-lg\:vads-u-padding-top--6 {
    padding-top: 3rem !important;
  }
  .mobile-lg\:vads-u-padding-bottom--6 {
    padding-bottom: 3rem !important;
  }
  .mobile-lg\:vads-u-padding-right--6 {
    padding-right: 3rem !important;
  }
  .mobile-lg\:vads-u-padding-left--6 {
    padding-left: 3rem !important;
  }
  .mobile-lg\:vads-u-padding--7 {
    padding: 3.5rem !important;
  }
  .mobile-lg\:vads-u-padding-top--7 {
    padding-top: 3.5rem !important;
  }
  .mobile-lg\:vads-u-padding-bottom--7 {
    padding-bottom: 3.5rem !important;
  }
  .mobile-lg\:vads-u-padding-right--7 {
    padding-right: 3.5rem !important;
  }
  .mobile-lg\:vads-u-padding-left--7 {
    padding-left: 3.5rem !important;
  }
  .mobile-lg\:vads-u-padding--8 {
    padding: 4rem !important;
  }
  .mobile-lg\:vads-u-padding-top--8 {
    padding-top: 4rem !important;
  }
  .mobile-lg\:vads-u-padding-bottom--8 {
    padding-bottom: 4rem !important;
  }
  .mobile-lg\:vads-u-padding-right--8 {
    padding-right: 4rem !important;
  }
  .mobile-lg\:vads-u-padding-left--8 {
    padding-left: 4rem !important;
  }
  .mobile-lg\:vads-u-padding--9 {
    padding: 4.5rem !important;
  }
  .mobile-lg\:vads-u-padding-top--9 {
    padding-top: 4.5rem !important;
  }
  .mobile-lg\:vads-u-padding-bottom--9 {
    padding-bottom: 4.5rem !important;
  }
  .mobile-lg\:vads-u-padding-right--9 {
    padding-right: 4.5rem !important;
  }
  .mobile-lg\:vads-u-padding-left--9 {
    padding-left: 4.5rem !important;
  }
  .mobile-lg\:vads-u-padding-y--0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .mobile-lg\:vads-u-padding-y--1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .mobile-lg\:vads-u-padding-y--0p25 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .mobile-lg\:vads-u-padding-y--0p5 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .mobile-lg\:vads-u-padding-y--1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .mobile-lg\:vads-u-padding-y--1p5 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .mobile-lg\:vads-u-padding-y--2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .mobile-lg\:vads-u-padding-y--2p5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .mobile-lg\:vads-u-padding-y--3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .mobile-lg\:vads-u-padding-y--4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .mobile-lg\:vads-u-padding-y--5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .mobile-lg\:vads-u-padding-y--6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .mobile-lg\:vads-u-padding-y--7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .mobile-lg\:vads-u-padding-y--8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .mobile-lg\:vads-u-padding-y--9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .mobile-lg\:vads-u-padding-x--0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .mobile-lg\:vads-u-padding-x--1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .mobile-lg\:vads-u-padding-x--0p25 {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .mobile-lg\:vads-u-padding-x--0p5 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .mobile-lg\:vads-u-padding-x--1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .mobile-lg\:vads-u-padding-x--1p5 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .mobile-lg\:vads-u-padding-x--2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .mobile-lg\:vads-u-padding-x--2p5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .mobile-lg\:vads-u-padding-x--3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .mobile-lg\:vads-u-padding-x--4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .mobile-lg\:vads-u-padding-x--5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .mobile-lg\:vads-u-padding-x--6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .mobile-lg\:vads-u-padding-x--7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .mobile-lg\:vads-u-padding-x--8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .mobile-lg\:vads-u-padding-x--9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .mobile-lg\:vads-u-position--absolute {
    position: absolute !important;
  }
  .mobile-lg\:vads-u-position--fixed {
    position: fixed !important;
  }
  .mobile-lg\:vads-u-position--relative {
    position: relative !important;
  }
  .mobile-lg\:vads-u-text-align--left {
    text-align: left !important;
  }
  .mobile-lg\:vads-u-text-align--center {
    text-align: center !important;
  }
  .mobile-lg\:vads-u-text-align--right {
    text-align: right !important;
  }
  .mobile-lg\:vads-u-text-align--justify {
    text-align: justify !important;
  }
  .mobile-lg\:vads-u-visibility--hidden {
    visibility: hidden !important;
  }
  .mobile-lg\:vads-u-visibility--visible {
    visibility: visible !important;
  }
  .mobile-lg\:vads-u-width--0 {
    width: 0px !important;
  }
  .mobile-lg\:vads-u-width--full {
    width: 100% !important;
  }
  .mobile-lg\:vads-u-width--auto {
    width: auto !important;
  }
  .mobile-lg\:vads-u-min-width--none {
    min-width: none !important;
  }
  .mobile-lg\:vads-u-max-width--none {
    max-width: none !important;
  }
  .mobile-lg\:vads-u-max-width--100 {
    max-width: 100% !important;
  }
}
@media all and (min-width: 40em) {
  .tablet\:vads-u-border--0 {
    border: 0 !important;
  }
  .tablet\:vads-u-border-top--0 {
    border-top: 0 !important;
  }
  .tablet\:vads-u-border-right--0 {
    border-right: 0 !important;
  }
  .tablet\:vads-u-border-bottom--0 {
    border-bottom: 0 !important;
  }
  .tablet\:vads-u-border-left--0 {
    border-left: 0 !important;
  }
  .tablet\:vads-u-border--1px {
    border: 1px solid !important;
  }
  .tablet\:vads-u-border-top--1px {
    border-top: 1px solid !important;
  }
  .tablet\:vads-u-border-right--1px {
    border-right: 1px solid !important;
  }
  .tablet\:vads-u-border-bottom--1px {
    border-bottom: 1px solid !important;
  }
  .tablet\:vads-u-border-left--1px {
    border-left: 1px solid !important;
  }
  .tablet\:vads-u-border--2px {
    border: 2px solid !important;
  }
  .tablet\:vads-u-border-top--2px {
    border-top: 2px solid !important;
  }
  .tablet\:vads-u-border-right--2px {
    border-right: 2px solid !important;
  }
  .tablet\:vads-u-border-bottom--2px {
    border-bottom: 2px solid !important;
  }
  .tablet\:vads-u-border-left--2px {
    border-left: 2px solid !important;
  }
  .tablet\:vads-u-border--3px {
    border: 3px solid !important;
  }
  .tablet\:vads-u-border-top--3px {
    border-top: 3px solid !important;
  }
  .tablet\:vads-u-border-right--3px {
    border-right: 3px solid !important;
  }
  .tablet\:vads-u-border-bottom--3px {
    border-bottom: 3px solid !important;
  }
  .tablet\:vads-u-border-left--3px {
    border-left: 3px solid !important;
  }
  .tablet\:vads-u-border--4px {
    border: 4px solid !important;
  }
  .tablet\:vads-u-border-top--4px {
    border-top: 4px solid !important;
  }
  .tablet\:vads-u-border-right--4px {
    border-right: 4px solid !important;
  }
  .tablet\:vads-u-border-bottom--4px {
    border-bottom: 4px solid !important;
  }
  .tablet\:vads-u-border-left--4px {
    border-left: 4px solid !important;
  }
  .tablet\:vads-u-border--5px {
    border: 5px solid !important;
  }
  .tablet\:vads-u-border-top--5px {
    border-top: 5px solid !important;
  }
  .tablet\:vads-u-border-right--5px {
    border-right: 5px solid !important;
  }
  .tablet\:vads-u-border-bottom--5px {
    border-bottom: 5px solid !important;
  }
  .tablet\:vads-u-border-left--5px {
    border-left: 5px solid !important;
  }
  .tablet\:vads-u-border--7px {
    border: 7px solid !important;
  }
  .tablet\:vads-u-border-top--7px {
    border-top: 7px solid !important;
  }
  .tablet\:vads-u-border-right--7px {
    border-right: 7px solid !important;
  }
  .tablet\:vads-u-border-bottom--7px {
    border-bottom: 7px solid !important;
  }
  .tablet\:vads-u-border-left--7px {
    border-left: 7px solid !important;
  }
  .tablet\:vads-u-border--10px {
    border: 10px solid !important;
  }
  .tablet\:vads-u-border-top--10px {
    border-top: 10px solid !important;
  }
  .tablet\:vads-u-border-right--10px {
    border-right: 10px solid !important;
  }
  .tablet\:vads-u-border-bottom--10px {
    border-bottom: 10px solid !important;
  }
  .tablet\:vads-u-border-left--10px {
    border-left: 10px solid !important;
  }
  .tablet\:vads-u-display--block {
    display: block !important;
  }
  .tablet\:vads-u-display--inline {
    display: inline !important;
  }
  .tablet\:vads-u-display--inline-block {
    display: inline-block !important;
  }
  .tablet\:vads-u-display--flex {
    display: flex !important;
  }
  .tablet\:vads-u-display--none {
    display: none !important;
  }
  .tablet\:vads-u-flex--1 {
    flex: 1 0 0% !important;
  }
  .tablet\:vads-u-flex--2 {
    flex: 2 0 0% !important;
  }
  .tablet\:vads-u-flex--3 {
    flex: 3 0 0% !important;
  }
  .tablet\:vads-u-flex--4 {
    flex: 4 0 0% !important;
  }
  .tablet\:vads-u-flex--auto {
    flex: 0 0 auto !important;
  }
  .tablet\:vads-u-flex--fill {
    flex: 1 0 0% !important;
  }
  .tablet\:vads-u-flex-direction--column {
    flex-direction: column !important;
  }
  .tablet\:vads-u-flex-direction--row {
    flex-direction: row !important;
  }
  .tablet\:vads-u-flex-direction--column-reverse {
    flex-direction: column-reverse !important;
  }
  .tablet\:vads-u-flex-direction--row-reverse {
    flex-direction: row-reverse !important;
  }
  .tablet\:vads-u-flex-wrap--nowrap {
    flex-wrap: nowrap !important;
  }
  .tablet\:vads-u-flex-wrap--wrap {
    flex-wrap: wrap !important;
  }
  .tablet\:vads-u-flex-wrap--wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .tablet\:vads-u-align-items--stretch {
    align-items: stretch !important;
  }
  .tablet\:vads-u-align-items--flex-start {
    align-items: flex-start !important;
  }
  .tablet\:vads-u-align-items--flex-end {
    align-items: flex-end !important;
  }
  .tablet\:vads-u-align-items--center {
    align-items: center !important;
  }
  .tablet\:vads-u-align-items--baseline {
    align-items: baseline !important;
  }
  .tablet\:vads-u-justify-content--flex-start {
    justify-content: flex-start !important;
  }
  .tablet\:vads-u-justify-content--flex-end {
    justify-content: flex-end !important;
  }
  .tablet\:vads-u-justify-content--center {
    justify-content: center !important;
  }
  .tablet\:vads-u-justify-content--space-between {
    justify-content: space-between !important;
  }
  .tablet\:vads-u-justify-content--space-around {
    justify-content: space-around !important;
  }
  .tablet\:vads-u-justify-content--space-evenly {
    justify-content: space-evenly !important;
  }
  .tablet\:vads-u-align-content--flex-start {
    align-content: flex-start !important;
  }
  .tablet\:vads-u-align-content--flex-end {
    align-content: flex-end !important;
  }
  .tablet\:vads-u-align-content--center {
    align-content: center !important;
  }
  .tablet\:vads-u-align-content--space-between {
    align-content: space-between !important;
  }
  .tablet\:vads-u-align-content--space-around {
    align-content: space-around !important;
  }
  .tablet\:vads-u-align-content--stretch {
    align-content: stretch !important;
  }
  .tablet\:vads-u-align-self--auto {
    align-self: auto !important;
  }
  .tablet\:vads-u-align-self--flex-start {
    align-self: flex-start !important;
  }
  .tablet\:vads-u-align-self--flex-end {
    align-self: flex-end !important;
  }
  .tablet\:vads-u-align-self--center {
    align-self: center !important;
  }
  .tablet\:vads-u-align-self--baseline {
    align-self: baseline !important;
  }
  .tablet\:vads-u-align-self--stretch {
    align-self: stretch !important;
  }
  .tablet\:vads-u-order--1 {
    order: 1 !important;
  }
  .tablet\:vads-u-order--2 {
    order: 2 !important;
  }
  .tablet\:vads-u-order--3 {
    order: 3 !important;
  }
  .tablet\:vads-u-order--4 {
    order: 4 !important;
  }
  .tablet\:vads-u-order--first {
    order: -1 !important;
  }
  .tablet\:vads-u-order--last {
    order: 999 !important;
  }
  .tablet\:vads-u-order--initial {
    order: 0 !important;
  }
  .tablet\:vads-u-font-size--source-sans-normalized {
    font-size: 1.063rem !important;
  }
  .tablet\:vads-u-font-size--sm {
    font-size: 0.938rem !important;
  }
  .tablet\:vads-u-font-size--base {
    font-size: 1rem !important;
  }
  .tablet\:vads-u-font-size--md {
    font-size: 1.063rem !important;
  }
  .tablet\:vads-u-font-size--lg {
    font-size: 1.25rem !important;
  }
  .tablet\:vads-u-font-size--xl {
    font-size: 1.875rem !important;
  }
  .tablet\:vads-u-font-size--2xl {
    font-size: 2.5rem !important;
  }
  .tablet\:vads-u-font-size--h1 {
    font-size: 2.5rem !important;
  }
  .tablet\:vads-u-font-size--h2 {
    font-size: 1.875rem !important;
  }
  .tablet\:vads-u-font-size--h3 {
    font-size: 1.25rem !important;
  }
  .tablet\:vads-u-font-size--h4 {
    font-size: 1.063rem !important;
  }
  .tablet\:vads-u-font-size--h5 {
    font-size: 0.938rem !important;
  }
  .tablet\:vads-u-font-size--h6 {
    font-size: 0.938rem !important;
  }
  .tablet\:vads-u-height--0 {
    height: 0px !important;
  }
  .tablet\:vads-u-height--full {
    height: 100% !important;
  }
  .tablet\:vads-u-height--auto {
    height: auto !important;
  }
  .tablet\:vads-u-height--viewport {
    height: 100vh !important;
  }
  .tablet\:vads-u-min-height--none {
    min-height: none !important;
  }
  .tablet\:vads-u-min-height--viewport {
    min-height: 100vh !important;
  }
  .tablet\:vads-u-max-height--none {
    max-height: none !important;
  }
  .tablet\:vads-u-max-height--viewport {
    max-height: 100vh !important;
  }
  .tablet\:vads-u-line-height--1 {
    line-height: 1 !important;
  }
  .tablet\:vads-u-line-height--2 {
    line-height: 1.15 !important;
  }
  .tablet\:vads-u-line-height--3 {
    line-height: 1.35 !important;
  }
  .tablet\:vads-u-line-height--4 {
    line-height: 1.5 !important;
  }
  .tablet\:vads-u-line-height--5 {
    line-height: 1.62 !important;
  }
  .tablet\:vads-u-line-height--6 {
    line-height: 1.75 !important;
  }
  .tablet\:vads-u-margin--0 {
    margin: 0 !important;
  }
  .tablet\:vads-u-margin--1px {
    margin: 1px !important;
  }
  .tablet\:vads-u-margin--0p25 {
    margin: 2px !important;
  }
  .tablet\:vads-u-margin--0p5 {
    margin: 0.25rem !important;
  }
  .tablet\:vads-u-margin--1 {
    margin: 0.5rem !important;
  }
  .tablet\:vads-u-margin--1p5 {
    margin: 0.75rem !important;
  }
  .tablet\:vads-u-margin--2 {
    margin: 1rem !important;
  }
  .tablet\:vads-u-margin--2p5 {
    margin: 1.25rem !important;
  }
  .tablet\:vads-u-margin--3 {
    margin: 1.5rem !important;
  }
  .tablet\:vads-u-margin--4 {
    margin: 2rem !important;
  }
  .tablet\:vads-u-margin--5 {
    margin: 2.5rem !important;
  }
  .tablet\:vads-u-margin--6 {
    margin: 3rem !important;
  }
  .tablet\:vads-u-margin--7 {
    margin: 3.5rem !important;
  }
  .tablet\:vads-u-margin--8 {
    margin: 4rem !important;
  }
  .tablet\:vads-u-margin--9 {
    margin: 4.5rem !important;
  }
  .tablet\:vads-u-margin-y--0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .tablet\:vads-u-margin-top--0 {
    margin-top: 0 !important;
  }
  .tablet\:vads-u-margin-bottom--0 {
    margin-bottom: 0 !important;
  }
  .tablet\:vads-u-margin-y--1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .tablet\:vads-u-margin-top--1px {
    margin-top: 1px !important;
  }
  .tablet\:vads-u-margin-bottom--1px {
    margin-bottom: 1px !important;
  }
  .tablet\:vads-u-margin-y--0p25 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .tablet\:vads-u-margin-top--0p25 {
    margin-top: 2px !important;
  }
  .tablet\:vads-u-margin-bottom--0p25 {
    margin-bottom: 2px !important;
  }
  .tablet\:vads-u-margin-y--0p5 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .tablet\:vads-u-margin-top--0p5 {
    margin-top: 0.25rem !important;
  }
  .tablet\:vads-u-margin-bottom--0p5 {
    margin-bottom: 0.25rem !important;
  }
  .tablet\:vads-u-margin-y--1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .tablet\:vads-u-margin-top--1 {
    margin-top: 0.5rem !important;
  }
  .tablet\:vads-u-margin-bottom--1 {
    margin-bottom: 0.5rem !important;
  }
  .tablet\:vads-u-margin-y--1p5 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .tablet\:vads-u-margin-top--1p5 {
    margin-top: 0.75rem !important;
  }
  .tablet\:vads-u-margin-bottom--1p5 {
    margin-bottom: 0.75rem !important;
  }
  .tablet\:vads-u-margin-y--2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .tablet\:vads-u-margin-top--2 {
    margin-top: 1rem !important;
  }
  .tablet\:vads-u-margin-bottom--2 {
    margin-bottom: 1rem !important;
  }
  .tablet\:vads-u-margin-y--2p5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .tablet\:vads-u-margin-top--2p5 {
    margin-top: 1.25rem !important;
  }
  .tablet\:vads-u-margin-bottom--2p5 {
    margin-bottom: 1.25rem !important;
  }
  .tablet\:vads-u-margin-y--3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .tablet\:vads-u-margin-top--3 {
    margin-top: 1.5rem !important;
  }
  .tablet\:vads-u-margin-bottom--3 {
    margin-bottom: 1.5rem !important;
  }
  .tablet\:vads-u-margin-y--4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .tablet\:vads-u-margin-top--4 {
    margin-top: 2rem !important;
  }
  .tablet\:vads-u-margin-bottom--4 {
    margin-bottom: 2rem !important;
  }
  .tablet\:vads-u-margin-y--5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .tablet\:vads-u-margin-top--5 {
    margin-top: 2.5rem !important;
  }
  .tablet\:vads-u-margin-bottom--5 {
    margin-bottom: 2.5rem !important;
  }
  .tablet\:vads-u-margin-y--6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .tablet\:vads-u-margin-top--6 {
    margin-top: 3rem !important;
  }
  .tablet\:vads-u-margin-bottom--6 {
    margin-bottom: 3rem !important;
  }
  .tablet\:vads-u-margin-y--7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .tablet\:vads-u-margin-top--7 {
    margin-top: 3.5rem !important;
  }
  .tablet\:vads-u-margin-bottom--7 {
    margin-bottom: 3.5rem !important;
  }
  .tablet\:vads-u-margin-y--8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .tablet\:vads-u-margin-top--8 {
    margin-top: 4rem !important;
  }
  .tablet\:vads-u-margin-bottom--8 {
    margin-bottom: 4rem !important;
  }
  .tablet\:vads-u-margin-y--9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .tablet\:vads-u-margin-top--9 {
    margin-top: 4.5rem !important;
  }
  .tablet\:vads-u-margin-bottom--9 {
    margin-bottom: 4.5rem !important;
  }
  .tablet\:vads-u-margin-y--neg9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .tablet\:vads-u-margin-top--neg9 {
    margin-top: -4.5rem !important;
  }
  .tablet\:vads-u-margin-bottom--neg9 {
    margin-bottom: -4.5rem !important;
  }
  .tablet\:vads-u-margin-y--neg8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .tablet\:vads-u-margin-top--neg8 {
    margin-top: -4rem !important;
  }
  .tablet\:vads-u-margin-bottom--neg8 {
    margin-bottom: -4rem !important;
  }
  .tablet\:vads-u-margin-y--neg7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .tablet\:vads-u-margin-top--neg7 {
    margin-top: -3.5rem !important;
  }
  .tablet\:vads-u-margin-bottom--neg7 {
    margin-bottom: -3.5rem !important;
  }
  .tablet\:vads-u-margin-y--neg6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .tablet\:vads-u-margin-top--neg6 {
    margin-top: -3rem !important;
  }
  .tablet\:vads-u-margin-bottom--neg6 {
    margin-bottom: -3rem !important;
  }
  .tablet\:vads-u-margin-y--neg5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .tablet\:vads-u-margin-top--neg5 {
    margin-top: -2.5rem !important;
  }
  .tablet\:vads-u-margin-bottom--neg5 {
    margin-bottom: -2.5rem !important;
  }
  .tablet\:vads-u-margin-y--neg4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .tablet\:vads-u-margin-top--neg4 {
    margin-top: -2rem !important;
  }
  .tablet\:vads-u-margin-bottom--neg4 {
    margin-bottom: -2rem !important;
  }
  .tablet\:vads-u-margin-y--neg3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .tablet\:vads-u-margin-top--neg3 {
    margin-top: -1.5rem !important;
  }
  .tablet\:vads-u-margin-bottom--neg3 {
    margin-bottom: -1.5rem !important;
  }
  .tablet\:vads-u-margin-y--neg2p5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .tablet\:vads-u-margin-top--neg2p5 {
    margin-top: -1.25rem !important;
  }
  .tablet\:vads-u-margin-bottom--neg2p5 {
    margin-bottom: -1.25rem !important;
  }
  .tablet\:vads-u-margin-y--neg2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .tablet\:vads-u-margin-top--neg2 {
    margin-top: -1rem !important;
  }
  .tablet\:vads-u-margin-bottom--neg2 {
    margin-bottom: -1rem !important;
  }
  .tablet\:vads-u-margin-y--neg1p5 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .tablet\:vads-u-margin-top--neg1p5 {
    margin-top: -0.75rem !important;
  }
  .tablet\:vads-u-margin-bottom--neg1p5 {
    margin-bottom: -0.75rem !important;
  }
  .tablet\:vads-u-margin-y--neg1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .tablet\:vads-u-margin-top--neg1 {
    margin-top: -0.5rem !important;
  }
  .tablet\:vads-u-margin-bottom--neg1 {
    margin-bottom: -0.5rem !important;
  }
  .tablet\:vads-u-margin-y--neg0p5 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .tablet\:vads-u-margin-top--neg0p5 {
    margin-top: -0.25rem !important;
  }
  .tablet\:vads-u-margin-bottom--neg0p5 {
    margin-bottom: -0.25rem !important;
  }
  .tablet\:vads-u-margin-y--neg0p25 {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .tablet\:vads-u-margin-top--neg0p25 {
    margin-top: -2px !important;
  }
  .tablet\:vads-u-margin-bottom--neg0p25 {
    margin-bottom: -2px !important;
  }
  .tablet\:vads-u-margin-y--neg1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }
  .tablet\:vads-u-margin-top--neg1px {
    margin-top: -1px !important;
  }
  .tablet\:vads-u-margin-bottom--neg1px {
    margin-bottom: -1px !important;
  }
  .tablet\:vads-u-margin-x--0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .tablet\:vads-u-margin-right--0 {
    margin-right: 0 !important;
  }
  .tablet\:vads-u-margin-left--0 {
    margin-left: 0 !important;
  }
  .tablet\:vads-u-margin-x--1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .tablet\:vads-u-margin-right--1px {
    margin-right: 1px !important;
  }
  .tablet\:vads-u-margin-left--1px {
    margin-left: 1px !important;
  }
  .tablet\:vads-u-margin-x--0p25 {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .tablet\:vads-u-margin-right--0p25 {
    margin-right: 2px !important;
  }
  .tablet\:vads-u-margin-left--0p25 {
    margin-left: 2px !important;
  }
  .tablet\:vads-u-margin-x--0p5 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .tablet\:vads-u-margin-right--0p5 {
    margin-right: 0.25rem !important;
  }
  .tablet\:vads-u-margin-left--0p5 {
    margin-left: 0.25rem !important;
  }
  .tablet\:vads-u-margin-x--1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .tablet\:vads-u-margin-right--1 {
    margin-right: 0.5rem !important;
  }
  .tablet\:vads-u-margin-left--1 {
    margin-left: 0.5rem !important;
  }
  .tablet\:vads-u-margin-x--1p5 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .tablet\:vads-u-margin-right--1p5 {
    margin-right: 0.75rem !important;
  }
  .tablet\:vads-u-margin-left--1p5 {
    margin-left: 0.75rem !important;
  }
  .tablet\:vads-u-margin-x--2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .tablet\:vads-u-margin-right--2 {
    margin-right: 1rem !important;
  }
  .tablet\:vads-u-margin-left--2 {
    margin-left: 1rem !important;
  }
  .tablet\:vads-u-margin-x--2p5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .tablet\:vads-u-margin-right--2p5 {
    margin-right: 1.25rem !important;
  }
  .tablet\:vads-u-margin-left--2p5 {
    margin-left: 1.25rem !important;
  }
  .tablet\:vads-u-margin-x--3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .tablet\:vads-u-margin-right--3 {
    margin-right: 1.5rem !important;
  }
  .tablet\:vads-u-margin-left--3 {
    margin-left: 1.5rem !important;
  }
  .tablet\:vads-u-margin-x--4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .tablet\:vads-u-margin-right--4 {
    margin-right: 2rem !important;
  }
  .tablet\:vads-u-margin-left--4 {
    margin-left: 2rem !important;
  }
  .tablet\:vads-u-margin-x--5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .tablet\:vads-u-margin-right--5 {
    margin-right: 2.5rem !important;
  }
  .tablet\:vads-u-margin-left--5 {
    margin-left: 2.5rem !important;
  }
  .tablet\:vads-u-margin-x--6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .tablet\:vads-u-margin-right--6 {
    margin-right: 3rem !important;
  }
  .tablet\:vads-u-margin-left--6 {
    margin-left: 3rem !important;
  }
  .tablet\:vads-u-margin-x--7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .tablet\:vads-u-margin-right--7 {
    margin-right: 3.5rem !important;
  }
  .tablet\:vads-u-margin-left--7 {
    margin-left: 3.5rem !important;
  }
  .tablet\:vads-u-margin-x--8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .tablet\:vads-u-margin-right--8 {
    margin-right: 4rem !important;
  }
  .tablet\:vads-u-margin-left--8 {
    margin-left: 4rem !important;
  }
  .tablet\:vads-u-margin-x--9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .tablet\:vads-u-margin-right--9 {
    margin-right: 4.5rem !important;
  }
  .tablet\:vads-u-margin-left--9 {
    margin-left: 4.5rem !important;
  }
  .tablet\:vads-u-margin-x--neg9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .tablet\:vads-u-margin-right--neg9 {
    margin-right: -4.5rem !important;
  }
  .tablet\:vads-u-margin-left--neg9 {
    margin-left: -4.5rem !important;
  }
  .tablet\:vads-u-margin-x--neg8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .tablet\:vads-u-margin-right--neg8 {
    margin-right: -4rem !important;
  }
  .tablet\:vads-u-margin-left--neg8 {
    margin-left: -4rem !important;
  }
  .tablet\:vads-u-margin-x--neg7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .tablet\:vads-u-margin-right--neg7 {
    margin-right: -3.5rem !important;
  }
  .tablet\:vads-u-margin-left--neg7 {
    margin-left: -3.5rem !important;
  }
  .tablet\:vads-u-margin-x--neg6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .tablet\:vads-u-margin-right--neg6 {
    margin-right: -3rem !important;
  }
  .tablet\:vads-u-margin-left--neg6 {
    margin-left: -3rem !important;
  }
  .tablet\:vads-u-margin-x--neg5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .tablet\:vads-u-margin-right--neg5 {
    margin-right: -2.5rem !important;
  }
  .tablet\:vads-u-margin-left--neg5 {
    margin-left: -2.5rem !important;
  }
  .tablet\:vads-u-margin-x--neg4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .tablet\:vads-u-margin-right--neg4 {
    margin-right: -2rem !important;
  }
  .tablet\:vads-u-margin-left--neg4 {
    margin-left: -2rem !important;
  }
  .tablet\:vads-u-margin-x--neg3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .tablet\:vads-u-margin-right--neg3 {
    margin-right: -1.5rem !important;
  }
  .tablet\:vads-u-margin-left--neg3 {
    margin-left: -1.5rem !important;
  }
  .tablet\:vads-u-margin-x--neg2p5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .tablet\:vads-u-margin-right--neg2p5 {
    margin-right: -1.25rem !important;
  }
  .tablet\:vads-u-margin-left--neg2p5 {
    margin-left: -1.25rem !important;
  }
  .tablet\:vads-u-margin-x--neg2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .tablet\:vads-u-margin-right--neg2 {
    margin-right: -1rem !important;
  }
  .tablet\:vads-u-margin-left--neg2 {
    margin-left: -1rem !important;
  }
  .tablet\:vads-u-margin-x--neg1p5 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .tablet\:vads-u-margin-right--neg1p5 {
    margin-right: -0.75rem !important;
  }
  .tablet\:vads-u-margin-left--neg1p5 {
    margin-left: -0.75rem !important;
  }
  .tablet\:vads-u-margin-x--neg1 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .tablet\:vads-u-margin-right--neg1 {
    margin-right: -0.5rem !important;
  }
  .tablet\:vads-u-margin-left--neg1 {
    margin-left: -0.5rem !important;
  }
  .tablet\:vads-u-margin-x--neg0p5 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .tablet\:vads-u-margin-right--neg0p5 {
    margin-right: -0.25rem !important;
  }
  .tablet\:vads-u-margin-left--neg0p5 {
    margin-left: -0.25rem !important;
  }
  .tablet\:vads-u-margin-x--neg0p25 {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }
  .tablet\:vads-u-margin-right--neg0p25 {
    margin-right: -2px !important;
  }
  .tablet\:vads-u-margin-left--neg0p25 {
    margin-left: -2px !important;
  }
  .tablet\:vads-u-margin-x--neg1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }
  .tablet\:vads-u-margin-right--neg1px {
    margin-right: -1px !important;
  }
  .tablet\:vads-u-margin-left--neg1px {
    margin-left: -1px !important;
  }
  .tablet\:vads-u-margin-x--auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .tablet\:vads-u-margin-right--auto {
    margin-right: auto !important;
  }
  .tablet\:vads-u-margin-left--auto {
    margin-left: auto !important;
  }
  .tablet\:vads-u-measure--1 {
    max-width: 40ch !important;
  }
  .tablet\:vads-u-measure--2 {
    max-width: 60ch !important;
  }
  .tablet\:vads-u-measure--3 {
    max-width: 66ch !important;
  }
  .tablet\:vads-u-measure--4 {
    max-width: 72ch !important;
  }
  .tablet\:vads-u-measure--5 {
    max-width: 77ch !important;
  }
  .tablet\:vads-u-measure--none {
    max-width: none !important;
  }
  .tablet\:vads-u-padding--0 {
    padding: 0 !important;
  }
  .tablet\:vads-u-padding-top--0 {
    padding-top: 0 !important;
  }
  .tablet\:vads-u-padding-bottom--0 {
    padding-bottom: 0 !important;
  }
  .tablet\:vads-u-padding-right--0 {
    padding-right: 0 !important;
  }
  .tablet\:vads-u-padding-left--0 {
    padding-left: 0 !important;
  }
  .tablet\:vads-u-padding--1px {
    padding: 1px !important;
  }
  .tablet\:vads-u-padding-top--1px {
    padding-top: 1px !important;
  }
  .tablet\:vads-u-padding-bottom--1px {
    padding-bottom: 1px !important;
  }
  .tablet\:vads-u-padding-right--1px {
    padding-right: 1px !important;
  }
  .tablet\:vads-u-padding-left--1px {
    padding-left: 1px !important;
  }
  .tablet\:vads-u-padding--0p25 {
    padding: 2px !important;
  }
  .tablet\:vads-u-padding-top--0p25 {
    padding-top: 2px !important;
  }
  .tablet\:vads-u-padding-bottom--0p25 {
    padding-bottom: 2px !important;
  }
  .tablet\:vads-u-padding-right--0p25 {
    padding-right: 2px !important;
  }
  .tablet\:vads-u-padding-left--0p25 {
    padding-left: 2px !important;
  }
  .tablet\:vads-u-padding--0p5 {
    padding: 0.25rem !important;
  }
  .tablet\:vads-u-padding-top--0p5 {
    padding-top: 0.25rem !important;
  }
  .tablet\:vads-u-padding-bottom--0p5 {
    padding-bottom: 0.25rem !important;
  }
  .tablet\:vads-u-padding-right--0p5 {
    padding-right: 0.25rem !important;
  }
  .tablet\:vads-u-padding-left--0p5 {
    padding-left: 0.25rem !important;
  }
  .tablet\:vads-u-padding--1 {
    padding: 0.5rem !important;
  }
  .tablet\:vads-u-padding-top--1 {
    padding-top: 0.5rem !important;
  }
  .tablet\:vads-u-padding-bottom--1 {
    padding-bottom: 0.5rem !important;
  }
  .tablet\:vads-u-padding-right--1 {
    padding-right: 0.5rem !important;
  }
  .tablet\:vads-u-padding-left--1 {
    padding-left: 0.5rem !important;
  }
  .tablet\:vads-u-padding--1p5 {
    padding: 0.75rem !important;
  }
  .tablet\:vads-u-padding-top--1p5 {
    padding-top: 0.75rem !important;
  }
  .tablet\:vads-u-padding-bottom--1p5 {
    padding-bottom: 0.75rem !important;
  }
  .tablet\:vads-u-padding-right--1p5 {
    padding-right: 0.75rem !important;
  }
  .tablet\:vads-u-padding-left--1p5 {
    padding-left: 0.75rem !important;
  }
  .tablet\:vads-u-padding--2 {
    padding: 1rem !important;
  }
  .tablet\:vads-u-padding-top--2 {
    padding-top: 1rem !important;
  }
  .tablet\:vads-u-padding-bottom--2 {
    padding-bottom: 1rem !important;
  }
  .tablet\:vads-u-padding-right--2 {
    padding-right: 1rem !important;
  }
  .tablet\:vads-u-padding-left--2 {
    padding-left: 1rem !important;
  }
  .tablet\:vads-u-padding--2p5 {
    padding: 1.25rem !important;
  }
  .tablet\:vads-u-padding-top--2p5 {
    padding-top: 1.25rem !important;
  }
  .tablet\:vads-u-padding-bottom--2p5 {
    padding-bottom: 1.25rem !important;
  }
  .tablet\:vads-u-padding-right--2p5 {
    padding-right: 1.25rem !important;
  }
  .tablet\:vads-u-padding-left--2p5 {
    padding-left: 1.25rem !important;
  }
  .tablet\:vads-u-padding--3 {
    padding: 1.5rem !important;
  }
  .tablet\:vads-u-padding-top--3 {
    padding-top: 1.5rem !important;
  }
  .tablet\:vads-u-padding-bottom--3 {
    padding-bottom: 1.5rem !important;
  }
  .tablet\:vads-u-padding-right--3 {
    padding-right: 1.5rem !important;
  }
  .tablet\:vads-u-padding-left--3 {
    padding-left: 1.5rem !important;
  }
  .tablet\:vads-u-padding--4 {
    padding: 2rem !important;
  }
  .tablet\:vads-u-padding-top--4 {
    padding-top: 2rem !important;
  }
  .tablet\:vads-u-padding-bottom--4 {
    padding-bottom: 2rem !important;
  }
  .tablet\:vads-u-padding-right--4 {
    padding-right: 2rem !important;
  }
  .tablet\:vads-u-padding-left--4 {
    padding-left: 2rem !important;
  }
  .tablet\:vads-u-padding--5 {
    padding: 2.5rem !important;
  }
  .tablet\:vads-u-padding-top--5 {
    padding-top: 2.5rem !important;
  }
  .tablet\:vads-u-padding-bottom--5 {
    padding-bottom: 2.5rem !important;
  }
  .tablet\:vads-u-padding-right--5 {
    padding-right: 2.5rem !important;
  }
  .tablet\:vads-u-padding-left--5 {
    padding-left: 2.5rem !important;
  }
  .tablet\:vads-u-padding--6 {
    padding: 3rem !important;
  }
  .tablet\:vads-u-padding-top--6 {
    padding-top: 3rem !important;
  }
  .tablet\:vads-u-padding-bottom--6 {
    padding-bottom: 3rem !important;
  }
  .tablet\:vads-u-padding-right--6 {
    padding-right: 3rem !important;
  }
  .tablet\:vads-u-padding-left--6 {
    padding-left: 3rem !important;
  }
  .tablet\:vads-u-padding--7 {
    padding: 3.5rem !important;
  }
  .tablet\:vads-u-padding-top--7 {
    padding-top: 3.5rem !important;
  }
  .tablet\:vads-u-padding-bottom--7 {
    padding-bottom: 3.5rem !important;
  }
  .tablet\:vads-u-padding-right--7 {
    padding-right: 3.5rem !important;
  }
  .tablet\:vads-u-padding-left--7 {
    padding-left: 3.5rem !important;
  }
  .tablet\:vads-u-padding--8 {
    padding: 4rem !important;
  }
  .tablet\:vads-u-padding-top--8 {
    padding-top: 4rem !important;
  }
  .tablet\:vads-u-padding-bottom--8 {
    padding-bottom: 4rem !important;
  }
  .tablet\:vads-u-padding-right--8 {
    padding-right: 4rem !important;
  }
  .tablet\:vads-u-padding-left--8 {
    padding-left: 4rem !important;
  }
  .tablet\:vads-u-padding--9 {
    padding: 4.5rem !important;
  }
  .tablet\:vads-u-padding-top--9 {
    padding-top: 4.5rem !important;
  }
  .tablet\:vads-u-padding-bottom--9 {
    padding-bottom: 4.5rem !important;
  }
  .tablet\:vads-u-padding-right--9 {
    padding-right: 4.5rem !important;
  }
  .tablet\:vads-u-padding-left--9 {
    padding-left: 4.5rem !important;
  }
  .tablet\:vads-u-padding-y--0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .tablet\:vads-u-padding-y--1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .tablet\:vads-u-padding-y--0p25 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .tablet\:vads-u-padding-y--0p5 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .tablet\:vads-u-padding-y--1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .tablet\:vads-u-padding-y--1p5 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .tablet\:vads-u-padding-y--2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .tablet\:vads-u-padding-y--2p5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .tablet\:vads-u-padding-y--3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .tablet\:vads-u-padding-y--4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .tablet\:vads-u-padding-y--5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .tablet\:vads-u-padding-y--6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .tablet\:vads-u-padding-y--7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .tablet\:vads-u-padding-y--8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .tablet\:vads-u-padding-y--9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .tablet\:vads-u-padding-x--0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .tablet\:vads-u-padding-x--1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .tablet\:vads-u-padding-x--0p25 {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .tablet\:vads-u-padding-x--0p5 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .tablet\:vads-u-padding-x--1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .tablet\:vads-u-padding-x--1p5 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .tablet\:vads-u-padding-x--2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .tablet\:vads-u-padding-x--2p5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .tablet\:vads-u-padding-x--3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .tablet\:vads-u-padding-x--4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .tablet\:vads-u-padding-x--5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .tablet\:vads-u-padding-x--6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .tablet\:vads-u-padding-x--7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .tablet\:vads-u-padding-x--8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .tablet\:vads-u-padding-x--9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .tablet\:vads-u-position--absolute {
    position: absolute !important;
  }
  .tablet\:vads-u-position--fixed {
    position: fixed !important;
  }
  .tablet\:vads-u-position--relative {
    position: relative !important;
  }
  .tablet\:vads-u-text-align--left {
    text-align: left !important;
  }
  .tablet\:vads-u-text-align--center {
    text-align: center !important;
  }
  .tablet\:vads-u-text-align--right {
    text-align: right !important;
  }
  .tablet\:vads-u-text-align--justify {
    text-align: justify !important;
  }
  .tablet\:vads-u-visibility--hidden {
    visibility: hidden !important;
  }
  .tablet\:vads-u-visibility--visible {
    visibility: visible !important;
  }
  .tablet\:vads-u-width--0 {
    width: 0px !important;
  }
  .tablet\:vads-u-width--full {
    width: 100% !important;
  }
  .tablet\:vads-u-width--auto {
    width: auto !important;
  }
  .tablet\:vads-u-min-width--none {
    min-width: none !important;
  }
  .tablet\:vads-u-max-width--none {
    max-width: none !important;
  }
  .tablet\:vads-u-max-width--100 {
    max-width: 100% !important;
  }
}
@media all and (min-width: 64em) {
  .desktop\:vads-u-border--0 {
    border: 0 !important;
  }
  .desktop\:vads-u-border-top--0 {
    border-top: 0 !important;
  }
  .desktop\:vads-u-border-right--0 {
    border-right: 0 !important;
  }
  .desktop\:vads-u-border-bottom--0 {
    border-bottom: 0 !important;
  }
  .desktop\:vads-u-border-left--0 {
    border-left: 0 !important;
  }
  .desktop\:vads-u-border--1px {
    border: 1px solid !important;
  }
  .desktop\:vads-u-border-top--1px {
    border-top: 1px solid !important;
  }
  .desktop\:vads-u-border-right--1px {
    border-right: 1px solid !important;
  }
  .desktop\:vads-u-border-bottom--1px {
    border-bottom: 1px solid !important;
  }
  .desktop\:vads-u-border-left--1px {
    border-left: 1px solid !important;
  }
  .desktop\:vads-u-border--2px {
    border: 2px solid !important;
  }
  .desktop\:vads-u-border-top--2px {
    border-top: 2px solid !important;
  }
  .desktop\:vads-u-border-right--2px {
    border-right: 2px solid !important;
  }
  .desktop\:vads-u-border-bottom--2px {
    border-bottom: 2px solid !important;
  }
  .desktop\:vads-u-border-left--2px {
    border-left: 2px solid !important;
  }
  .desktop\:vads-u-border--3px {
    border: 3px solid !important;
  }
  .desktop\:vads-u-border-top--3px {
    border-top: 3px solid !important;
  }
  .desktop\:vads-u-border-right--3px {
    border-right: 3px solid !important;
  }
  .desktop\:vads-u-border-bottom--3px {
    border-bottom: 3px solid !important;
  }
  .desktop\:vads-u-border-left--3px {
    border-left: 3px solid !important;
  }
  .desktop\:vads-u-border--4px {
    border: 4px solid !important;
  }
  .desktop\:vads-u-border-top--4px {
    border-top: 4px solid !important;
  }
  .desktop\:vads-u-border-right--4px {
    border-right: 4px solid !important;
  }
  .desktop\:vads-u-border-bottom--4px {
    border-bottom: 4px solid !important;
  }
  .desktop\:vads-u-border-left--4px {
    border-left: 4px solid !important;
  }
  .desktop\:vads-u-border--5px {
    border: 5px solid !important;
  }
  .desktop\:vads-u-border-top--5px {
    border-top: 5px solid !important;
  }
  .desktop\:vads-u-border-right--5px {
    border-right: 5px solid !important;
  }
  .desktop\:vads-u-border-bottom--5px {
    border-bottom: 5px solid !important;
  }
  .desktop\:vads-u-border-left--5px {
    border-left: 5px solid !important;
  }
  .desktop\:vads-u-border--7px {
    border: 7px solid !important;
  }
  .desktop\:vads-u-border-top--7px {
    border-top: 7px solid !important;
  }
  .desktop\:vads-u-border-right--7px {
    border-right: 7px solid !important;
  }
  .desktop\:vads-u-border-bottom--7px {
    border-bottom: 7px solid !important;
  }
  .desktop\:vads-u-border-left--7px {
    border-left: 7px solid !important;
  }
  .desktop\:vads-u-border--10px {
    border: 10px solid !important;
  }
  .desktop\:vads-u-border-top--10px {
    border-top: 10px solid !important;
  }
  .desktop\:vads-u-border-right--10px {
    border-right: 10px solid !important;
  }
  .desktop\:vads-u-border-bottom--10px {
    border-bottom: 10px solid !important;
  }
  .desktop\:vads-u-border-left--10px {
    border-left: 10px solid !important;
  }
  .desktop\:vads-u-display--block {
    display: block !important;
  }
  .desktop\:vads-u-display--inline {
    display: inline !important;
  }
  .desktop\:vads-u-display--inline-block {
    display: inline-block !important;
  }
  .desktop\:vads-u-display--flex {
    display: flex !important;
  }
  .desktop\:vads-u-display--none {
    display: none !important;
  }
  .desktop\:vads-u-flex--1 {
    flex: 1 0 0% !important;
  }
  .desktop\:vads-u-flex--2 {
    flex: 2 0 0% !important;
  }
  .desktop\:vads-u-flex--3 {
    flex: 3 0 0% !important;
  }
  .desktop\:vads-u-flex--4 {
    flex: 4 0 0% !important;
  }
  .desktop\:vads-u-flex--auto {
    flex: 0 0 auto !important;
  }
  .desktop\:vads-u-flex--fill {
    flex: 1 0 0% !important;
  }
  .desktop\:vads-u-flex-direction--column {
    flex-direction: column !important;
  }
  .desktop\:vads-u-flex-direction--row {
    flex-direction: row !important;
  }
  .desktop\:vads-u-flex-direction--column-reverse {
    flex-direction: column-reverse !important;
  }
  .desktop\:vads-u-flex-direction--row-reverse {
    flex-direction: row-reverse !important;
  }
  .desktop\:vads-u-flex-wrap--nowrap {
    flex-wrap: nowrap !important;
  }
  .desktop\:vads-u-flex-wrap--wrap {
    flex-wrap: wrap !important;
  }
  .desktop\:vads-u-flex-wrap--wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .desktop\:vads-u-align-items--stretch {
    align-items: stretch !important;
  }
  .desktop\:vads-u-align-items--flex-start {
    align-items: flex-start !important;
  }
  .desktop\:vads-u-align-items--flex-end {
    align-items: flex-end !important;
  }
  .desktop\:vads-u-align-items--center {
    align-items: center !important;
  }
  .desktop\:vads-u-align-items--baseline {
    align-items: baseline !important;
  }
  .desktop\:vads-u-justify-content--flex-start {
    justify-content: flex-start !important;
  }
  .desktop\:vads-u-justify-content--flex-end {
    justify-content: flex-end !important;
  }
  .desktop\:vads-u-justify-content--center {
    justify-content: center !important;
  }
  .desktop\:vads-u-justify-content--space-between {
    justify-content: space-between !important;
  }
  .desktop\:vads-u-justify-content--space-around {
    justify-content: space-around !important;
  }
  .desktop\:vads-u-justify-content--space-evenly {
    justify-content: space-evenly !important;
  }
  .desktop\:vads-u-align-content--flex-start {
    align-content: flex-start !important;
  }
  .desktop\:vads-u-align-content--flex-end {
    align-content: flex-end !important;
  }
  .desktop\:vads-u-align-content--center {
    align-content: center !important;
  }
  .desktop\:vads-u-align-content--space-between {
    align-content: space-between !important;
  }
  .desktop\:vads-u-align-content--space-around {
    align-content: space-around !important;
  }
  .desktop\:vads-u-align-content--stretch {
    align-content: stretch !important;
  }
  .desktop\:vads-u-align-self--auto {
    align-self: auto !important;
  }
  .desktop\:vads-u-align-self--flex-start {
    align-self: flex-start !important;
  }
  .desktop\:vads-u-align-self--flex-end {
    align-self: flex-end !important;
  }
  .desktop\:vads-u-align-self--center {
    align-self: center !important;
  }
  .desktop\:vads-u-align-self--baseline {
    align-self: baseline !important;
  }
  .desktop\:vads-u-align-self--stretch {
    align-self: stretch !important;
  }
  .desktop\:vads-u-order--1 {
    order: 1 !important;
  }
  .desktop\:vads-u-order--2 {
    order: 2 !important;
  }
  .desktop\:vads-u-order--3 {
    order: 3 !important;
  }
  .desktop\:vads-u-order--4 {
    order: 4 !important;
  }
  .desktop\:vads-u-order--first {
    order: -1 !important;
  }
  .desktop\:vads-u-order--last {
    order: 999 !important;
  }
  .desktop\:vads-u-order--initial {
    order: 0 !important;
  }
  .desktop\:vads-u-font-size--source-sans-normalized {
    font-size: 1.063rem !important;
  }
  .desktop\:vads-u-font-size--sm {
    font-size: 0.938rem !important;
  }
  .desktop\:vads-u-font-size--base {
    font-size: 1rem !important;
  }
  .desktop\:vads-u-font-size--md {
    font-size: 1.063rem !important;
  }
  .desktop\:vads-u-font-size--lg {
    font-size: 1.25rem !important;
  }
  .desktop\:vads-u-font-size--xl {
    font-size: 1.875rem !important;
  }
  .desktop\:vads-u-font-size--2xl {
    font-size: 2.5rem !important;
  }
  .desktop\:vads-u-font-size--h1 {
    font-size: 2.5rem !important;
  }
  .desktop\:vads-u-font-size--h2 {
    font-size: 1.875rem !important;
  }
  .desktop\:vads-u-font-size--h3 {
    font-size: 1.25rem !important;
  }
  .desktop\:vads-u-font-size--h4 {
    font-size: 1.063rem !important;
  }
  .desktop\:vads-u-font-size--h5 {
    font-size: 0.938rem !important;
  }
  .desktop\:vads-u-font-size--h6 {
    font-size: 0.938rem !important;
  }
  .desktop\:vads-u-height--0 {
    height: 0px !important;
  }
  .desktop\:vads-u-height--full {
    height: 100% !important;
  }
  .desktop\:vads-u-height--auto {
    height: auto !important;
  }
  .desktop\:vads-u-height--viewport {
    height: 100vh !important;
  }
  .desktop\:vads-u-min-height--none {
    min-height: none !important;
  }
  .desktop\:vads-u-min-height--viewport {
    min-height: 100vh !important;
  }
  .desktop\:vads-u-max-height--none {
    max-height: none !important;
  }
  .desktop\:vads-u-max-height--viewport {
    max-height: 100vh !important;
  }
  .desktop\:vads-u-line-height--1 {
    line-height: 1 !important;
  }
  .desktop\:vads-u-line-height--2 {
    line-height: 1.15 !important;
  }
  .desktop\:vads-u-line-height--3 {
    line-height: 1.35 !important;
  }
  .desktop\:vads-u-line-height--4 {
    line-height: 1.5 !important;
  }
  .desktop\:vads-u-line-height--5 {
    line-height: 1.62 !important;
  }
  .desktop\:vads-u-line-height--6 {
    line-height: 1.75 !important;
  }
  .desktop\:vads-u-margin--0 {
    margin: 0 !important;
  }
  .desktop\:vads-u-margin--1px {
    margin: 1px !important;
  }
  .desktop\:vads-u-margin--0p25 {
    margin: 2px !important;
  }
  .desktop\:vads-u-margin--0p5 {
    margin: 0.25rem !important;
  }
  .desktop\:vads-u-margin--1 {
    margin: 0.5rem !important;
  }
  .desktop\:vads-u-margin--1p5 {
    margin: 0.75rem !important;
  }
  .desktop\:vads-u-margin--2 {
    margin: 1rem !important;
  }
  .desktop\:vads-u-margin--2p5 {
    margin: 1.25rem !important;
  }
  .desktop\:vads-u-margin--3 {
    margin: 1.5rem !important;
  }
  .desktop\:vads-u-margin--4 {
    margin: 2rem !important;
  }
  .desktop\:vads-u-margin--5 {
    margin: 2.5rem !important;
  }
  .desktop\:vads-u-margin--6 {
    margin: 3rem !important;
  }
  .desktop\:vads-u-margin--7 {
    margin: 3.5rem !important;
  }
  .desktop\:vads-u-margin--8 {
    margin: 4rem !important;
  }
  .desktop\:vads-u-margin--9 {
    margin: 4.5rem !important;
  }
  .desktop\:vads-u-margin-y--0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .desktop\:vads-u-margin-top--0 {
    margin-top: 0 !important;
  }
  .desktop\:vads-u-margin-bottom--0 {
    margin-bottom: 0 !important;
  }
  .desktop\:vads-u-margin-y--1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .desktop\:vads-u-margin-top--1px {
    margin-top: 1px !important;
  }
  .desktop\:vads-u-margin-bottom--1px {
    margin-bottom: 1px !important;
  }
  .desktop\:vads-u-margin-y--0p25 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .desktop\:vads-u-margin-top--0p25 {
    margin-top: 2px !important;
  }
  .desktop\:vads-u-margin-bottom--0p25 {
    margin-bottom: 2px !important;
  }
  .desktop\:vads-u-margin-y--0p5 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .desktop\:vads-u-margin-top--0p5 {
    margin-top: 0.25rem !important;
  }
  .desktop\:vads-u-margin-bottom--0p5 {
    margin-bottom: 0.25rem !important;
  }
  .desktop\:vads-u-margin-y--1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .desktop\:vads-u-margin-top--1 {
    margin-top: 0.5rem !important;
  }
  .desktop\:vads-u-margin-bottom--1 {
    margin-bottom: 0.5rem !important;
  }
  .desktop\:vads-u-margin-y--1p5 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .desktop\:vads-u-margin-top--1p5 {
    margin-top: 0.75rem !important;
  }
  .desktop\:vads-u-margin-bottom--1p5 {
    margin-bottom: 0.75rem !important;
  }
  .desktop\:vads-u-margin-y--2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .desktop\:vads-u-margin-top--2 {
    margin-top: 1rem !important;
  }
  .desktop\:vads-u-margin-bottom--2 {
    margin-bottom: 1rem !important;
  }
  .desktop\:vads-u-margin-y--2p5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .desktop\:vads-u-margin-top--2p5 {
    margin-top: 1.25rem !important;
  }
  .desktop\:vads-u-margin-bottom--2p5 {
    margin-bottom: 1.25rem !important;
  }
  .desktop\:vads-u-margin-y--3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .desktop\:vads-u-margin-top--3 {
    margin-top: 1.5rem !important;
  }
  .desktop\:vads-u-margin-bottom--3 {
    margin-bottom: 1.5rem !important;
  }
  .desktop\:vads-u-margin-y--4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .desktop\:vads-u-margin-top--4 {
    margin-top: 2rem !important;
  }
  .desktop\:vads-u-margin-bottom--4 {
    margin-bottom: 2rem !important;
  }
  .desktop\:vads-u-margin-y--5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .desktop\:vads-u-margin-top--5 {
    margin-top: 2.5rem !important;
  }
  .desktop\:vads-u-margin-bottom--5 {
    margin-bottom: 2.5rem !important;
  }
  .desktop\:vads-u-margin-y--6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .desktop\:vads-u-margin-top--6 {
    margin-top: 3rem !important;
  }
  .desktop\:vads-u-margin-bottom--6 {
    margin-bottom: 3rem !important;
  }
  .desktop\:vads-u-margin-y--7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .desktop\:vads-u-margin-top--7 {
    margin-top: 3.5rem !important;
  }
  .desktop\:vads-u-margin-bottom--7 {
    margin-bottom: 3.5rem !important;
  }
  .desktop\:vads-u-margin-y--8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .desktop\:vads-u-margin-top--8 {
    margin-top: 4rem !important;
  }
  .desktop\:vads-u-margin-bottom--8 {
    margin-bottom: 4rem !important;
  }
  .desktop\:vads-u-margin-y--9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .desktop\:vads-u-margin-top--9 {
    margin-top: 4.5rem !important;
  }
  .desktop\:vads-u-margin-bottom--9 {
    margin-bottom: 4.5rem !important;
  }
  .desktop\:vads-u-margin-y--neg9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .desktop\:vads-u-margin-top--neg9 {
    margin-top: -4.5rem !important;
  }
  .desktop\:vads-u-margin-bottom--neg9 {
    margin-bottom: -4.5rem !important;
  }
  .desktop\:vads-u-margin-y--neg8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .desktop\:vads-u-margin-top--neg8 {
    margin-top: -4rem !important;
  }
  .desktop\:vads-u-margin-bottom--neg8 {
    margin-bottom: -4rem !important;
  }
  .desktop\:vads-u-margin-y--neg7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .desktop\:vads-u-margin-top--neg7 {
    margin-top: -3.5rem !important;
  }
  .desktop\:vads-u-margin-bottom--neg7 {
    margin-bottom: -3.5rem !important;
  }
  .desktop\:vads-u-margin-y--neg6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .desktop\:vads-u-margin-top--neg6 {
    margin-top: -3rem !important;
  }
  .desktop\:vads-u-margin-bottom--neg6 {
    margin-bottom: -3rem !important;
  }
  .desktop\:vads-u-margin-y--neg5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .desktop\:vads-u-margin-top--neg5 {
    margin-top: -2.5rem !important;
  }
  .desktop\:vads-u-margin-bottom--neg5 {
    margin-bottom: -2.5rem !important;
  }
  .desktop\:vads-u-margin-y--neg4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .desktop\:vads-u-margin-top--neg4 {
    margin-top: -2rem !important;
  }
  .desktop\:vads-u-margin-bottom--neg4 {
    margin-bottom: -2rem !important;
  }
  .desktop\:vads-u-margin-y--neg3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .desktop\:vads-u-margin-top--neg3 {
    margin-top: -1.5rem !important;
  }
  .desktop\:vads-u-margin-bottom--neg3 {
    margin-bottom: -1.5rem !important;
  }
  .desktop\:vads-u-margin-y--neg2p5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .desktop\:vads-u-margin-top--neg2p5 {
    margin-top: -1.25rem !important;
  }
  .desktop\:vads-u-margin-bottom--neg2p5 {
    margin-bottom: -1.25rem !important;
  }
  .desktop\:vads-u-margin-y--neg2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .desktop\:vads-u-margin-top--neg2 {
    margin-top: -1rem !important;
  }
  .desktop\:vads-u-margin-bottom--neg2 {
    margin-bottom: -1rem !important;
  }
  .desktop\:vads-u-margin-y--neg1p5 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .desktop\:vads-u-margin-top--neg1p5 {
    margin-top: -0.75rem !important;
  }
  .desktop\:vads-u-margin-bottom--neg1p5 {
    margin-bottom: -0.75rem !important;
  }
  .desktop\:vads-u-margin-y--neg1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .desktop\:vads-u-margin-top--neg1 {
    margin-top: -0.5rem !important;
  }
  .desktop\:vads-u-margin-bottom--neg1 {
    margin-bottom: -0.5rem !important;
  }
  .desktop\:vads-u-margin-y--neg0p5 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .desktop\:vads-u-margin-top--neg0p5 {
    margin-top: -0.25rem !important;
  }
  .desktop\:vads-u-margin-bottom--neg0p5 {
    margin-bottom: -0.25rem !important;
  }
  .desktop\:vads-u-margin-y--neg0p25 {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .desktop\:vads-u-margin-top--neg0p25 {
    margin-top: -2px !important;
  }
  .desktop\:vads-u-margin-bottom--neg0p25 {
    margin-bottom: -2px !important;
  }
  .desktop\:vads-u-margin-y--neg1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }
  .desktop\:vads-u-margin-top--neg1px {
    margin-top: -1px !important;
  }
  .desktop\:vads-u-margin-bottom--neg1px {
    margin-bottom: -1px !important;
  }
  .desktop\:vads-u-margin-x--0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .desktop\:vads-u-margin-right--0 {
    margin-right: 0 !important;
  }
  .desktop\:vads-u-margin-left--0 {
    margin-left: 0 !important;
  }
  .desktop\:vads-u-margin-x--1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .desktop\:vads-u-margin-right--1px {
    margin-right: 1px !important;
  }
  .desktop\:vads-u-margin-left--1px {
    margin-left: 1px !important;
  }
  .desktop\:vads-u-margin-x--0p25 {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .desktop\:vads-u-margin-right--0p25 {
    margin-right: 2px !important;
  }
  .desktop\:vads-u-margin-left--0p25 {
    margin-left: 2px !important;
  }
  .desktop\:vads-u-margin-x--0p5 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .desktop\:vads-u-margin-right--0p5 {
    margin-right: 0.25rem !important;
  }
  .desktop\:vads-u-margin-left--0p5 {
    margin-left: 0.25rem !important;
  }
  .desktop\:vads-u-margin-x--1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .desktop\:vads-u-margin-right--1 {
    margin-right: 0.5rem !important;
  }
  .desktop\:vads-u-margin-left--1 {
    margin-left: 0.5rem !important;
  }
  .desktop\:vads-u-margin-x--1p5 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .desktop\:vads-u-margin-right--1p5 {
    margin-right: 0.75rem !important;
  }
  .desktop\:vads-u-margin-left--1p5 {
    margin-left: 0.75rem !important;
  }
  .desktop\:vads-u-margin-x--2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .desktop\:vads-u-margin-right--2 {
    margin-right: 1rem !important;
  }
  .desktop\:vads-u-margin-left--2 {
    margin-left: 1rem !important;
  }
  .desktop\:vads-u-margin-x--2p5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .desktop\:vads-u-margin-right--2p5 {
    margin-right: 1.25rem !important;
  }
  .desktop\:vads-u-margin-left--2p5 {
    margin-left: 1.25rem !important;
  }
  .desktop\:vads-u-margin-x--3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .desktop\:vads-u-margin-right--3 {
    margin-right: 1.5rem !important;
  }
  .desktop\:vads-u-margin-left--3 {
    margin-left: 1.5rem !important;
  }
  .desktop\:vads-u-margin-x--4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .desktop\:vads-u-margin-right--4 {
    margin-right: 2rem !important;
  }
  .desktop\:vads-u-margin-left--4 {
    margin-left: 2rem !important;
  }
  .desktop\:vads-u-margin-x--5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .desktop\:vads-u-margin-right--5 {
    margin-right: 2.5rem !important;
  }
  .desktop\:vads-u-margin-left--5 {
    margin-left: 2.5rem !important;
  }
  .desktop\:vads-u-margin-x--6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .desktop\:vads-u-margin-right--6 {
    margin-right: 3rem !important;
  }
  .desktop\:vads-u-margin-left--6 {
    margin-left: 3rem !important;
  }
  .desktop\:vads-u-margin-x--7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .desktop\:vads-u-margin-right--7 {
    margin-right: 3.5rem !important;
  }
  .desktop\:vads-u-margin-left--7 {
    margin-left: 3.5rem !important;
  }
  .desktop\:vads-u-margin-x--8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .desktop\:vads-u-margin-right--8 {
    margin-right: 4rem !important;
  }
  .desktop\:vads-u-margin-left--8 {
    margin-left: 4rem !important;
  }
  .desktop\:vads-u-margin-x--9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .desktop\:vads-u-margin-right--9 {
    margin-right: 4.5rem !important;
  }
  .desktop\:vads-u-margin-left--9 {
    margin-left: 4.5rem !important;
  }
  .desktop\:vads-u-margin-x--neg9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .desktop\:vads-u-margin-right--neg9 {
    margin-right: -4.5rem !important;
  }
  .desktop\:vads-u-margin-left--neg9 {
    margin-left: -4.5rem !important;
  }
  .desktop\:vads-u-margin-x--neg8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .desktop\:vads-u-margin-right--neg8 {
    margin-right: -4rem !important;
  }
  .desktop\:vads-u-margin-left--neg8 {
    margin-left: -4rem !important;
  }
  .desktop\:vads-u-margin-x--neg7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .desktop\:vads-u-margin-right--neg7 {
    margin-right: -3.5rem !important;
  }
  .desktop\:vads-u-margin-left--neg7 {
    margin-left: -3.5rem !important;
  }
  .desktop\:vads-u-margin-x--neg6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .desktop\:vads-u-margin-right--neg6 {
    margin-right: -3rem !important;
  }
  .desktop\:vads-u-margin-left--neg6 {
    margin-left: -3rem !important;
  }
  .desktop\:vads-u-margin-x--neg5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .desktop\:vads-u-margin-right--neg5 {
    margin-right: -2.5rem !important;
  }
  .desktop\:vads-u-margin-left--neg5 {
    margin-left: -2.5rem !important;
  }
  .desktop\:vads-u-margin-x--neg4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .desktop\:vads-u-margin-right--neg4 {
    margin-right: -2rem !important;
  }
  .desktop\:vads-u-margin-left--neg4 {
    margin-left: -2rem !important;
  }
  .desktop\:vads-u-margin-x--neg3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .desktop\:vads-u-margin-right--neg3 {
    margin-right: -1.5rem !important;
  }
  .desktop\:vads-u-margin-left--neg3 {
    margin-left: -1.5rem !important;
  }
  .desktop\:vads-u-margin-x--neg2p5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .desktop\:vads-u-margin-right--neg2p5 {
    margin-right: -1.25rem !important;
  }
  .desktop\:vads-u-margin-left--neg2p5 {
    margin-left: -1.25rem !important;
  }
  .desktop\:vads-u-margin-x--neg2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .desktop\:vads-u-margin-right--neg2 {
    margin-right: -1rem !important;
  }
  .desktop\:vads-u-margin-left--neg2 {
    margin-left: -1rem !important;
  }
  .desktop\:vads-u-margin-x--neg1p5 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .desktop\:vads-u-margin-right--neg1p5 {
    margin-right: -0.75rem !important;
  }
  .desktop\:vads-u-margin-left--neg1p5 {
    margin-left: -0.75rem !important;
  }
  .desktop\:vads-u-margin-x--neg1 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .desktop\:vads-u-margin-right--neg1 {
    margin-right: -0.5rem !important;
  }
  .desktop\:vads-u-margin-left--neg1 {
    margin-left: -0.5rem !important;
  }
  .desktop\:vads-u-margin-x--neg0p5 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .desktop\:vads-u-margin-right--neg0p5 {
    margin-right: -0.25rem !important;
  }
  .desktop\:vads-u-margin-left--neg0p5 {
    margin-left: -0.25rem !important;
  }
  .desktop\:vads-u-margin-x--neg0p25 {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }
  .desktop\:vads-u-margin-right--neg0p25 {
    margin-right: -2px !important;
  }
  .desktop\:vads-u-margin-left--neg0p25 {
    margin-left: -2px !important;
  }
  .desktop\:vads-u-margin-x--neg1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }
  .desktop\:vads-u-margin-right--neg1px {
    margin-right: -1px !important;
  }
  .desktop\:vads-u-margin-left--neg1px {
    margin-left: -1px !important;
  }
  .desktop\:vads-u-margin-x--auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .desktop\:vads-u-margin-right--auto {
    margin-right: auto !important;
  }
  .desktop\:vads-u-margin-left--auto {
    margin-left: auto !important;
  }
  .desktop\:vads-u-measure--1 {
    max-width: 40ch !important;
  }
  .desktop\:vads-u-measure--2 {
    max-width: 60ch !important;
  }
  .desktop\:vads-u-measure--3 {
    max-width: 66ch !important;
  }
  .desktop\:vads-u-measure--4 {
    max-width: 72ch !important;
  }
  .desktop\:vads-u-measure--5 {
    max-width: 77ch !important;
  }
  .desktop\:vads-u-measure--none {
    max-width: none !important;
  }
  .desktop\:vads-u-padding--0 {
    padding: 0 !important;
  }
  .desktop\:vads-u-padding-top--0 {
    padding-top: 0 !important;
  }
  .desktop\:vads-u-padding-bottom--0 {
    padding-bottom: 0 !important;
  }
  .desktop\:vads-u-padding-right--0 {
    padding-right: 0 !important;
  }
  .desktop\:vads-u-padding-left--0 {
    padding-left: 0 !important;
  }
  .desktop\:vads-u-padding--1px {
    padding: 1px !important;
  }
  .desktop\:vads-u-padding-top--1px {
    padding-top: 1px !important;
  }
  .desktop\:vads-u-padding-bottom--1px {
    padding-bottom: 1px !important;
  }
  .desktop\:vads-u-padding-right--1px {
    padding-right: 1px !important;
  }
  .desktop\:vads-u-padding-left--1px {
    padding-left: 1px !important;
  }
  .desktop\:vads-u-padding--0p25 {
    padding: 2px !important;
  }
  .desktop\:vads-u-padding-top--0p25 {
    padding-top: 2px !important;
  }
  .desktop\:vads-u-padding-bottom--0p25 {
    padding-bottom: 2px !important;
  }
  .desktop\:vads-u-padding-right--0p25 {
    padding-right: 2px !important;
  }
  .desktop\:vads-u-padding-left--0p25 {
    padding-left: 2px !important;
  }
  .desktop\:vads-u-padding--0p5 {
    padding: 0.25rem !important;
  }
  .desktop\:vads-u-padding-top--0p5 {
    padding-top: 0.25rem !important;
  }
  .desktop\:vads-u-padding-bottom--0p5 {
    padding-bottom: 0.25rem !important;
  }
  .desktop\:vads-u-padding-right--0p5 {
    padding-right: 0.25rem !important;
  }
  .desktop\:vads-u-padding-left--0p5 {
    padding-left: 0.25rem !important;
  }
  .desktop\:vads-u-padding--1 {
    padding: 0.5rem !important;
  }
  .desktop\:vads-u-padding-top--1 {
    padding-top: 0.5rem !important;
  }
  .desktop\:vads-u-padding-bottom--1 {
    padding-bottom: 0.5rem !important;
  }
  .desktop\:vads-u-padding-right--1 {
    padding-right: 0.5rem !important;
  }
  .desktop\:vads-u-padding-left--1 {
    padding-left: 0.5rem !important;
  }
  .desktop\:vads-u-padding--1p5 {
    padding: 0.75rem !important;
  }
  .desktop\:vads-u-padding-top--1p5 {
    padding-top: 0.75rem !important;
  }
  .desktop\:vads-u-padding-bottom--1p5 {
    padding-bottom: 0.75rem !important;
  }
  .desktop\:vads-u-padding-right--1p5 {
    padding-right: 0.75rem !important;
  }
  .desktop\:vads-u-padding-left--1p5 {
    padding-left: 0.75rem !important;
  }
  .desktop\:vads-u-padding--2 {
    padding: 1rem !important;
  }
  .desktop\:vads-u-padding-top--2 {
    padding-top: 1rem !important;
  }
  .desktop\:vads-u-padding-bottom--2 {
    padding-bottom: 1rem !important;
  }
  .desktop\:vads-u-padding-right--2 {
    padding-right: 1rem !important;
  }
  .desktop\:vads-u-padding-left--2 {
    padding-left: 1rem !important;
  }
  .desktop\:vads-u-padding--2p5 {
    padding: 1.25rem !important;
  }
  .desktop\:vads-u-padding-top--2p5 {
    padding-top: 1.25rem !important;
  }
  .desktop\:vads-u-padding-bottom--2p5 {
    padding-bottom: 1.25rem !important;
  }
  .desktop\:vads-u-padding-right--2p5 {
    padding-right: 1.25rem !important;
  }
  .desktop\:vads-u-padding-left--2p5 {
    padding-left: 1.25rem !important;
  }
  .desktop\:vads-u-padding--3 {
    padding: 1.5rem !important;
  }
  .desktop\:vads-u-padding-top--3 {
    padding-top: 1.5rem !important;
  }
  .desktop\:vads-u-padding-bottom--3 {
    padding-bottom: 1.5rem !important;
  }
  .desktop\:vads-u-padding-right--3 {
    padding-right: 1.5rem !important;
  }
  .desktop\:vads-u-padding-left--3 {
    padding-left: 1.5rem !important;
  }
  .desktop\:vads-u-padding--4 {
    padding: 2rem !important;
  }
  .desktop\:vads-u-padding-top--4 {
    padding-top: 2rem !important;
  }
  .desktop\:vads-u-padding-bottom--4 {
    padding-bottom: 2rem !important;
  }
  .desktop\:vads-u-padding-right--4 {
    padding-right: 2rem !important;
  }
  .desktop\:vads-u-padding-left--4 {
    padding-left: 2rem !important;
  }
  .desktop\:vads-u-padding--5 {
    padding: 2.5rem !important;
  }
  .desktop\:vads-u-padding-top--5 {
    padding-top: 2.5rem !important;
  }
  .desktop\:vads-u-padding-bottom--5 {
    padding-bottom: 2.5rem !important;
  }
  .desktop\:vads-u-padding-right--5 {
    padding-right: 2.5rem !important;
  }
  .desktop\:vads-u-padding-left--5 {
    padding-left: 2.5rem !important;
  }
  .desktop\:vads-u-padding--6 {
    padding: 3rem !important;
  }
  .desktop\:vads-u-padding-top--6 {
    padding-top: 3rem !important;
  }
  .desktop\:vads-u-padding-bottom--6 {
    padding-bottom: 3rem !important;
  }
  .desktop\:vads-u-padding-right--6 {
    padding-right: 3rem !important;
  }
  .desktop\:vads-u-padding-left--6 {
    padding-left: 3rem !important;
  }
  .desktop\:vads-u-padding--7 {
    padding: 3.5rem !important;
  }
  .desktop\:vads-u-padding-top--7 {
    padding-top: 3.5rem !important;
  }
  .desktop\:vads-u-padding-bottom--7 {
    padding-bottom: 3.5rem !important;
  }
  .desktop\:vads-u-padding-right--7 {
    padding-right: 3.5rem !important;
  }
  .desktop\:vads-u-padding-left--7 {
    padding-left: 3.5rem !important;
  }
  .desktop\:vads-u-padding--8 {
    padding: 4rem !important;
  }
  .desktop\:vads-u-padding-top--8 {
    padding-top: 4rem !important;
  }
  .desktop\:vads-u-padding-bottom--8 {
    padding-bottom: 4rem !important;
  }
  .desktop\:vads-u-padding-right--8 {
    padding-right: 4rem !important;
  }
  .desktop\:vads-u-padding-left--8 {
    padding-left: 4rem !important;
  }
  .desktop\:vads-u-padding--9 {
    padding: 4.5rem !important;
  }
  .desktop\:vads-u-padding-top--9 {
    padding-top: 4.5rem !important;
  }
  .desktop\:vads-u-padding-bottom--9 {
    padding-bottom: 4.5rem !important;
  }
  .desktop\:vads-u-padding-right--9 {
    padding-right: 4.5rem !important;
  }
  .desktop\:vads-u-padding-left--9 {
    padding-left: 4.5rem !important;
  }
  .desktop\:vads-u-padding-y--0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .desktop\:vads-u-padding-y--1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .desktop\:vads-u-padding-y--0p25 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .desktop\:vads-u-padding-y--0p5 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .desktop\:vads-u-padding-y--1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .desktop\:vads-u-padding-y--1p5 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .desktop\:vads-u-padding-y--2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .desktop\:vads-u-padding-y--2p5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .desktop\:vads-u-padding-y--3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .desktop\:vads-u-padding-y--4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .desktop\:vads-u-padding-y--5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .desktop\:vads-u-padding-y--6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .desktop\:vads-u-padding-y--7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .desktop\:vads-u-padding-y--8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .desktop\:vads-u-padding-y--9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .desktop\:vads-u-padding-x--0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .desktop\:vads-u-padding-x--1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .desktop\:vads-u-padding-x--0p25 {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .desktop\:vads-u-padding-x--0p5 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .desktop\:vads-u-padding-x--1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .desktop\:vads-u-padding-x--1p5 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .desktop\:vads-u-padding-x--2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .desktop\:vads-u-padding-x--2p5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .desktop\:vads-u-padding-x--3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .desktop\:vads-u-padding-x--4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .desktop\:vads-u-padding-x--5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .desktop\:vads-u-padding-x--6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .desktop\:vads-u-padding-x--7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .desktop\:vads-u-padding-x--8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .desktop\:vads-u-padding-x--9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .desktop\:vads-u-position--absolute {
    position: absolute !important;
  }
  .desktop\:vads-u-position--fixed {
    position: fixed !important;
  }
  .desktop\:vads-u-position--relative {
    position: relative !important;
  }
  .desktop\:vads-u-text-align--left {
    text-align: left !important;
  }
  .desktop\:vads-u-text-align--center {
    text-align: center !important;
  }
  .desktop\:vads-u-text-align--right {
    text-align: right !important;
  }
  .desktop\:vads-u-text-align--justify {
    text-align: justify !important;
  }
  .desktop\:vads-u-visibility--hidden {
    visibility: hidden !important;
  }
  .desktop\:vads-u-visibility--visible {
    visibility: visible !important;
  }
  .desktop\:vads-u-width--0 {
    width: 0px !important;
  }
  .desktop\:vads-u-width--full {
    width: 100% !important;
  }
  .desktop\:vads-u-width--auto {
    width: auto !important;
  }
  .desktop\:vads-u-min-width--none {
    min-width: none !important;
  }
  .desktop\:vads-u-max-width--none {
    max-width: none !important;
  }
  .desktop\:vads-u-max-width--100 {
    max-width: 100% !important;
  }
}
@media all and (min-width: 75em) {
  .desktop-lg\:vads-u-border--0 {
    border: 0 !important;
  }
  .desktop-lg\:vads-u-border-top--0 {
    border-top: 0 !important;
  }
  .desktop-lg\:vads-u-border-right--0 {
    border-right: 0 !important;
  }
  .desktop-lg\:vads-u-border-bottom--0 {
    border-bottom: 0 !important;
  }
  .desktop-lg\:vads-u-border-left--0 {
    border-left: 0 !important;
  }
  .desktop-lg\:vads-u-border--1px {
    border: 1px solid !important;
  }
  .desktop-lg\:vads-u-border-top--1px {
    border-top: 1px solid !important;
  }
  .desktop-lg\:vads-u-border-right--1px {
    border-right: 1px solid !important;
  }
  .desktop-lg\:vads-u-border-bottom--1px {
    border-bottom: 1px solid !important;
  }
  .desktop-lg\:vads-u-border-left--1px {
    border-left: 1px solid !important;
  }
  .desktop-lg\:vads-u-border--2px {
    border: 2px solid !important;
  }
  .desktop-lg\:vads-u-border-top--2px {
    border-top: 2px solid !important;
  }
  .desktop-lg\:vads-u-border-right--2px {
    border-right: 2px solid !important;
  }
  .desktop-lg\:vads-u-border-bottom--2px {
    border-bottom: 2px solid !important;
  }
  .desktop-lg\:vads-u-border-left--2px {
    border-left: 2px solid !important;
  }
  .desktop-lg\:vads-u-border--3px {
    border: 3px solid !important;
  }
  .desktop-lg\:vads-u-border-top--3px {
    border-top: 3px solid !important;
  }
  .desktop-lg\:vads-u-border-right--3px {
    border-right: 3px solid !important;
  }
  .desktop-lg\:vads-u-border-bottom--3px {
    border-bottom: 3px solid !important;
  }
  .desktop-lg\:vads-u-border-left--3px {
    border-left: 3px solid !important;
  }
  .desktop-lg\:vads-u-border--4px {
    border: 4px solid !important;
  }
  .desktop-lg\:vads-u-border-top--4px {
    border-top: 4px solid !important;
  }
  .desktop-lg\:vads-u-border-right--4px {
    border-right: 4px solid !important;
  }
  .desktop-lg\:vads-u-border-bottom--4px {
    border-bottom: 4px solid !important;
  }
  .desktop-lg\:vads-u-border-left--4px {
    border-left: 4px solid !important;
  }
  .desktop-lg\:vads-u-border--5px {
    border: 5px solid !important;
  }
  .desktop-lg\:vads-u-border-top--5px {
    border-top: 5px solid !important;
  }
  .desktop-lg\:vads-u-border-right--5px {
    border-right: 5px solid !important;
  }
  .desktop-lg\:vads-u-border-bottom--5px {
    border-bottom: 5px solid !important;
  }
  .desktop-lg\:vads-u-border-left--5px {
    border-left: 5px solid !important;
  }
  .desktop-lg\:vads-u-border--7px {
    border: 7px solid !important;
  }
  .desktop-lg\:vads-u-border-top--7px {
    border-top: 7px solid !important;
  }
  .desktop-lg\:vads-u-border-right--7px {
    border-right: 7px solid !important;
  }
  .desktop-lg\:vads-u-border-bottom--7px {
    border-bottom: 7px solid !important;
  }
  .desktop-lg\:vads-u-border-left--7px {
    border-left: 7px solid !important;
  }
  .desktop-lg\:vads-u-border--10px {
    border: 10px solid !important;
  }
  .desktop-lg\:vads-u-border-top--10px {
    border-top: 10px solid !important;
  }
  .desktop-lg\:vads-u-border-right--10px {
    border-right: 10px solid !important;
  }
  .desktop-lg\:vads-u-border-bottom--10px {
    border-bottom: 10px solid !important;
  }
  .desktop-lg\:vads-u-border-left--10px {
    border-left: 10px solid !important;
  }
  .desktop-lg\:vads-u-display--block {
    display: block !important;
  }
  .desktop-lg\:vads-u-display--inline {
    display: inline !important;
  }
  .desktop-lg\:vads-u-display--inline-block {
    display: inline-block !important;
  }
  .desktop-lg\:vads-u-display--flex {
    display: flex !important;
  }
  .desktop-lg\:vads-u-display--none {
    display: none !important;
  }
  .desktop-lg\:vads-u-flex--1 {
    flex: 1 0 0% !important;
  }
  .desktop-lg\:vads-u-flex--2 {
    flex: 2 0 0% !important;
  }
  .desktop-lg\:vads-u-flex--3 {
    flex: 3 0 0% !important;
  }
  .desktop-lg\:vads-u-flex--4 {
    flex: 4 0 0% !important;
  }
  .desktop-lg\:vads-u-flex--auto {
    flex: 0 0 auto !important;
  }
  .desktop-lg\:vads-u-flex--fill {
    flex: 1 0 0% !important;
  }
  .desktop-lg\:vads-u-flex-direction--column {
    flex-direction: column !important;
  }
  .desktop-lg\:vads-u-flex-direction--row {
    flex-direction: row !important;
  }
  .desktop-lg\:vads-u-flex-direction--column-reverse {
    flex-direction: column-reverse !important;
  }
  .desktop-lg\:vads-u-flex-direction--row-reverse {
    flex-direction: row-reverse !important;
  }
  .desktop-lg\:vads-u-flex-wrap--nowrap {
    flex-wrap: nowrap !important;
  }
  .desktop-lg\:vads-u-flex-wrap--wrap {
    flex-wrap: wrap !important;
  }
  .desktop-lg\:vads-u-flex-wrap--wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .desktop-lg\:vads-u-align-items--stretch {
    align-items: stretch !important;
  }
  .desktop-lg\:vads-u-align-items--flex-start {
    align-items: flex-start !important;
  }
  .desktop-lg\:vads-u-align-items--flex-end {
    align-items: flex-end !important;
  }
  .desktop-lg\:vads-u-align-items--center {
    align-items: center !important;
  }
  .desktop-lg\:vads-u-align-items--baseline {
    align-items: baseline !important;
  }
  .desktop-lg\:vads-u-justify-content--flex-start {
    justify-content: flex-start !important;
  }
  .desktop-lg\:vads-u-justify-content--flex-end {
    justify-content: flex-end !important;
  }
  .desktop-lg\:vads-u-justify-content--center {
    justify-content: center !important;
  }
  .desktop-lg\:vads-u-justify-content--space-between {
    justify-content: space-between !important;
  }
  .desktop-lg\:vads-u-justify-content--space-around {
    justify-content: space-around !important;
  }
  .desktop-lg\:vads-u-justify-content--space-evenly {
    justify-content: space-evenly !important;
  }
  .desktop-lg\:vads-u-align-content--flex-start {
    align-content: flex-start !important;
  }
  .desktop-lg\:vads-u-align-content--flex-end {
    align-content: flex-end !important;
  }
  .desktop-lg\:vads-u-align-content--center {
    align-content: center !important;
  }
  .desktop-lg\:vads-u-align-content--space-between {
    align-content: space-between !important;
  }
  .desktop-lg\:vads-u-align-content--space-around {
    align-content: space-around !important;
  }
  .desktop-lg\:vads-u-align-content--stretch {
    align-content: stretch !important;
  }
  .desktop-lg\:vads-u-align-self--auto {
    align-self: auto !important;
  }
  .desktop-lg\:vads-u-align-self--flex-start {
    align-self: flex-start !important;
  }
  .desktop-lg\:vads-u-align-self--flex-end {
    align-self: flex-end !important;
  }
  .desktop-lg\:vads-u-align-self--center {
    align-self: center !important;
  }
  .desktop-lg\:vads-u-align-self--baseline {
    align-self: baseline !important;
  }
  .desktop-lg\:vads-u-align-self--stretch {
    align-self: stretch !important;
  }
  .desktop-lg\:vads-u-order--1 {
    order: 1 !important;
  }
  .desktop-lg\:vads-u-order--2 {
    order: 2 !important;
  }
  .desktop-lg\:vads-u-order--3 {
    order: 3 !important;
  }
  .desktop-lg\:vads-u-order--4 {
    order: 4 !important;
  }
  .desktop-lg\:vads-u-order--first {
    order: -1 !important;
  }
  .desktop-lg\:vads-u-order--last {
    order: 999 !important;
  }
  .desktop-lg\:vads-u-order--initial {
    order: 0 !important;
  }
  .desktop-lg\:vads-u-font-size--source-sans-normalized {
    font-size: 1.063rem !important;
  }
  .desktop-lg\:vads-u-font-size--sm {
    font-size: 0.938rem !important;
  }
  .desktop-lg\:vads-u-font-size--base {
    font-size: 1rem !important;
  }
  .desktop-lg\:vads-u-font-size--md {
    font-size: 1.063rem !important;
  }
  .desktop-lg\:vads-u-font-size--lg {
    font-size: 1.25rem !important;
  }
  .desktop-lg\:vads-u-font-size--xl {
    font-size: 1.875rem !important;
  }
  .desktop-lg\:vads-u-font-size--2xl {
    font-size: 2.5rem !important;
  }
  .desktop-lg\:vads-u-font-size--h1 {
    font-size: 2.5rem !important;
  }
  .desktop-lg\:vads-u-font-size--h2 {
    font-size: 1.875rem !important;
  }
  .desktop-lg\:vads-u-font-size--h3 {
    font-size: 1.25rem !important;
  }
  .desktop-lg\:vads-u-font-size--h4 {
    font-size: 1.063rem !important;
  }
  .desktop-lg\:vads-u-font-size--h5 {
    font-size: 0.938rem !important;
  }
  .desktop-lg\:vads-u-font-size--h6 {
    font-size: 0.938rem !important;
  }
  .desktop-lg\:vads-u-height--0 {
    height: 0px !important;
  }
  .desktop-lg\:vads-u-height--full {
    height: 100% !important;
  }
  .desktop-lg\:vads-u-height--auto {
    height: auto !important;
  }
  .desktop-lg\:vads-u-height--viewport {
    height: 100vh !important;
  }
  .desktop-lg\:vads-u-min-height--none {
    min-height: none !important;
  }
  .desktop-lg\:vads-u-min-height--viewport {
    min-height: 100vh !important;
  }
  .desktop-lg\:vads-u-max-height--none {
    max-height: none !important;
  }
  .desktop-lg\:vads-u-max-height--viewport {
    max-height: 100vh !important;
  }
  .desktop-lg\:vads-u-line-height--1 {
    line-height: 1 !important;
  }
  .desktop-lg\:vads-u-line-height--2 {
    line-height: 1.15 !important;
  }
  .desktop-lg\:vads-u-line-height--3 {
    line-height: 1.35 !important;
  }
  .desktop-lg\:vads-u-line-height--4 {
    line-height: 1.5 !important;
  }
  .desktop-lg\:vads-u-line-height--5 {
    line-height: 1.62 !important;
  }
  .desktop-lg\:vads-u-line-height--6 {
    line-height: 1.75 !important;
  }
  .desktop-lg\:vads-u-margin--0 {
    margin: 0 !important;
  }
  .desktop-lg\:vads-u-margin--1px {
    margin: 1px !important;
  }
  .desktop-lg\:vads-u-margin--0p25 {
    margin: 2px !important;
  }
  .desktop-lg\:vads-u-margin--0p5 {
    margin: 0.25rem !important;
  }
  .desktop-lg\:vads-u-margin--1 {
    margin: 0.5rem !important;
  }
  .desktop-lg\:vads-u-margin--1p5 {
    margin: 0.75rem !important;
  }
  .desktop-lg\:vads-u-margin--2 {
    margin: 1rem !important;
  }
  .desktop-lg\:vads-u-margin--2p5 {
    margin: 1.25rem !important;
  }
  .desktop-lg\:vads-u-margin--3 {
    margin: 1.5rem !important;
  }
  .desktop-lg\:vads-u-margin--4 {
    margin: 2rem !important;
  }
  .desktop-lg\:vads-u-margin--5 {
    margin: 2.5rem !important;
  }
  .desktop-lg\:vads-u-margin--6 {
    margin: 3rem !important;
  }
  .desktop-lg\:vads-u-margin--7 {
    margin: 3.5rem !important;
  }
  .desktop-lg\:vads-u-margin--8 {
    margin: 4rem !important;
  }
  .desktop-lg\:vads-u-margin--9 {
    margin: 4.5rem !important;
  }
  .desktop-lg\:vads-u-margin-y--0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .desktop-lg\:vads-u-margin-top--0 {
    margin-top: 0 !important;
  }
  .desktop-lg\:vads-u-margin-bottom--0 {
    margin-bottom: 0 !important;
  }
  .desktop-lg\:vads-u-margin-y--1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .desktop-lg\:vads-u-margin-top--1px {
    margin-top: 1px !important;
  }
  .desktop-lg\:vads-u-margin-bottom--1px {
    margin-bottom: 1px !important;
  }
  .desktop-lg\:vads-u-margin-y--0p25 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .desktop-lg\:vads-u-margin-top--0p25 {
    margin-top: 2px !important;
  }
  .desktop-lg\:vads-u-margin-bottom--0p25 {
    margin-bottom: 2px !important;
  }
  .desktop-lg\:vads-u-margin-y--0p5 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .desktop-lg\:vads-u-margin-top--0p5 {
    margin-top: 0.25rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--0p5 {
    margin-bottom: 0.25rem !important;
  }
  .desktop-lg\:vads-u-margin-y--1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .desktop-lg\:vads-u-margin-top--1 {
    margin-top: 0.5rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--1 {
    margin-bottom: 0.5rem !important;
  }
  .desktop-lg\:vads-u-margin-y--1p5 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .desktop-lg\:vads-u-margin-top--1p5 {
    margin-top: 0.75rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--1p5 {
    margin-bottom: 0.75rem !important;
  }
  .desktop-lg\:vads-u-margin-y--2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .desktop-lg\:vads-u-margin-top--2 {
    margin-top: 1rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--2 {
    margin-bottom: 1rem !important;
  }
  .desktop-lg\:vads-u-margin-y--2p5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .desktop-lg\:vads-u-margin-top--2p5 {
    margin-top: 1.25rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--2p5 {
    margin-bottom: 1.25rem !important;
  }
  .desktop-lg\:vads-u-margin-y--3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .desktop-lg\:vads-u-margin-top--3 {
    margin-top: 1.5rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--3 {
    margin-bottom: 1.5rem !important;
  }
  .desktop-lg\:vads-u-margin-y--4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .desktop-lg\:vads-u-margin-top--4 {
    margin-top: 2rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--4 {
    margin-bottom: 2rem !important;
  }
  .desktop-lg\:vads-u-margin-y--5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .desktop-lg\:vads-u-margin-top--5 {
    margin-top: 2.5rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--5 {
    margin-bottom: 2.5rem !important;
  }
  .desktop-lg\:vads-u-margin-y--6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .desktop-lg\:vads-u-margin-top--6 {
    margin-top: 3rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--6 {
    margin-bottom: 3rem !important;
  }
  .desktop-lg\:vads-u-margin-y--7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .desktop-lg\:vads-u-margin-top--7 {
    margin-top: 3.5rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--7 {
    margin-bottom: 3.5rem !important;
  }
  .desktop-lg\:vads-u-margin-y--8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .desktop-lg\:vads-u-margin-top--8 {
    margin-top: 4rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--8 {
    margin-bottom: 4rem !important;
  }
  .desktop-lg\:vads-u-margin-y--9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .desktop-lg\:vads-u-margin-top--9 {
    margin-top: 4.5rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--9 {
    margin-bottom: 4.5rem !important;
  }
  .desktop-lg\:vads-u-margin-y--neg9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .desktop-lg\:vads-u-margin-top--neg9 {
    margin-top: -4.5rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--neg9 {
    margin-bottom: -4.5rem !important;
  }
  .desktop-lg\:vads-u-margin-y--neg8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .desktop-lg\:vads-u-margin-top--neg8 {
    margin-top: -4rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--neg8 {
    margin-bottom: -4rem !important;
  }
  .desktop-lg\:vads-u-margin-y--neg7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .desktop-lg\:vads-u-margin-top--neg7 {
    margin-top: -3.5rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--neg7 {
    margin-bottom: -3.5rem !important;
  }
  .desktop-lg\:vads-u-margin-y--neg6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .desktop-lg\:vads-u-margin-top--neg6 {
    margin-top: -3rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--neg6 {
    margin-bottom: -3rem !important;
  }
  .desktop-lg\:vads-u-margin-y--neg5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .desktop-lg\:vads-u-margin-top--neg5 {
    margin-top: -2.5rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--neg5 {
    margin-bottom: -2.5rem !important;
  }
  .desktop-lg\:vads-u-margin-y--neg4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .desktop-lg\:vads-u-margin-top--neg4 {
    margin-top: -2rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--neg4 {
    margin-bottom: -2rem !important;
  }
  .desktop-lg\:vads-u-margin-y--neg3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .desktop-lg\:vads-u-margin-top--neg3 {
    margin-top: -1.5rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--neg3 {
    margin-bottom: -1.5rem !important;
  }
  .desktop-lg\:vads-u-margin-y--neg2p5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .desktop-lg\:vads-u-margin-top--neg2p5 {
    margin-top: -1.25rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--neg2p5 {
    margin-bottom: -1.25rem !important;
  }
  .desktop-lg\:vads-u-margin-y--neg2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .desktop-lg\:vads-u-margin-top--neg2 {
    margin-top: -1rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--neg2 {
    margin-bottom: -1rem !important;
  }
  .desktop-lg\:vads-u-margin-y--neg1p5 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .desktop-lg\:vads-u-margin-top--neg1p5 {
    margin-top: -0.75rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--neg1p5 {
    margin-bottom: -0.75rem !important;
  }
  .desktop-lg\:vads-u-margin-y--neg1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .desktop-lg\:vads-u-margin-top--neg1 {
    margin-top: -0.5rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--neg1 {
    margin-bottom: -0.5rem !important;
  }
  .desktop-lg\:vads-u-margin-y--neg0p5 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .desktop-lg\:vads-u-margin-top--neg0p5 {
    margin-top: -0.25rem !important;
  }
  .desktop-lg\:vads-u-margin-bottom--neg0p5 {
    margin-bottom: -0.25rem !important;
  }
  .desktop-lg\:vads-u-margin-y--neg0p25 {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .desktop-lg\:vads-u-margin-top--neg0p25 {
    margin-top: -2px !important;
  }
  .desktop-lg\:vads-u-margin-bottom--neg0p25 {
    margin-bottom: -2px !important;
  }
  .desktop-lg\:vads-u-margin-y--neg1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }
  .desktop-lg\:vads-u-margin-top--neg1px {
    margin-top: -1px !important;
  }
  .desktop-lg\:vads-u-margin-bottom--neg1px {
    margin-bottom: -1px !important;
  }
  .desktop-lg\:vads-u-margin-x--0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .desktop-lg\:vads-u-margin-right--0 {
    margin-right: 0 !important;
  }
  .desktop-lg\:vads-u-margin-left--0 {
    margin-left: 0 !important;
  }
  .desktop-lg\:vads-u-margin-x--1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .desktop-lg\:vads-u-margin-right--1px {
    margin-right: 1px !important;
  }
  .desktop-lg\:vads-u-margin-left--1px {
    margin-left: 1px !important;
  }
  .desktop-lg\:vads-u-margin-x--0p25 {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .desktop-lg\:vads-u-margin-right--0p25 {
    margin-right: 2px !important;
  }
  .desktop-lg\:vads-u-margin-left--0p25 {
    margin-left: 2px !important;
  }
  .desktop-lg\:vads-u-margin-x--0p5 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .desktop-lg\:vads-u-margin-right--0p5 {
    margin-right: 0.25rem !important;
  }
  .desktop-lg\:vads-u-margin-left--0p5 {
    margin-left: 0.25rem !important;
  }
  .desktop-lg\:vads-u-margin-x--1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .desktop-lg\:vads-u-margin-right--1 {
    margin-right: 0.5rem !important;
  }
  .desktop-lg\:vads-u-margin-left--1 {
    margin-left: 0.5rem !important;
  }
  .desktop-lg\:vads-u-margin-x--1p5 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .desktop-lg\:vads-u-margin-right--1p5 {
    margin-right: 0.75rem !important;
  }
  .desktop-lg\:vads-u-margin-left--1p5 {
    margin-left: 0.75rem !important;
  }
  .desktop-lg\:vads-u-margin-x--2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .desktop-lg\:vads-u-margin-right--2 {
    margin-right: 1rem !important;
  }
  .desktop-lg\:vads-u-margin-left--2 {
    margin-left: 1rem !important;
  }
  .desktop-lg\:vads-u-margin-x--2p5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .desktop-lg\:vads-u-margin-right--2p5 {
    margin-right: 1.25rem !important;
  }
  .desktop-lg\:vads-u-margin-left--2p5 {
    margin-left: 1.25rem !important;
  }
  .desktop-lg\:vads-u-margin-x--3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .desktop-lg\:vads-u-margin-right--3 {
    margin-right: 1.5rem !important;
  }
  .desktop-lg\:vads-u-margin-left--3 {
    margin-left: 1.5rem !important;
  }
  .desktop-lg\:vads-u-margin-x--4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .desktop-lg\:vads-u-margin-right--4 {
    margin-right: 2rem !important;
  }
  .desktop-lg\:vads-u-margin-left--4 {
    margin-left: 2rem !important;
  }
  .desktop-lg\:vads-u-margin-x--5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .desktop-lg\:vads-u-margin-right--5 {
    margin-right: 2.5rem !important;
  }
  .desktop-lg\:vads-u-margin-left--5 {
    margin-left: 2.5rem !important;
  }
  .desktop-lg\:vads-u-margin-x--6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .desktop-lg\:vads-u-margin-right--6 {
    margin-right: 3rem !important;
  }
  .desktop-lg\:vads-u-margin-left--6 {
    margin-left: 3rem !important;
  }
  .desktop-lg\:vads-u-margin-x--7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .desktop-lg\:vads-u-margin-right--7 {
    margin-right: 3.5rem !important;
  }
  .desktop-lg\:vads-u-margin-left--7 {
    margin-left: 3.5rem !important;
  }
  .desktop-lg\:vads-u-margin-x--8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .desktop-lg\:vads-u-margin-right--8 {
    margin-right: 4rem !important;
  }
  .desktop-lg\:vads-u-margin-left--8 {
    margin-left: 4rem !important;
  }
  .desktop-lg\:vads-u-margin-x--9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .desktop-lg\:vads-u-margin-right--9 {
    margin-right: 4.5rem !important;
  }
  .desktop-lg\:vads-u-margin-left--9 {
    margin-left: 4.5rem !important;
  }
  .desktop-lg\:vads-u-margin-x--neg9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .desktop-lg\:vads-u-margin-right--neg9 {
    margin-right: -4.5rem !important;
  }
  .desktop-lg\:vads-u-margin-left--neg9 {
    margin-left: -4.5rem !important;
  }
  .desktop-lg\:vads-u-margin-x--neg8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .desktop-lg\:vads-u-margin-right--neg8 {
    margin-right: -4rem !important;
  }
  .desktop-lg\:vads-u-margin-left--neg8 {
    margin-left: -4rem !important;
  }
  .desktop-lg\:vads-u-margin-x--neg7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .desktop-lg\:vads-u-margin-right--neg7 {
    margin-right: -3.5rem !important;
  }
  .desktop-lg\:vads-u-margin-left--neg7 {
    margin-left: -3.5rem !important;
  }
  .desktop-lg\:vads-u-margin-x--neg6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .desktop-lg\:vads-u-margin-right--neg6 {
    margin-right: -3rem !important;
  }
  .desktop-lg\:vads-u-margin-left--neg6 {
    margin-left: -3rem !important;
  }
  .desktop-lg\:vads-u-margin-x--neg5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .desktop-lg\:vads-u-margin-right--neg5 {
    margin-right: -2.5rem !important;
  }
  .desktop-lg\:vads-u-margin-left--neg5 {
    margin-left: -2.5rem !important;
  }
  .desktop-lg\:vads-u-margin-x--neg4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .desktop-lg\:vads-u-margin-right--neg4 {
    margin-right: -2rem !important;
  }
  .desktop-lg\:vads-u-margin-left--neg4 {
    margin-left: -2rem !important;
  }
  .desktop-lg\:vads-u-margin-x--neg3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .desktop-lg\:vads-u-margin-right--neg3 {
    margin-right: -1.5rem !important;
  }
  .desktop-lg\:vads-u-margin-left--neg3 {
    margin-left: -1.5rem !important;
  }
  .desktop-lg\:vads-u-margin-x--neg2p5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .desktop-lg\:vads-u-margin-right--neg2p5 {
    margin-right: -1.25rem !important;
  }
  .desktop-lg\:vads-u-margin-left--neg2p5 {
    margin-left: -1.25rem !important;
  }
  .desktop-lg\:vads-u-margin-x--neg2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .desktop-lg\:vads-u-margin-right--neg2 {
    margin-right: -1rem !important;
  }
  .desktop-lg\:vads-u-margin-left--neg2 {
    margin-left: -1rem !important;
  }
  .desktop-lg\:vads-u-margin-x--neg1p5 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .desktop-lg\:vads-u-margin-right--neg1p5 {
    margin-right: -0.75rem !important;
  }
  .desktop-lg\:vads-u-margin-left--neg1p5 {
    margin-left: -0.75rem !important;
  }
  .desktop-lg\:vads-u-margin-x--neg1 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .desktop-lg\:vads-u-margin-right--neg1 {
    margin-right: -0.5rem !important;
  }
  .desktop-lg\:vads-u-margin-left--neg1 {
    margin-left: -0.5rem !important;
  }
  .desktop-lg\:vads-u-margin-x--neg0p5 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .desktop-lg\:vads-u-margin-right--neg0p5 {
    margin-right: -0.25rem !important;
  }
  .desktop-lg\:vads-u-margin-left--neg0p5 {
    margin-left: -0.25rem !important;
  }
  .desktop-lg\:vads-u-margin-x--neg0p25 {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }
  .desktop-lg\:vads-u-margin-right--neg0p25 {
    margin-right: -2px !important;
  }
  .desktop-lg\:vads-u-margin-left--neg0p25 {
    margin-left: -2px !important;
  }
  .desktop-lg\:vads-u-margin-x--neg1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }
  .desktop-lg\:vads-u-margin-right--neg1px {
    margin-right: -1px !important;
  }
  .desktop-lg\:vads-u-margin-left--neg1px {
    margin-left: -1px !important;
  }
  .desktop-lg\:vads-u-margin-x--auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .desktop-lg\:vads-u-margin-right--auto {
    margin-right: auto !important;
  }
  .desktop-lg\:vads-u-margin-left--auto {
    margin-left: auto !important;
  }
  .desktop-lg\:vads-u-measure--1 {
    max-width: 40ch !important;
  }
  .desktop-lg\:vads-u-measure--2 {
    max-width: 60ch !important;
  }
  .desktop-lg\:vads-u-measure--3 {
    max-width: 66ch !important;
  }
  .desktop-lg\:vads-u-measure--4 {
    max-width: 72ch !important;
  }
  .desktop-lg\:vads-u-measure--5 {
    max-width: 77ch !important;
  }
  .desktop-lg\:vads-u-measure--none {
    max-width: none !important;
  }
  .desktop-lg\:vads-u-padding--0 {
    padding: 0 !important;
  }
  .desktop-lg\:vads-u-padding-top--0 {
    padding-top: 0 !important;
  }
  .desktop-lg\:vads-u-padding-bottom--0 {
    padding-bottom: 0 !important;
  }
  .desktop-lg\:vads-u-padding-right--0 {
    padding-right: 0 !important;
  }
  .desktop-lg\:vads-u-padding-left--0 {
    padding-left: 0 !important;
  }
  .desktop-lg\:vads-u-padding--1px {
    padding: 1px !important;
  }
  .desktop-lg\:vads-u-padding-top--1px {
    padding-top: 1px !important;
  }
  .desktop-lg\:vads-u-padding-bottom--1px {
    padding-bottom: 1px !important;
  }
  .desktop-lg\:vads-u-padding-right--1px {
    padding-right: 1px !important;
  }
  .desktop-lg\:vads-u-padding-left--1px {
    padding-left: 1px !important;
  }
  .desktop-lg\:vads-u-padding--0p25 {
    padding: 2px !important;
  }
  .desktop-lg\:vads-u-padding-top--0p25 {
    padding-top: 2px !important;
  }
  .desktop-lg\:vads-u-padding-bottom--0p25 {
    padding-bottom: 2px !important;
  }
  .desktop-lg\:vads-u-padding-right--0p25 {
    padding-right: 2px !important;
  }
  .desktop-lg\:vads-u-padding-left--0p25 {
    padding-left: 2px !important;
  }
  .desktop-lg\:vads-u-padding--0p5 {
    padding: 0.25rem !important;
  }
  .desktop-lg\:vads-u-padding-top--0p5 {
    padding-top: 0.25rem !important;
  }
  .desktop-lg\:vads-u-padding-bottom--0p5 {
    padding-bottom: 0.25rem !important;
  }
  .desktop-lg\:vads-u-padding-right--0p5 {
    padding-right: 0.25rem !important;
  }
  .desktop-lg\:vads-u-padding-left--0p5 {
    padding-left: 0.25rem !important;
  }
  .desktop-lg\:vads-u-padding--1 {
    padding: 0.5rem !important;
  }
  .desktop-lg\:vads-u-padding-top--1 {
    padding-top: 0.5rem !important;
  }
  .desktop-lg\:vads-u-padding-bottom--1 {
    padding-bottom: 0.5rem !important;
  }
  .desktop-lg\:vads-u-padding-right--1 {
    padding-right: 0.5rem !important;
  }
  .desktop-lg\:vads-u-padding-left--1 {
    padding-left: 0.5rem !important;
  }
  .desktop-lg\:vads-u-padding--1p5 {
    padding: 0.75rem !important;
  }
  .desktop-lg\:vads-u-padding-top--1p5 {
    padding-top: 0.75rem !important;
  }
  .desktop-lg\:vads-u-padding-bottom--1p5 {
    padding-bottom: 0.75rem !important;
  }
  .desktop-lg\:vads-u-padding-right--1p5 {
    padding-right: 0.75rem !important;
  }
  .desktop-lg\:vads-u-padding-left--1p5 {
    padding-left: 0.75rem !important;
  }
  .desktop-lg\:vads-u-padding--2 {
    padding: 1rem !important;
  }
  .desktop-lg\:vads-u-padding-top--2 {
    padding-top: 1rem !important;
  }
  .desktop-lg\:vads-u-padding-bottom--2 {
    padding-bottom: 1rem !important;
  }
  .desktop-lg\:vads-u-padding-right--2 {
    padding-right: 1rem !important;
  }
  .desktop-lg\:vads-u-padding-left--2 {
    padding-left: 1rem !important;
  }
  .desktop-lg\:vads-u-padding--2p5 {
    padding: 1.25rem !important;
  }
  .desktop-lg\:vads-u-padding-top--2p5 {
    padding-top: 1.25rem !important;
  }
  .desktop-lg\:vads-u-padding-bottom--2p5 {
    padding-bottom: 1.25rem !important;
  }
  .desktop-lg\:vads-u-padding-right--2p5 {
    padding-right: 1.25rem !important;
  }
  .desktop-lg\:vads-u-padding-left--2p5 {
    padding-left: 1.25rem !important;
  }
  .desktop-lg\:vads-u-padding--3 {
    padding: 1.5rem !important;
  }
  .desktop-lg\:vads-u-padding-top--3 {
    padding-top: 1.5rem !important;
  }
  .desktop-lg\:vads-u-padding-bottom--3 {
    padding-bottom: 1.5rem !important;
  }
  .desktop-lg\:vads-u-padding-right--3 {
    padding-right: 1.5rem !important;
  }
  .desktop-lg\:vads-u-padding-left--3 {
    padding-left: 1.5rem !important;
  }
  .desktop-lg\:vads-u-padding--4 {
    padding: 2rem !important;
  }
  .desktop-lg\:vads-u-padding-top--4 {
    padding-top: 2rem !important;
  }
  .desktop-lg\:vads-u-padding-bottom--4 {
    padding-bottom: 2rem !important;
  }
  .desktop-lg\:vads-u-padding-right--4 {
    padding-right: 2rem !important;
  }
  .desktop-lg\:vads-u-padding-left--4 {
    padding-left: 2rem !important;
  }
  .desktop-lg\:vads-u-padding--5 {
    padding: 2.5rem !important;
  }
  .desktop-lg\:vads-u-padding-top--5 {
    padding-top: 2.5rem !important;
  }
  .desktop-lg\:vads-u-padding-bottom--5 {
    padding-bottom: 2.5rem !important;
  }
  .desktop-lg\:vads-u-padding-right--5 {
    padding-right: 2.5rem !important;
  }
  .desktop-lg\:vads-u-padding-left--5 {
    padding-left: 2.5rem !important;
  }
  .desktop-lg\:vads-u-padding--6 {
    padding: 3rem !important;
  }
  .desktop-lg\:vads-u-padding-top--6 {
    padding-top: 3rem !important;
  }
  .desktop-lg\:vads-u-padding-bottom--6 {
    padding-bottom: 3rem !important;
  }
  .desktop-lg\:vads-u-padding-right--6 {
    padding-right: 3rem !important;
  }
  .desktop-lg\:vads-u-padding-left--6 {
    padding-left: 3rem !important;
  }
  .desktop-lg\:vads-u-padding--7 {
    padding: 3.5rem !important;
  }
  .desktop-lg\:vads-u-padding-top--7 {
    padding-top: 3.5rem !important;
  }
  .desktop-lg\:vads-u-padding-bottom--7 {
    padding-bottom: 3.5rem !important;
  }
  .desktop-lg\:vads-u-padding-right--7 {
    padding-right: 3.5rem !important;
  }
  .desktop-lg\:vads-u-padding-left--7 {
    padding-left: 3.5rem !important;
  }
  .desktop-lg\:vads-u-padding--8 {
    padding: 4rem !important;
  }
  .desktop-lg\:vads-u-padding-top--8 {
    padding-top: 4rem !important;
  }
  .desktop-lg\:vads-u-padding-bottom--8 {
    padding-bottom: 4rem !important;
  }
  .desktop-lg\:vads-u-padding-right--8 {
    padding-right: 4rem !important;
  }
  .desktop-lg\:vads-u-padding-left--8 {
    padding-left: 4rem !important;
  }
  .desktop-lg\:vads-u-padding--9 {
    padding: 4.5rem !important;
  }
  .desktop-lg\:vads-u-padding-top--9 {
    padding-top: 4.5rem !important;
  }
  .desktop-lg\:vads-u-padding-bottom--9 {
    padding-bottom: 4.5rem !important;
  }
  .desktop-lg\:vads-u-padding-right--9 {
    padding-right: 4.5rem !important;
  }
  .desktop-lg\:vads-u-padding-left--9 {
    padding-left: 4.5rem !important;
  }
  .desktop-lg\:vads-u-padding-y--0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .desktop-lg\:vads-u-padding-y--1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .desktop-lg\:vads-u-padding-y--0p25 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .desktop-lg\:vads-u-padding-y--0p5 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .desktop-lg\:vads-u-padding-y--1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .desktop-lg\:vads-u-padding-y--1p5 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .desktop-lg\:vads-u-padding-y--2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .desktop-lg\:vads-u-padding-y--2p5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .desktop-lg\:vads-u-padding-y--3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .desktop-lg\:vads-u-padding-y--4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .desktop-lg\:vads-u-padding-y--5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .desktop-lg\:vads-u-padding-y--6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .desktop-lg\:vads-u-padding-y--7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .desktop-lg\:vads-u-padding-y--8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .desktop-lg\:vads-u-padding-y--9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .desktop-lg\:vads-u-padding-x--0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .desktop-lg\:vads-u-padding-x--1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .desktop-lg\:vads-u-padding-x--0p25 {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .desktop-lg\:vads-u-padding-x--0p5 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .desktop-lg\:vads-u-padding-x--1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .desktop-lg\:vads-u-padding-x--1p5 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .desktop-lg\:vads-u-padding-x--2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .desktop-lg\:vads-u-padding-x--2p5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .desktop-lg\:vads-u-padding-x--3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .desktop-lg\:vads-u-padding-x--4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .desktop-lg\:vads-u-padding-x--5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .desktop-lg\:vads-u-padding-x--6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .desktop-lg\:vads-u-padding-x--7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .desktop-lg\:vads-u-padding-x--8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .desktop-lg\:vads-u-padding-x--9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .desktop-lg\:vads-u-position--absolute {
    position: absolute !important;
  }
  .desktop-lg\:vads-u-position--fixed {
    position: fixed !important;
  }
  .desktop-lg\:vads-u-position--relative {
    position: relative !important;
  }
  .desktop-lg\:vads-u-text-align--left {
    text-align: left !important;
  }
  .desktop-lg\:vads-u-text-align--center {
    text-align: center !important;
  }
  .desktop-lg\:vads-u-text-align--right {
    text-align: right !important;
  }
  .desktop-lg\:vads-u-text-align--justify {
    text-align: justify !important;
  }
  .desktop-lg\:vads-u-visibility--hidden {
    visibility: hidden !important;
  }
  .desktop-lg\:vads-u-visibility--visible {
    visibility: visible !important;
  }
  .desktop-lg\:vads-u-width--0 {
    width: 0px !important;
  }
  .desktop-lg\:vads-u-width--full {
    width: 100% !important;
  }
  .desktop-lg\:vads-u-width--auto {
    width: auto !important;
  }
  .desktop-lg\:vads-u-min-width--none {
    min-width: none !important;
  }
  .desktop-lg\:vads-u-max-width--none {
    max-width: none !important;
  }
  .desktop-lg\:vads-u-max-width--100 {
    max-width: 100% !important;
  }
}
@media all and (min-width: 48em) {
  .medium-screen\:vads-u-border--0 {
    border: 0 !important;
  }
  .medium-screen\:vads-u-border-top--0 {
    border-top: 0 !important;
  }
  .medium-screen\:vads-u-border-right--0 {
    border-right: 0 !important;
  }
  .medium-screen\:vads-u-border-bottom--0 {
    border-bottom: 0 !important;
  }
  .medium-screen\:vads-u-border-left--0 {
    border-left: 0 !important;
  }
  .medium-screen\:vads-u-border--1px {
    border: 1px solid !important;
  }
  .medium-screen\:vads-u-border-top--1px {
    border-top: 1px solid !important;
  }
  .medium-screen\:vads-u-border-right--1px {
    border-right: 1px solid !important;
  }
  .medium-screen\:vads-u-border-bottom--1px {
    border-bottom: 1px solid !important;
  }
  .medium-screen\:vads-u-border-left--1px {
    border-left: 1px solid !important;
  }
  .medium-screen\:vads-u-border--2px {
    border: 2px solid !important;
  }
  .medium-screen\:vads-u-border-top--2px {
    border-top: 2px solid !important;
  }
  .medium-screen\:vads-u-border-right--2px {
    border-right: 2px solid !important;
  }
  .medium-screen\:vads-u-border-bottom--2px {
    border-bottom: 2px solid !important;
  }
  .medium-screen\:vads-u-border-left--2px {
    border-left: 2px solid !important;
  }
  .medium-screen\:vads-u-border--3px {
    border: 3px solid !important;
  }
  .medium-screen\:vads-u-border-top--3px {
    border-top: 3px solid !important;
  }
  .medium-screen\:vads-u-border-right--3px {
    border-right: 3px solid !important;
  }
  .medium-screen\:vads-u-border-bottom--3px {
    border-bottom: 3px solid !important;
  }
  .medium-screen\:vads-u-border-left--3px {
    border-left: 3px solid !important;
  }
  .medium-screen\:vads-u-border--4px {
    border: 4px solid !important;
  }
  .medium-screen\:vads-u-border-top--4px {
    border-top: 4px solid !important;
  }
  .medium-screen\:vads-u-border-right--4px {
    border-right: 4px solid !important;
  }
  .medium-screen\:vads-u-border-bottom--4px {
    border-bottom: 4px solid !important;
  }
  .medium-screen\:vads-u-border-left--4px {
    border-left: 4px solid !important;
  }
  .medium-screen\:vads-u-border--5px {
    border: 5px solid !important;
  }
  .medium-screen\:vads-u-border-top--5px {
    border-top: 5px solid !important;
  }
  .medium-screen\:vads-u-border-right--5px {
    border-right: 5px solid !important;
  }
  .medium-screen\:vads-u-border-bottom--5px {
    border-bottom: 5px solid !important;
  }
  .medium-screen\:vads-u-border-left--5px {
    border-left: 5px solid !important;
  }
  .medium-screen\:vads-u-border--7px {
    border: 7px solid !important;
  }
  .medium-screen\:vads-u-border-top--7px {
    border-top: 7px solid !important;
  }
  .medium-screen\:vads-u-border-right--7px {
    border-right: 7px solid !important;
  }
  .medium-screen\:vads-u-border-bottom--7px {
    border-bottom: 7px solid !important;
  }
  .medium-screen\:vads-u-border-left--7px {
    border-left: 7px solid !important;
  }
  .medium-screen\:vads-u-border--10px {
    border: 10px solid !important;
  }
  .medium-screen\:vads-u-border-top--10px {
    border-top: 10px solid !important;
  }
  .medium-screen\:vads-u-border-right--10px {
    border-right: 10px solid !important;
  }
  .medium-screen\:vads-u-border-bottom--10px {
    border-bottom: 10px solid !important;
  }
  .medium-screen\:vads-u-border-left--10px {
    border-left: 10px solid !important;
  }
  .medium-screen\:vads-u-display--block {
    display: block !important;
  }
  .medium-screen\:vads-u-display--inline {
    display: inline !important;
  }
  .medium-screen\:vads-u-display--inline-block {
    display: inline-block !important;
  }
  .medium-screen\:vads-u-display--flex {
    display: flex !important;
  }
  .medium-screen\:vads-u-display--none {
    display: none !important;
  }
  .medium-screen\:vads-u-flex--1 {
    flex: 1 0 0% !important;
  }
  .medium-screen\:vads-u-flex--2 {
    flex: 2 0 0% !important;
  }
  .medium-screen\:vads-u-flex--3 {
    flex: 3 0 0% !important;
  }
  .medium-screen\:vads-u-flex--4 {
    flex: 4 0 0% !important;
  }
  .medium-screen\:vads-u-flex--auto {
    flex: 0 0 auto !important;
  }
  .medium-screen\:vads-u-flex--fill {
    flex: 1 0 0% !important;
  }
  .medium-screen\:vads-u-flex-direction--column {
    flex-direction: column !important;
  }
  .medium-screen\:vads-u-flex-direction--row {
    flex-direction: row !important;
  }
  .medium-screen\:vads-u-flex-direction--column-reverse {
    flex-direction: column-reverse !important;
  }
  .medium-screen\:vads-u-flex-direction--row-reverse {
    flex-direction: row-reverse !important;
  }
  .medium-screen\:vads-u-flex-wrap--nowrap {
    flex-wrap: nowrap !important;
  }
  .medium-screen\:vads-u-flex-wrap--wrap {
    flex-wrap: wrap !important;
  }
  .medium-screen\:vads-u-flex-wrap--wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .medium-screen\:vads-u-align-items--stretch {
    align-items: stretch !important;
  }
  .medium-screen\:vads-u-align-items--flex-start {
    align-items: flex-start !important;
  }
  .medium-screen\:vads-u-align-items--flex-end {
    align-items: flex-end !important;
  }
  .medium-screen\:vads-u-align-items--center {
    align-items: center !important;
  }
  .medium-screen\:vads-u-align-items--baseline {
    align-items: baseline !important;
  }
  .medium-screen\:vads-u-justify-content--flex-start {
    justify-content: flex-start !important;
  }
  .medium-screen\:vads-u-justify-content--flex-end {
    justify-content: flex-end !important;
  }
  .medium-screen\:vads-u-justify-content--center {
    justify-content: center !important;
  }
  .medium-screen\:vads-u-justify-content--space-between {
    justify-content: space-between !important;
  }
  .medium-screen\:vads-u-justify-content--space-around {
    justify-content: space-around !important;
  }
  .medium-screen\:vads-u-justify-content--space-evenly {
    justify-content: space-evenly !important;
  }
  .medium-screen\:vads-u-align-content--flex-start {
    align-content: flex-start !important;
  }
  .medium-screen\:vads-u-align-content--flex-end {
    align-content: flex-end !important;
  }
  .medium-screen\:vads-u-align-content--center {
    align-content: center !important;
  }
  .medium-screen\:vads-u-align-content--space-between {
    align-content: space-between !important;
  }
  .medium-screen\:vads-u-align-content--space-around {
    align-content: space-around !important;
  }
  .medium-screen\:vads-u-align-content--stretch {
    align-content: stretch !important;
  }
  .medium-screen\:vads-u-align-self--auto {
    align-self: auto !important;
  }
  .medium-screen\:vads-u-align-self--flex-start {
    align-self: flex-start !important;
  }
  .medium-screen\:vads-u-align-self--flex-end {
    align-self: flex-end !important;
  }
  .medium-screen\:vads-u-align-self--center {
    align-self: center !important;
  }
  .medium-screen\:vads-u-align-self--baseline {
    align-self: baseline !important;
  }
  .medium-screen\:vads-u-align-self--stretch {
    align-self: stretch !important;
  }
  .medium-screen\:vads-u-order--1 {
    order: 1 !important;
  }
  .medium-screen\:vads-u-order--2 {
    order: 2 !important;
  }
  .medium-screen\:vads-u-order--3 {
    order: 3 !important;
  }
  .medium-screen\:vads-u-order--4 {
    order: 4 !important;
  }
  .medium-screen\:vads-u-order--first {
    order: -1 !important;
  }
  .medium-screen\:vads-u-order--last {
    order: 999 !important;
  }
  .medium-screen\:vads-u-order--initial {
    order: 0 !important;
  }
  .medium-screen\:vads-u-font-size--source-sans-normalized {
    font-size: 1.063rem !important;
  }
  .medium-screen\:vads-u-font-size--sm {
    font-size: 0.938rem !important;
  }
  .medium-screen\:vads-u-font-size--base {
    font-size: 1rem !important;
  }
  .medium-screen\:vads-u-font-size--md {
    font-size: 1.063rem !important;
  }
  .medium-screen\:vads-u-font-size--lg {
    font-size: 1.25rem !important;
  }
  .medium-screen\:vads-u-font-size--xl {
    font-size: 1.875rem !important;
  }
  .medium-screen\:vads-u-font-size--2xl {
    font-size: 2.5rem !important;
  }
  .medium-screen\:vads-u-font-size--h1 {
    font-size: 2.5rem !important;
  }
  .medium-screen\:vads-u-font-size--h2 {
    font-size: 1.875rem !important;
  }
  .medium-screen\:vads-u-font-size--h3 {
    font-size: 1.25rem !important;
  }
  .medium-screen\:vads-u-font-size--h4 {
    font-size: 1.063rem !important;
  }
  .medium-screen\:vads-u-font-size--h5 {
    font-size: 0.938rem !important;
  }
  .medium-screen\:vads-u-font-size--h6 {
    font-size: 0.938rem !important;
  }
  .medium-screen\:vads-u-height--0 {
    height: 0px !important;
  }
  .medium-screen\:vads-u-height--full {
    height: 100% !important;
  }
  .medium-screen\:vads-u-height--auto {
    height: auto !important;
  }
  .medium-screen\:vads-u-height--viewport {
    height: 100vh !important;
  }
  .medium-screen\:vads-u-min-height--none {
    min-height: none !important;
  }
  .medium-screen\:vads-u-min-height--viewport {
    min-height: 100vh !important;
  }
  .medium-screen\:vads-u-max-height--none {
    max-height: none !important;
  }
  .medium-screen\:vads-u-max-height--viewport {
    max-height: 100vh !important;
  }
  .medium-screen\:vads-u-line-height--1 {
    line-height: 1 !important;
  }
  .medium-screen\:vads-u-line-height--2 {
    line-height: 1.15 !important;
  }
  .medium-screen\:vads-u-line-height--3 {
    line-height: 1.35 !important;
  }
  .medium-screen\:vads-u-line-height--4 {
    line-height: 1.5 !important;
  }
  .medium-screen\:vads-u-line-height--5 {
    line-height: 1.62 !important;
  }
  .medium-screen\:vads-u-line-height--6 {
    line-height: 1.75 !important;
  }
  .medium-screen\:vads-u-margin--0 {
    margin: 0 !important;
  }
  .medium-screen\:vads-u-margin--1px {
    margin: 1px !important;
  }
  .medium-screen\:vads-u-margin--0p25 {
    margin: 2px !important;
  }
  .medium-screen\:vads-u-margin--0p5 {
    margin: 0.25rem !important;
  }
  .medium-screen\:vads-u-margin--1 {
    margin: 0.5rem !important;
  }
  .medium-screen\:vads-u-margin--1p5 {
    margin: 0.75rem !important;
  }
  .medium-screen\:vads-u-margin--2 {
    margin: 1rem !important;
  }
  .medium-screen\:vads-u-margin--2p5 {
    margin: 1.25rem !important;
  }
  .medium-screen\:vads-u-margin--3 {
    margin: 1.5rem !important;
  }
  .medium-screen\:vads-u-margin--4 {
    margin: 2rem !important;
  }
  .medium-screen\:vads-u-margin--5 {
    margin: 2.5rem !important;
  }
  .medium-screen\:vads-u-margin--6 {
    margin: 3rem !important;
  }
  .medium-screen\:vads-u-margin--7 {
    margin: 3.5rem !important;
  }
  .medium-screen\:vads-u-margin--8 {
    margin: 4rem !important;
  }
  .medium-screen\:vads-u-margin--9 {
    margin: 4.5rem !important;
  }
  .medium-screen\:vads-u-margin-y--0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .medium-screen\:vads-u-margin-top--0 {
    margin-top: 0 !important;
  }
  .medium-screen\:vads-u-margin-bottom--0 {
    margin-bottom: 0 !important;
  }
  .medium-screen\:vads-u-margin-y--1px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .medium-screen\:vads-u-margin-top--1px {
    margin-top: 1px !important;
  }
  .medium-screen\:vads-u-margin-bottom--1px {
    margin-bottom: 1px !important;
  }
  .medium-screen\:vads-u-margin-y--0p25 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .medium-screen\:vads-u-margin-top--0p25 {
    margin-top: 2px !important;
  }
  .medium-screen\:vads-u-margin-bottom--0p25 {
    margin-bottom: 2px !important;
  }
  .medium-screen\:vads-u-margin-y--0p5 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .medium-screen\:vads-u-margin-top--0p5 {
    margin-top: 0.25rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--0p5 {
    margin-bottom: 0.25rem !important;
  }
  .medium-screen\:vads-u-margin-y--1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .medium-screen\:vads-u-margin-top--1 {
    margin-top: 0.5rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--1 {
    margin-bottom: 0.5rem !important;
  }
  .medium-screen\:vads-u-margin-y--1p5 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .medium-screen\:vads-u-margin-top--1p5 {
    margin-top: 0.75rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--1p5 {
    margin-bottom: 0.75rem !important;
  }
  .medium-screen\:vads-u-margin-y--2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .medium-screen\:vads-u-margin-top--2 {
    margin-top: 1rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--2 {
    margin-bottom: 1rem !important;
  }
  .medium-screen\:vads-u-margin-y--2p5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .medium-screen\:vads-u-margin-top--2p5 {
    margin-top: 1.25rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--2p5 {
    margin-bottom: 1.25rem !important;
  }
  .medium-screen\:vads-u-margin-y--3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .medium-screen\:vads-u-margin-top--3 {
    margin-top: 1.5rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--3 {
    margin-bottom: 1.5rem !important;
  }
  .medium-screen\:vads-u-margin-y--4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .medium-screen\:vads-u-margin-top--4 {
    margin-top: 2rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--4 {
    margin-bottom: 2rem !important;
  }
  .medium-screen\:vads-u-margin-y--5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .medium-screen\:vads-u-margin-top--5 {
    margin-top: 2.5rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--5 {
    margin-bottom: 2.5rem !important;
  }
  .medium-screen\:vads-u-margin-y--6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .medium-screen\:vads-u-margin-top--6 {
    margin-top: 3rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--6 {
    margin-bottom: 3rem !important;
  }
  .medium-screen\:vads-u-margin-y--7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .medium-screen\:vads-u-margin-top--7 {
    margin-top: 3.5rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--7 {
    margin-bottom: 3.5rem !important;
  }
  .medium-screen\:vads-u-margin-y--8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .medium-screen\:vads-u-margin-top--8 {
    margin-top: 4rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--8 {
    margin-bottom: 4rem !important;
  }
  .medium-screen\:vads-u-margin-y--9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .medium-screen\:vads-u-margin-top--9 {
    margin-top: 4.5rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--9 {
    margin-bottom: 4.5rem !important;
  }
  .medium-screen\:vads-u-margin-y--neg9 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .medium-screen\:vads-u-margin-top--neg9 {
    margin-top: -4.5rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--neg9 {
    margin-bottom: -4.5rem !important;
  }
  .medium-screen\:vads-u-margin-y--neg8 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .medium-screen\:vads-u-margin-top--neg8 {
    margin-top: -4rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--neg8 {
    margin-bottom: -4rem !important;
  }
  .medium-screen\:vads-u-margin-y--neg7 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .medium-screen\:vads-u-margin-top--neg7 {
    margin-top: -3.5rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--neg7 {
    margin-bottom: -3.5rem !important;
  }
  .medium-screen\:vads-u-margin-y--neg6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .medium-screen\:vads-u-margin-top--neg6 {
    margin-top: -3rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--neg6 {
    margin-bottom: -3rem !important;
  }
  .medium-screen\:vads-u-margin-y--neg5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .medium-screen\:vads-u-margin-top--neg5 {
    margin-top: -2.5rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--neg5 {
    margin-bottom: -2.5rem !important;
  }
  .medium-screen\:vads-u-margin-y--neg4 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .medium-screen\:vads-u-margin-top--neg4 {
    margin-top: -2rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--neg4 {
    margin-bottom: -2rem !important;
  }
  .medium-screen\:vads-u-margin-y--neg3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .medium-screen\:vads-u-margin-top--neg3 {
    margin-top: -1.5rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--neg3 {
    margin-bottom: -1.5rem !important;
  }
  .medium-screen\:vads-u-margin-y--neg2p5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .medium-screen\:vads-u-margin-top--neg2p5 {
    margin-top: -1.25rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--neg2p5 {
    margin-bottom: -1.25rem !important;
  }
  .medium-screen\:vads-u-margin-y--neg2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .medium-screen\:vads-u-margin-top--neg2 {
    margin-top: -1rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--neg2 {
    margin-bottom: -1rem !important;
  }
  .medium-screen\:vads-u-margin-y--neg1p5 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .medium-screen\:vads-u-margin-top--neg1p5 {
    margin-top: -0.75rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--neg1p5 {
    margin-bottom: -0.75rem !important;
  }
  .medium-screen\:vads-u-margin-y--neg1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .medium-screen\:vads-u-margin-top--neg1 {
    margin-top: -0.5rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--neg1 {
    margin-bottom: -0.5rem !important;
  }
  .medium-screen\:vads-u-margin-y--neg0p5 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .medium-screen\:vads-u-margin-top--neg0p5 {
    margin-top: -0.25rem !important;
  }
  .medium-screen\:vads-u-margin-bottom--neg0p5 {
    margin-bottom: -0.25rem !important;
  }
  .medium-screen\:vads-u-margin-y--neg0p25 {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }
  .medium-screen\:vads-u-margin-top--neg0p25 {
    margin-top: -2px !important;
  }
  .medium-screen\:vads-u-margin-bottom--neg0p25 {
    margin-bottom: -2px !important;
  }
  .medium-screen\:vads-u-margin-y--neg1px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }
  .medium-screen\:vads-u-margin-top--neg1px {
    margin-top: -1px !important;
  }
  .medium-screen\:vads-u-margin-bottom--neg1px {
    margin-bottom: -1px !important;
  }
  .medium-screen\:vads-u-margin-x--0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .medium-screen\:vads-u-margin-right--0 {
    margin-right: 0 !important;
  }
  .medium-screen\:vads-u-margin-left--0 {
    margin-left: 0 !important;
  }
  .medium-screen\:vads-u-margin-x--1px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .medium-screen\:vads-u-margin-right--1px {
    margin-right: 1px !important;
  }
  .medium-screen\:vads-u-margin-left--1px {
    margin-left: 1px !important;
  }
  .medium-screen\:vads-u-margin-x--0p25 {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .medium-screen\:vads-u-margin-right--0p25 {
    margin-right: 2px !important;
  }
  .medium-screen\:vads-u-margin-left--0p25 {
    margin-left: 2px !important;
  }
  .medium-screen\:vads-u-margin-x--0p5 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .medium-screen\:vads-u-margin-right--0p5 {
    margin-right: 0.25rem !important;
  }
  .medium-screen\:vads-u-margin-left--0p5 {
    margin-left: 0.25rem !important;
  }
  .medium-screen\:vads-u-margin-x--1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .medium-screen\:vads-u-margin-right--1 {
    margin-right: 0.5rem !important;
  }
  .medium-screen\:vads-u-margin-left--1 {
    margin-left: 0.5rem !important;
  }
  .medium-screen\:vads-u-margin-x--1p5 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .medium-screen\:vads-u-margin-right--1p5 {
    margin-right: 0.75rem !important;
  }
  .medium-screen\:vads-u-margin-left--1p5 {
    margin-left: 0.75rem !important;
  }
  .medium-screen\:vads-u-margin-x--2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .medium-screen\:vads-u-margin-right--2 {
    margin-right: 1rem !important;
  }
  .medium-screen\:vads-u-margin-left--2 {
    margin-left: 1rem !important;
  }
  .medium-screen\:vads-u-margin-x--2p5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .medium-screen\:vads-u-margin-right--2p5 {
    margin-right: 1.25rem !important;
  }
  .medium-screen\:vads-u-margin-left--2p5 {
    margin-left: 1.25rem !important;
  }
  .medium-screen\:vads-u-margin-x--3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .medium-screen\:vads-u-margin-right--3 {
    margin-right: 1.5rem !important;
  }
  .medium-screen\:vads-u-margin-left--3 {
    margin-left: 1.5rem !important;
  }
  .medium-screen\:vads-u-margin-x--4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .medium-screen\:vads-u-margin-right--4 {
    margin-right: 2rem !important;
  }
  .medium-screen\:vads-u-margin-left--4 {
    margin-left: 2rem !important;
  }
  .medium-screen\:vads-u-margin-x--5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .medium-screen\:vads-u-margin-right--5 {
    margin-right: 2.5rem !important;
  }
  .medium-screen\:vads-u-margin-left--5 {
    margin-left: 2.5rem !important;
  }
  .medium-screen\:vads-u-margin-x--6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .medium-screen\:vads-u-margin-right--6 {
    margin-right: 3rem !important;
  }
  .medium-screen\:vads-u-margin-left--6 {
    margin-left: 3rem !important;
  }
  .medium-screen\:vads-u-margin-x--7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .medium-screen\:vads-u-margin-right--7 {
    margin-right: 3.5rem !important;
  }
  .medium-screen\:vads-u-margin-left--7 {
    margin-left: 3.5rem !important;
  }
  .medium-screen\:vads-u-margin-x--8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .medium-screen\:vads-u-margin-right--8 {
    margin-right: 4rem !important;
  }
  .medium-screen\:vads-u-margin-left--8 {
    margin-left: 4rem !important;
  }
  .medium-screen\:vads-u-margin-x--9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .medium-screen\:vads-u-margin-right--9 {
    margin-right: 4.5rem !important;
  }
  .medium-screen\:vads-u-margin-left--9 {
    margin-left: 4.5rem !important;
  }
  .medium-screen\:vads-u-margin-x--neg9 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .medium-screen\:vads-u-margin-right--neg9 {
    margin-right: -4.5rem !important;
  }
  .medium-screen\:vads-u-margin-left--neg9 {
    margin-left: -4.5rem !important;
  }
  .medium-screen\:vads-u-margin-x--neg8 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .medium-screen\:vads-u-margin-right--neg8 {
    margin-right: -4rem !important;
  }
  .medium-screen\:vads-u-margin-left--neg8 {
    margin-left: -4rem !important;
  }
  .medium-screen\:vads-u-margin-x--neg7 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .medium-screen\:vads-u-margin-right--neg7 {
    margin-right: -3.5rem !important;
  }
  .medium-screen\:vads-u-margin-left--neg7 {
    margin-left: -3.5rem !important;
  }
  .medium-screen\:vads-u-margin-x--neg6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .medium-screen\:vads-u-margin-right--neg6 {
    margin-right: -3rem !important;
  }
  .medium-screen\:vads-u-margin-left--neg6 {
    margin-left: -3rem !important;
  }
  .medium-screen\:vads-u-margin-x--neg5 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .medium-screen\:vads-u-margin-right--neg5 {
    margin-right: -2.5rem !important;
  }
  .medium-screen\:vads-u-margin-left--neg5 {
    margin-left: -2.5rem !important;
  }
  .medium-screen\:vads-u-margin-x--neg4 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .medium-screen\:vads-u-margin-right--neg4 {
    margin-right: -2rem !important;
  }
  .medium-screen\:vads-u-margin-left--neg4 {
    margin-left: -2rem !important;
  }
  .medium-screen\:vads-u-margin-x--neg3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .medium-screen\:vads-u-margin-right--neg3 {
    margin-right: -1.5rem !important;
  }
  .medium-screen\:vads-u-margin-left--neg3 {
    margin-left: -1.5rem !important;
  }
  .medium-screen\:vads-u-margin-x--neg2p5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .medium-screen\:vads-u-margin-right--neg2p5 {
    margin-right: -1.25rem !important;
  }
  .medium-screen\:vads-u-margin-left--neg2p5 {
    margin-left: -1.25rem !important;
  }
  .medium-screen\:vads-u-margin-x--neg2 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .medium-screen\:vads-u-margin-right--neg2 {
    margin-right: -1rem !important;
  }
  .medium-screen\:vads-u-margin-left--neg2 {
    margin-left: -1rem !important;
  }
  .medium-screen\:vads-u-margin-x--neg1p5 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .medium-screen\:vads-u-margin-right--neg1p5 {
    margin-right: -0.75rem !important;
  }
  .medium-screen\:vads-u-margin-left--neg1p5 {
    margin-left: -0.75rem !important;
  }
  .medium-screen\:vads-u-margin-x--neg1 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .medium-screen\:vads-u-margin-right--neg1 {
    margin-right: -0.5rem !important;
  }
  .medium-screen\:vads-u-margin-left--neg1 {
    margin-left: -0.5rem !important;
  }
  .medium-screen\:vads-u-margin-x--neg0p5 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .medium-screen\:vads-u-margin-right--neg0p5 {
    margin-right: -0.25rem !important;
  }
  .medium-screen\:vads-u-margin-left--neg0p5 {
    margin-left: -0.25rem !important;
  }
  .medium-screen\:vads-u-margin-x--neg0p25 {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }
  .medium-screen\:vads-u-margin-right--neg0p25 {
    margin-right: -2px !important;
  }
  .medium-screen\:vads-u-margin-left--neg0p25 {
    margin-left: -2px !important;
  }
  .medium-screen\:vads-u-margin-x--neg1px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }
  .medium-screen\:vads-u-margin-right--neg1px {
    margin-right: -1px !important;
  }
  .medium-screen\:vads-u-margin-left--neg1px {
    margin-left: -1px !important;
  }
  .medium-screen\:vads-u-margin-x--auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .medium-screen\:vads-u-margin-right--auto {
    margin-right: auto !important;
  }
  .medium-screen\:vads-u-margin-left--auto {
    margin-left: auto !important;
  }
  .medium-screen\:vads-u-measure--1 {
    max-width: 40ch !important;
  }
  .medium-screen\:vads-u-measure--2 {
    max-width: 60ch !important;
  }
  .medium-screen\:vads-u-measure--3 {
    max-width: 66ch !important;
  }
  .medium-screen\:vads-u-measure--4 {
    max-width: 72ch !important;
  }
  .medium-screen\:vads-u-measure--5 {
    max-width: 77ch !important;
  }
  .medium-screen\:vads-u-measure--none {
    max-width: none !important;
  }
  .medium-screen\:vads-u-padding--0 {
    padding: 0 !important;
  }
  .medium-screen\:vads-u-padding-top--0 {
    padding-top: 0 !important;
  }
  .medium-screen\:vads-u-padding-bottom--0 {
    padding-bottom: 0 !important;
  }
  .medium-screen\:vads-u-padding-right--0 {
    padding-right: 0 !important;
  }
  .medium-screen\:vads-u-padding-left--0 {
    padding-left: 0 !important;
  }
  .medium-screen\:vads-u-padding--1px {
    padding: 1px !important;
  }
  .medium-screen\:vads-u-padding-top--1px {
    padding-top: 1px !important;
  }
  .medium-screen\:vads-u-padding-bottom--1px {
    padding-bottom: 1px !important;
  }
  .medium-screen\:vads-u-padding-right--1px {
    padding-right: 1px !important;
  }
  .medium-screen\:vads-u-padding-left--1px {
    padding-left: 1px !important;
  }
  .medium-screen\:vads-u-padding--0p25 {
    padding: 2px !important;
  }
  .medium-screen\:vads-u-padding-top--0p25 {
    padding-top: 2px !important;
  }
  .medium-screen\:vads-u-padding-bottom--0p25 {
    padding-bottom: 2px !important;
  }
  .medium-screen\:vads-u-padding-right--0p25 {
    padding-right: 2px !important;
  }
  .medium-screen\:vads-u-padding-left--0p25 {
    padding-left: 2px !important;
  }
  .medium-screen\:vads-u-padding--0p5 {
    padding: 0.25rem !important;
  }
  .medium-screen\:vads-u-padding-top--0p5 {
    padding-top: 0.25rem !important;
  }
  .medium-screen\:vads-u-padding-bottom--0p5 {
    padding-bottom: 0.25rem !important;
  }
  .medium-screen\:vads-u-padding-right--0p5 {
    padding-right: 0.25rem !important;
  }
  .medium-screen\:vads-u-padding-left--0p5 {
    padding-left: 0.25rem !important;
  }
  .medium-screen\:vads-u-padding--1 {
    padding: 0.5rem !important;
  }
  .medium-screen\:vads-u-padding-top--1 {
    padding-top: 0.5rem !important;
  }
  .medium-screen\:vads-u-padding-bottom--1 {
    padding-bottom: 0.5rem !important;
  }
  .medium-screen\:vads-u-padding-right--1 {
    padding-right: 0.5rem !important;
  }
  .medium-screen\:vads-u-padding-left--1 {
    padding-left: 0.5rem !important;
  }
  .medium-screen\:vads-u-padding--1p5 {
    padding: 0.75rem !important;
  }
  .medium-screen\:vads-u-padding-top--1p5 {
    padding-top: 0.75rem !important;
  }
  .medium-screen\:vads-u-padding-bottom--1p5 {
    padding-bottom: 0.75rem !important;
  }
  .medium-screen\:vads-u-padding-right--1p5 {
    padding-right: 0.75rem !important;
  }
  .medium-screen\:vads-u-padding-left--1p5 {
    padding-left: 0.75rem !important;
  }
  .medium-screen\:vads-u-padding--2 {
    padding: 1rem !important;
  }
  .medium-screen\:vads-u-padding-top--2 {
    padding-top: 1rem !important;
  }
  .medium-screen\:vads-u-padding-bottom--2 {
    padding-bottom: 1rem !important;
  }
  .medium-screen\:vads-u-padding-right--2 {
    padding-right: 1rem !important;
  }
  .medium-screen\:vads-u-padding-left--2 {
    padding-left: 1rem !important;
  }
  .medium-screen\:vads-u-padding--2p5 {
    padding: 1.25rem !important;
  }
  .medium-screen\:vads-u-padding-top--2p5 {
    padding-top: 1.25rem !important;
  }
  .medium-screen\:vads-u-padding-bottom--2p5 {
    padding-bottom: 1.25rem !important;
  }
  .medium-screen\:vads-u-padding-right--2p5 {
    padding-right: 1.25rem !important;
  }
  .medium-screen\:vads-u-padding-left--2p5 {
    padding-left: 1.25rem !important;
  }
  .medium-screen\:vads-u-padding--3 {
    padding: 1.5rem !important;
  }
  .medium-screen\:vads-u-padding-top--3 {
    padding-top: 1.5rem !important;
  }
  .medium-screen\:vads-u-padding-bottom--3 {
    padding-bottom: 1.5rem !important;
  }
  .medium-screen\:vads-u-padding-right--3 {
    padding-right: 1.5rem !important;
  }
  .medium-screen\:vads-u-padding-left--3 {
    padding-left: 1.5rem !important;
  }
  .medium-screen\:vads-u-padding--4 {
    padding: 2rem !important;
  }
  .medium-screen\:vads-u-padding-top--4 {
    padding-top: 2rem !important;
  }
  .medium-screen\:vads-u-padding-bottom--4 {
    padding-bottom: 2rem !important;
  }
  .medium-screen\:vads-u-padding-right--4 {
    padding-right: 2rem !important;
  }
  .medium-screen\:vads-u-padding-left--4 {
    padding-left: 2rem !important;
  }
  .medium-screen\:vads-u-padding--5 {
    padding: 2.5rem !important;
  }
  .medium-screen\:vads-u-padding-top--5 {
    padding-top: 2.5rem !important;
  }
  .medium-screen\:vads-u-padding-bottom--5 {
    padding-bottom: 2.5rem !important;
  }
  .medium-screen\:vads-u-padding-right--5 {
    padding-right: 2.5rem !important;
  }
  .medium-screen\:vads-u-padding-left--5 {
    padding-left: 2.5rem !important;
  }
  .medium-screen\:vads-u-padding--6 {
    padding: 3rem !important;
  }
  .medium-screen\:vads-u-padding-top--6 {
    padding-top: 3rem !important;
  }
  .medium-screen\:vads-u-padding-bottom--6 {
    padding-bottom: 3rem !important;
  }
  .medium-screen\:vads-u-padding-right--6 {
    padding-right: 3rem !important;
  }
  .medium-screen\:vads-u-padding-left--6 {
    padding-left: 3rem !important;
  }
  .medium-screen\:vads-u-padding--7 {
    padding: 3.5rem !important;
  }
  .medium-screen\:vads-u-padding-top--7 {
    padding-top: 3.5rem !important;
  }
  .medium-screen\:vads-u-padding-bottom--7 {
    padding-bottom: 3.5rem !important;
  }
  .medium-screen\:vads-u-padding-right--7 {
    padding-right: 3.5rem !important;
  }
  .medium-screen\:vads-u-padding-left--7 {
    padding-left: 3.5rem !important;
  }
  .medium-screen\:vads-u-padding--8 {
    padding: 4rem !important;
  }
  .medium-screen\:vads-u-padding-top--8 {
    padding-top: 4rem !important;
  }
  .medium-screen\:vads-u-padding-bottom--8 {
    padding-bottom: 4rem !important;
  }
  .medium-screen\:vads-u-padding-right--8 {
    padding-right: 4rem !important;
  }
  .medium-screen\:vads-u-padding-left--8 {
    padding-left: 4rem !important;
  }
  .medium-screen\:vads-u-padding--9 {
    padding: 4.5rem !important;
  }
  .medium-screen\:vads-u-padding-top--9 {
    padding-top: 4.5rem !important;
  }
  .medium-screen\:vads-u-padding-bottom--9 {
    padding-bottom: 4.5rem !important;
  }
  .medium-screen\:vads-u-padding-right--9 {
    padding-right: 4.5rem !important;
  }
  .medium-screen\:vads-u-padding-left--9 {
    padding-left: 4.5rem !important;
  }
  .medium-screen\:vads-u-padding-y--0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .medium-screen\:vads-u-padding-y--1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .medium-screen\:vads-u-padding-y--0p25 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .medium-screen\:vads-u-padding-y--0p5 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .medium-screen\:vads-u-padding-y--1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .medium-screen\:vads-u-padding-y--1p5 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .medium-screen\:vads-u-padding-y--2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .medium-screen\:vads-u-padding-y--2p5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .medium-screen\:vads-u-padding-y--3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .medium-screen\:vads-u-padding-y--4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .medium-screen\:vads-u-padding-y--5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .medium-screen\:vads-u-padding-y--6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .medium-screen\:vads-u-padding-y--7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .medium-screen\:vads-u-padding-y--8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .medium-screen\:vads-u-padding-y--9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .medium-screen\:vads-u-padding-x--0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .medium-screen\:vads-u-padding-x--1px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .medium-screen\:vads-u-padding-x--0p25 {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .medium-screen\:vads-u-padding-x--0p5 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .medium-screen\:vads-u-padding-x--1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .medium-screen\:vads-u-padding-x--1p5 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .medium-screen\:vads-u-padding-x--2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .medium-screen\:vads-u-padding-x--2p5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .medium-screen\:vads-u-padding-x--3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .medium-screen\:vads-u-padding-x--4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .medium-screen\:vads-u-padding-x--5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .medium-screen\:vads-u-padding-x--6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .medium-screen\:vads-u-padding-x--7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .medium-screen\:vads-u-padding-x--8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .medium-screen\:vads-u-padding-x--9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .medium-screen\:vads-u-position--absolute {
    position: absolute !important;
  }
  .medium-screen\:vads-u-position--fixed {
    position: fixed !important;
  }
  .medium-screen\:vads-u-position--relative {
    position: relative !important;
  }
  .medium-screen\:vads-u-text-align--left {
    text-align: left !important;
  }
  .medium-screen\:vads-u-text-align--center {
    text-align: center !important;
  }
  .medium-screen\:vads-u-text-align--right {
    text-align: right !important;
  }
  .medium-screen\:vads-u-text-align--justify {
    text-align: justify !important;
  }
  .medium-screen\:vads-u-visibility--hidden {
    visibility: hidden !important;
  }
  .medium-screen\:vads-u-visibility--visible {
    visibility: visible !important;
  }
  .medium-screen\:vads-u-width--0 {
    width: 0px !important;
  }
  .medium-screen\:vads-u-width--full {
    width: 100% !important;
  }
  .medium-screen\:vads-u-width--auto {
    width: auto !important;
  }
  .medium-screen\:vads-u-min-width--none {
    min-width: none !important;
  }
  .medium-screen\:vads-u-max-width--none {
    max-width: none !important;
  }
  .medium-screen\:vads-u-max-width--100 {
    max-width: 100% !important;
  }
}

/*# sourceMappingURL=utilities.css.map */
